import { Component, OnInit, AfterViewInit, OnDestroy, Input, Output, EventEmitter, HostListener, Inject, PLATFORM_ID, ElementRef, ViewChild, effect } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { Keyboard } from '@capacitor/keyboard';
import { Haptics } from '@capacitor/haptics';

import { AccountUser } from '../_models/user';
import { Conversation, Message } from '../_models/messenger';
import { Job } from '../_models/company';

import { AccountService } from '../_services/account.service';
import { AlertService } from '../_services/alert.service';
import { ChatService } from '../_services/chat.service';
import { CompanyService } from '../_services/company.service';
import { FooterService } from '../_services/footer.service';
import { HeaderService } from '../_services/header.service';
import { MessengerService } from '../_services/messenger.service'
import { MixpanelService } from '../_services/mixpanel.service';

//import * as moment from 'moment';

@Component({
  selector: 'app-messenger',
  templateUrl: './messenger.component.html',
  styleUrls: ['./messenger.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    //AccountUserProfileModule,
    //HeaderModule,
    //GeneralModule,
    //ModalModule,
    //ServiceModule,
    //MessengerRoutingModule,
    TranslateModule/*.forChild({
        extend: true
    })*/
  ],
})
export class MessengerComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('profileModal') profileModal;
    @ViewChild('showInterestModal') showInterestModal;
    @ViewChild('conversationMenu') conversationMenu;

    @ViewChild('messenger') messengerElem: ElementRef;
    @ViewChild('chatTextarea') chatTextareaElem: ElementRef;
    @ViewChild('conversationContainer') conversationContainerElem: ElementRef;
    //@ViewChild('messageBody') messageBodyElem: ElementRef;
    //@ViewChild('messageContainer') messageContainerElem: ElementRef;
    @ViewChild('messageContainer') messageContainer: ElementRef;
    @ViewChild('messageBox') messageBoxElem: ElementRef;

    @Input() heightReducedInPx: number = 0;

    @Input() isGhost: boolean = false;

    @Input() listAll: boolean = false;

    @Input() set conversation(c: Conversation) {
        if (c) {
            this.selectConversation(c);
        }
    }

    @Input() set conversationByHash(hash: string) {
        if (hash) {
            this.conversationHash = hash;
            this.selectConversationByHash(hash);
        }
    }

    _showConversations: boolean = true;

    mobileShowConversations: boolean = true; // show conversations by default in mobile view

    @Input() set showConversations(show: boolean) {
        if (show) {
            this._showConversations = true;
        } else {
            this._showConversations = false;
        }
    }

    _job: Job | null;

    @Input() set job(job: Job) {
        this._job = job;
    }

    selectedConversation: Conversation | undefined;
    selectedConversationTitle: string;
    selectedMessage: Message | null;

    hoverMessage: Message | null;

    conversationHash: string;

    conversations: Conversation[] = [];
    messages: Message[] = [];

    subConversations: any;
    subMessages: any;
    subChat: any;
    subJob: any;

    groupedMessages: { [key: string]: Message[] } = {};

    messagesCount: number;
    messagesNext: string | null;
    messagesPrevious: string | null;
    messagesPage: number = 1;


    conversationsCount: number;
    conversationsNext: string;
    conversationsPrevious: string;
    conversationsPage: number = 1;

    containerHeight: number;

    initialMessage: Message;

    firstMessage: string;

    previousDate: any;

    messageScrollHeightBeforeLoad: number;
    //conversationScrollHeightBeforeLoad: number;

    formTextareaHeight: number = 21;

    isKeyboardVisible: boolean = false;

    heightHeader: number = 0;
    heightFooter: number = 0;
    heightTotalReduced: number = 0;

    //messageBodyHeight: number = 100; //100 pixels default

    // try to get a conversation with the given member else create it
    @Input() set startChatWithMembers(auIds: number[] | undefined) {
        if (auIds && auIds.length > 0) {
            this.clearMessenger();

            this.loggedInAccountUser = this.accountService.loggedInAccountUser();

            const au = this.loggedInAccountUser;

            // clear auIds after first load since
            // loggedInAccountUser is called several times
            if (auIds && au) {
                this.subConversations = this.messengerService.getConversationByMembers(auIds).subscribe(
                    conv => {
                        if (conv.members == undefined || conv.members.length == 0) {
                            // create new conversation
                            if (auIds) {
                                this.newConversation(auIds);
                                //this.createConversation();
                                auIds = undefined;
                            }

                        } else {
                            // get conversation
                            let c = new Conversation().deserialize(conv);
                            this.selectConversation(c, true, au);
                            auIds = undefined;
                        }
                    },
                    error => {
                    }
                );
            }
        }
    }

    @Input() set initMessage(msg: string) {
        let m = new Message();
        //m.conversation = message.chatId;
        m.account_user = 0;
        m.account_user_full_name = 'HiCareer';
        m.account_user_main_image = 'https://hicareer.com/assets/img/logo-hicareer.png';
        m.text = msg;
        //m.created = message.created;
        this.initialMessage = m;
    }

    @Input() set setFirstMessage(msg: string) {
        this.firstMessage = msg;
    }

    members: number[] = []; // list if au id's

    @Output() saved = new EventEmitter<boolean>();
    @Output() deleted = new EventEmitter<boolean>();

    form: UntypedFormGroup;


    loadingConversations: boolean = false;
    loadingMessages: boolean = false;
    submitted: boolean = false;

    loggedInAccountUser: AccountUser | undefined | null;

    // this is the user we are displaying conversations/messages
    // for, must be loaded via @Input()
    _accountUser: AccountUser | undefined | null;

    showMenu: boolean = false;
    showSponsored: boolean = false;

    isIOS: boolean = false;

    @Input() set accountUser(au: AccountUser | undefined | null) {
        if (au) {
            this._accountUser = au;
            this.getConversations();
        }
    }

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private accountService: AccountService,
        private alertService: AlertService,
        private chatService: ChatService,
        private companyService: CompanyService,
        private footerService: FooterService,
        private formBuilder: UntypedFormBuilder,
        private headerService: HeaderService,
        private messengerService: MessengerService,
        private route: ActivatedRoute,
        private mixpanelService: MixpanelService,
    ) {
        this.form = this.formBuilder.group({
            id: [''],
            conversation_id: ['', Validators.required],
            text: ['', Validators.required],
        });

        effect(() => {
            this.loggedInAccountUser = this.accountService.loggedInAccountUser();
        });
    }

    ngOnInit(): void {

        this.route.queryParams.subscribe(params => {
            this.conversationHash = params.hash;
        });

        // get job
        this.route.queryParams.subscribe(params => {
            if (params['job']) {
                this.getJob(params['job']);
            }
        });

        this.accountService.reloadLoggedInAccountUser();

        // this monitors connection status and re-joins a disconnected chat
        this.chatService.getStatus();

        // get chat messages
        this.subChat = this.chatService.messages.subscribe((message: any) => {

            if (message) {
                let m = new Message();
                //m.conversation = message.chatId;
                m.account_user = message.auId;
                m.account_user_full_name = message.auFullName;
                m.account_user_main_image = message.auMainImage;
                m.text = message.text;
                m.created = message.created;

                if (this.messages) {

                    const lastElem = this.messages.slice(-1)[0];

                    // if there are no messages in the conversation push out the message.
                    // since multiple subscriptions to the chatService.messages object are created when we change routes
                    // we need to make sure that the created timestamp is not equal otherwise we get a new
                    // message for everytime we changed the route in the angular app...
                    if (!lastElem || ( lastElem && !(lastElem.created === m.created ))) {
                        this.messages.push(m);
                        if (isPlatformBrowser(this.platformId)) {
                            setTimeout( () => {
                                if (this.messageBoxElem) {
                                    this.messageBoxElem.nativeElement.scrollTop = this.messageBoxElem.nativeElement.scrollHeight;
                                }
                            }, 200 );
                            //window.scrollTo(0, document.body.scrollHeight);
                        }
                    }
                }

                if (Capacitor.isNativePlatform()) {
                  if (message.auId != this.loggedInAccountUser?.getId()) {
                      Haptics.vibrate();
                  }
                }

                this.getMessages();

            }
            this.groupMessagesByDay();
        });

        // app state change
        if (isPlatformBrowser(this.platformId)) {
          if (Capacitor.isNativePlatform()) {
            App.addListener('appStateChange', (state: { isActive: boolean }) => {
                // This function is called whenever the appState changes.
                if (state.isActive) {
                  this.getMessages();
                }

                Keyboard.addListener('keyboardWillShow', info => {
                    this.isKeyboardVisible = true;
                    this.scrollToBottomOnFocus();
                });

                //Keyboard.addListener('keyboardDidShow', info => {
                //});

                Keyboard.addListener('keyboardWillHide', () => {
                    this.isKeyboardVisible = false;
                });

                //Keyboard.addListener('keyboardDidHide', () => {
                //});
            });
          }
        }

        // is ios
        if (isPlatformBrowser(this.platformId)) {
            if (Capacitor.getPlatform() == 'ios') {
                this.isIOS = true;
            }
        }

    }

    ngAfterViewInit() {
        // show messenger columns correct
        /*if (this._showConversations && isPlatformBrowser(this.platformId)) {
            if(this.messengerElem) {
                this.messengerElem.nativeElement.style.gridTemplateColumns = "1fr 3fr";
            }
        } else {
            if(this.messengerElem) {
                this.messengerElem.nativeElement.style.gridTemplateColumns = "1fr";
            }
        }*/

        // get header and footer height
        this.heightHeader = this.headerService.getHeight();
        this.heightFooter = this.footerService.getHeight();
        this.heightTotalReduced = this.heightHeader + this.heightFooter;
    }

    ngOnDestroy(): void {
        if (this.subConversations) this.subConversations.unsubscribe();
        if (this.subMessages) this.subMessages.unsubscribe();
        if (this.subChat) this.subChat.unsubscribe();
        if (this.subJob) this.subJob.unsubscribe();
    }

    getExcessHeightHeader() {
        if (this.headerService.showMobile() || this.headerService.showDesktop()) {
            return this.headerService.getHeight();
        }
        return 0;
    }

    getExcessHeightFooter() {
        if (this.footerService.showMobile() || this.footerService.showDesktop()) {
            return this.footerService.getHeight();
        }
        return 0;
    }

    getExcessHeightTotal() {
        let iOSTop = this.isIOS ? 40 : 0;
        return this.getExcessHeightHeader() + this.getExcessHeightFooter() + iOSTop + this.heightReducedInPx;
    }

    // clicked outside => close
    @HostListener('document:mousedown', ['$event']) onGlobalClick(event): void {
        if (this.showMenu) {
            let clickedInside = this.conversationMenu.nativeElement.contains(event.target);

            if (!clickedInside) {
                this.showMenu = false;
            }
        }
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    /*shouldDisplayDate(currentMsg: Message, index: number): boolean {

      // Check if it's the last message
      if (index === this.messages.length - 1) {
        return true;
      }

      const currentDate = new Date(currentMsg.getCreated()).setHours(0, 0, 0, 0);
      const nextDate = new Date(this.messages[index + 1].getCreated()).setHours(0, 0, 0, 0);

      // Return true if the next message's date is different
      return currentDate !== nextDate;
    }

    displayDate(d: string): string {
      const inputDate = new Date(d);
      inputDate.setHours(0, 0, 0, 0); // Normalize the input date

      const today = new Date();
      today.setHours(0, 0, 0, 0); // Normalize today's date

      const timeDiff = today.getTime() - inputDate.getTime();
      const oneDay = 86400000; // Milliseconds in a day

      // Yesterday
      if (timeDiff === oneDay) {
        return 'Yesterday';
      }

      // Today
      if (timeDiff === 0) {
        return 'Today';
      }

      // 2-6 days ago, show weekday
      if (timeDiff > oneDay && timeDiff <= 6 * oneDay) {
        return inputDate.toLocaleDateString('en-US', { weekday: 'long' });
      }

      // More than 6 days ago, show full date
      return inputDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    }*/

    clearMessenger() {
        this.messages = [];
        this.messagesCount = 0;
        this.messagesNext = null;
        this.messagesPrevious = null;
        this.messagesPage = 1;

        this._job = null;

        // reset textbox
        //this.form.controls.text.setValue('');
    }

    chatTextareaHeight() {
        // auto height on chatTextareaElem
        if (isPlatformBrowser(this.platformId) && this.chatTextareaElem && !this.chatTextareaElem.nativeElement.disabled) {
            this.formTextareaHeight = this.chatTextareaElem.nativeElement.scrollHeight;
            this.chatTextareaElem.nativeElement.style.height = "21px";
            //this.chatTextareaElem.nativeElement.style.minHeight = "55px";
            this.chatTextareaElem.nativeElement.style.height = this.chatTextareaElem.nativeElement.scrollHeight+"px";
            //this.messageBodyElem.nativeElement.style.gridTemplateRows = "1fr " + (this.chatTextareaElem.nativeElement.scrollHeight)+"px";
        }
    }

    // used on mobile devices when mobile keyboard is shown
    /*scrollToBottomIfAtBottom() {
      // Define a tolerance threshold (e.g., 1px to 10px)
      const scrollTolerance = 30;

      if (isPlatformBrowser(this.platformId) && this.messageBoxElem) {
        setTimeout(() => {
          const isAtBottom = this.messageBoxElem.nativeElement.scrollHeight - this.messageBoxElem.nativeElement.scrollTop - this.messageBoxElem.nativeElement.clientHeight <= scrollTolerance;

          if (isAtBottom) {
            this.messageBoxElem.nativeElement.scrollTop = this.messageBoxElem.nativeElement.scrollHeight;
          }
        }, 200);
      }
    }*/

    isStaff() {
        if (this.loggedInAccountUser && (this.loggedInAccountUser.isAdmin() || this.loggedInAccountUser.isManager() || this.loggedInAccountUser.isSeller())) {
            return true;
        }
        return false;
    }

    newConversation(auIds: number[]) {
        this.selectedConversation = undefined;
        this.clearMessenger();

        // set initial message
        if (this.initialMessage) {
            this.messages.unshift(this.initialMessage);
        }

        // when we start a new conversation the loggedInAccountUser must be part of the conversation members
        if (this.loggedInAccountUser) {
            const found_in_auIds = auIds.find(id => id == this.loggedInAccountUser?.getId());
            if (!found_in_auIds) {
                // add if not already part of auIds
                auIds.push(this.loggedInAccountUser.getId());
            }
        }

        if (auIds && auIds.length < 2) {
            console.log("Please select at least two members for a new conversation.");
            return;
        }

        // add members in new conversation
        for (let auId of auIds) {
            this.members.push(auId);
        }

        if (isPlatformBrowser(this.platformId) && this.chatTextareaElem && !this.chatTextareaElem.nativeElement.disabled) {
            this.chatTextareaElem.nativeElement.focus();
        }

        // since we are writing a new message hide conversation list (needed for mobile)
        this.mobileShowConversations = false;

        // set first message
        if (this.messagesCount == 0 && !this.loadingMessages && !this.loadingConversations) {
            this.form.controls.text.setValue(this.firstMessage);
            this.chatTextareaHeight();
        }

        this.createConversation();
    }

    selectConversationByHash(hash: string) {
        this.subConversations = this.messengerService.getConversationByHash(hash).subscribe(
            conversation => {
                if (hash) {
                    this.selectConversation(conversation, true, this.loggedInAccountUser);
                }
            },
            error => {
            }
        );
    }

    showConversationsOnMobile() {
        this.mobileShowConversations = true;
        this.footerService.showMobile.set(true);
        this.footerService.showDesktop.set(false);
    }

    selectConversation(c: Conversation, loadMessages: boolean = true, loggedInAu: AccountUser | undefined | null = undefined) {
        this.clearMessenger();

        // no footer when we enter messages in a conversation
        this.footerService.showMobile.set(false);
        this.footerService.showDesktop.set(false);

        if (c) {
            this.selectedConversation = c;

            this.form.controls.conversation_id.setValue(c.getId());

            // only the logged in user can join a chat, make sure to have the user
            // loaded and ready in this method (dont wait for it to load or we will
            // miss the connection here, hence we use loggedInAu, since we use loggedInAu
            // if loggedInAu is a staff member he will not be able to watch a live chat room
            // then you would have to join as a member of the conversation
            if (loggedInAu && c.members) {

                const found_in_members = c.members.find(m => m.id === loggedInAu.getId())
                if (found_in_members) {
                    // join chat room on chat server
                    this.chatService.joinChat(
                        c.getId(),
                        loggedInAu.getId(),
                        loggedInAu.getFullName(),
                        loggedInAu.getMainImage()
                    );
                }
            }

            // load job
            if (c.getJobId()) {
                this.getJob(c.getJobId());
            }

            // load messages
            if (loadMessages) {
                this.getMessages();
            }

            // hide conversations in mobile view
            // when a conversation is selected
            this.mobileShowConversations = false;

            if (isPlatformBrowser(this.platformId) && this.chatTextareaElem && !this.chatTextareaElem.nativeElement.disabled) {
                this.chatTextareaElem.nativeElement.focus();
            }

            // disable textbox if loggedInAccountUser is not among the selected conversation members
            const conversationMemberIDs = c.getMemberIDsInList();
            if (this.loggedInAccountUser && conversationMemberIDs && conversationMemberIDs.indexOf(this.loggedInAccountUser.id) == -1) {
                this.form.controls.text.disable();
            } else {
                this.form.controls.text.enable();
            }

        }
    }

    openPendingJobApplication() {
        if (isPlatformBrowser(this.platformId)) {
            if (localStorage.getItem('pendingJobApplication')) {
                setTimeout( () => {
                    //this.formApplication.controls.message.setValue(localStorage.getItem('pendingJobApplicationWhy'));
                    //this.reloadAccountUser();
                    this.openShowInterestModal();

                    // reset every time we visit the job again
                    localStorage.removeItem('pendingJobApplication');
                    localStorage.removeItem('pendingJobApplicationWhy');
                }, 500 );
            }
        }
    }

    getJob(id: number) {
        this.subJob = this.companyService.getJob(id).subscribe(
            job => {
                this._job = job;

                this.openPendingJobApplication();
            }
        );
    }

    save(event: MouseEvent  | TouchEvent) {
        // dont hide keyboard
        event.preventDefault();
        if (isPlatformBrowser(this.platformId)) {
          this.chatTextareaElem.nativeElement.focus();
        }

        this.submitted = true;

        if (this.selectedMessage) {
            this.update();
        } else {
            this.create();
        }
    }

    toggleSponsoredConversations() {
        this.showSponsored = !this.showSponsored;
        this.getConversations(false, true);
    }

    getConversations(onScroll: boolean = false, manual: boolean = false) {

        if (this._accountUser) {

            this.loadingConversations = true;
            this.showConversationsOnMobile();

            if (manual) {
                this.conversationsPage = 1;
            }

            this.subConversations = this.messengerService.getConversationsPaginated(this._accountUser.getId(), this.conversationsPage, this.listAll, this.showSponsored).subscribe(
                data => {
                    this.conversationsCount = data.count;
                    this.conversationsNext = data.next;
                    this.conversationsPrevious = data.previous;

                    if (data.results) {
                        let r: Conversation[] = [];
                        for (let m of data.results) {
                            r.push(new Conversation().deserialize(m));
                        }
                        // used since we use order_by('-created') i backend
                        // on scroll, add conversations to the list
                        if (onScroll) {
                            if (this.conversations) {
                                this.conversations.reverse();
                                this.conversations = r.concat(this.conversations);
                                this.conversations.reverse();
                            } else {
                                this.conversations = r;
                            }
                        // on reload, reload all conversations
                        } else {
                            this.conversations = r;
                        }
                    }

                    // if no conversation selected, select the first one that has a updated date
                    /*if (!this.selectedConversation) {
                        let index = this.conversations.findIndex(c => c.updated);
                        this.selectConversation(this.conversations[index], true, this.loggedInAccountUser);
                    }*/

                    // if the accountUser has no conversations, init a new conversation with the
                    // accountUser and the loggedInAccountUser if they are not the same and hide the conversation view
                    if (this.conversations && this.conversations.length == 0 && this._accountUser && this.loggedInAccountUser && this._accountUser.getId() != this.loggedInAccountUser.getId()) {
                        this.newConversation([this._accountUser.getId(), this.loggedInAccountUser.getId()]);
                    }
                    this.loadingConversations = false;
                },
                error => {
                    this.loadingConversations = false;
                }
            );
        }
    }

    scrollToBottomOnFocus() {
        if (isPlatformBrowser(this.platformId)) {
            setTimeout( () => {
                this.scrollToBottom();
            }, 800 );
        }
    }

    scrollToBottom() {
        // scroll to bottom by default
        if (isPlatformBrowser(this.platformId)) {
            // Wait for the DOM to update before scrolling
            setTimeout( () => {
                if (this.messageBoxElem) {
                    //this.messageContainerElem.nativeElement.scrollTop = this.messageContainerElem.nativeElement.scrollHeight;
                    //this.messageBodyElem.nativeElement.scrollTop = this.messageBodyElem.nativeElement.scrollHeight;
                    this.messageContainer.nativeElement.scrollTop = this.messageBoxElem.nativeElement.scrollHeight;
                }
                //window.scrollTo(0, document.body.scrollHeight);
            }, 0 );
        }
    }

    onScrollConversationContainer(event) {
        //console.log(event);
        // when we scroll to the bottom, load more pages/conversations
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {

            // if we have more pages with messages to get
            if (this.conversationsNext) {

                /*if (isPlatformBrowser(this.platformId) && this.conversationContainerElem) {
                    this.conversationScrollHeightBeforeLoad = this.conversationContainerElem.nativeElement.scrollHeight;
                }*/

                this.conversationsPage += 1;

                this.getConversations(true);
            }

        }
    }

    onScrollMessageContainer(event) {

        // when we scroll to the top, load more pages/messages
        // get more messages of scroll is <= 30 from top
        if (event.target.scrollTop <= 30) {

            // if we have more pages with messages to get
            if (this.messagesNext) {

                if (isPlatformBrowser(this.platformId)) {
                    this.messageScrollHeightBeforeLoad = this.messageBoxElem.nativeElement.scrollHeight;
                }

                this.messagesPage += 1;

                if (this.selectedConversation) {
                    this.getMessages(false, true);
                }

            }

        }
    }

    groupMessagesByDay(): void {
      this.groupedMessages = this.messages.reduce((acc, message) => {
        // Assuming getCreated() returns a Date object or a date string
        const createdDate = new Date(message.getCreated());
        // Standardize the date string to format "YYYY-MM-DD"
        const dateString = createdDate.toISOString().split('T')[0];

        // If the accumulator doesn't have a key for this date, create it
        if (!acc[dateString]) {
          acc[dateString] = [];
        }

        // Add the current message to the array for this date
        acc[dateString].push(message);

        return acc;
      }, {});
    }

    objectKeys(obj: object): string[] {
      return Object.keys(obj);
    }

    getMessages(scrollToBottom: boolean = true, scrollAfterLoad: boolean = false) {

        if (this.selectedConversation) {

            this.selectedConversation.loading = true;
            this.loadingMessages = true;

            this.subMessages = this.messengerService.getMessagesPaginated(this.selectedConversation.getId(), this.messagesPage).subscribe(
                data => {

                    // reset values, but not on scroll
                    if (!scrollAfterLoad) {
                        this.messages = [];
                        this.messagesCount = 0;
                        this.messagesNext = null;
                        this.messagesPrevious = null;

                        this.scrollToBottom();
                    }

                    if (data.results) {
                        let r: Message[] = [];
                        for (let m of data.results) {
                            r.push(new Message().deserialize(m));
                        }
                        // used since we use order_by('-created') i backend
                        r.reverse();
                        this.messages = r.concat(this.messages);
                    }

                    // group messages by day
                    this.groupMessagesByDay();

                    this.messagesCount = data.count;
                    this.messagesNext = data.next;
                    this.messagesPrevious = data.previous;

                    if (this.selectedConversation) {
                        this.selectedConversation.loading = false;
                    }

                    // scroll to top position before loading in more elements
                    // when we load more elements by scrolling to the top
                    if (isPlatformBrowser(this.platformId) && scrollAfterLoad) {
                        setTimeout( () => {
                            this.messageContainer.nativeElement.scrollTop = this.messageBoxElem.nativeElement.scrollHeight - this.messageScrollHeightBeforeLoad;
                            //this.messageContainer.nativeElement.scroll(0, this.messageBoxElem.nativeElement.scrollTop - 30);
                        }, 50 );
                    }

                    // start from bottom
                    if (isPlatformBrowser(this.platformId) && !scrollAfterLoad) {
                        setTimeout( () => {
                            this.messageBoxElem.nativeElement.scrollTop = this.messageBoxElem.nativeElement.scrollHeight;
                        }, 50 );
                    }

                    this.loadingMessages = false;

                    // set message container height
                    //this.messageBodyHeight = this.messageBodyElem.nativeElement.offsetHeight - 20;// - 110; // height in pixels

                    /*if (!this.containerHeight) {
                        this.containerHeight = this.messageBodyHeight;
                    }*/

                    /*if (this.containerHeight) {
                        this.messageBodyHeight = this.containerHeight;
                    }

                    // set min height
                    if (this.messageBodyHeight < 250) {
                        this.messageBodyHeight = 250;
                    }*/

                    /*
                    this.messageContainerElem.nativeElement.style.height = this.messageBodyHeight + 'px';
                    if (this.conversationContainerElem)
                        this.conversationContainerElem.nativeElement.style.height = this.messageBodyHeight + 'px';
                    */

                    // scroll to bottom by default
                    /*if (isPlatformBrowser(this.platformId) && scrollToBottom) {
                        setTimeout( () => {
                            //this.messageContainerElem.nativeElement.scrollTop = this.messageContainerElem.nativeElement.scrollHeight;
                            //this.messageBodyElem.nativeElement.scrollTop = this.messageBodyElem.nativeElement.scrollHeight;
                            this.messageBoxElem.nativeElement.scrollTop = this.messageBoxElem.nativeElement.scrollHeight;
                        }, 50 );
                    }*/

                    // start from bottom
                    if (isPlatformBrowser(this.platformId) && !scrollAfterLoad) {
                        setTimeout( () => {
                            this.messageBoxElem.nativeElement.scrollTop = this.messageBoxElem.nativeElement.scrollHeight;
                            //window.scrollTo(0, document.body.scrollHeight);
                        }, 50 );

                    }

                },
                error => {
                    this.loadingMessages = false;
                    console.error(error);
                }
            );

            // stop loading all other conversations
            if (this.conversations) {
                let convs = this.conversations.filter(c => c.getId() !== this.selectedConversation?.getId())
                for (let conv of convs) {
                    conv.loading = false;
                }
            }

        }

    }

    createMessage() {
        if (this.form.controls.text.value && this.form.controls.text.value.length > 0) {

            this.form.controls.conversation_id.setValue(this.selectedConversation?.getId());

            // only logged in user that is part of a conversation can chat in that conversation
            const found_in_members = this.selectedConversation?.members.find(m => m.id === this.loggedInAccountUser?.getId())
            if (this.loggedInAccountUser && found_in_members) {

                // send a chat message
                this.chatService.sendMessage(
                    this.form.controls.conversation_id.value,
                    this.loggedInAccountUser.getId(),
                    this.loggedInAccountUser.getFullName(),
                    this.loggedInAccountUser.getMainImage(),
                    this.form.controls.text.value
                );

                // save the message in the db
                this.messengerService.createMessage(this.form.value).subscribe(
                    msg => {
                        this.submitted = false;

                        // move conversation to top
                        if (this.conversations && this.selectedConversation) {
                            this.selectedConversation.updated = new Date().toISOString();
                            this.conversations = this.conversations.filter(c => c.id !== this.selectedConversation?.getId());
                            this.conversations.unshift(this.selectedConversation);
                        }

                        //this.form.controls.text.setValue('');

                        this.scrollToBottom();

                        // set focus on textarea
                        if (isPlatformBrowser(this.platformId) && this.chatTextareaElem && !this.chatTextareaElem.nativeElement.disabled) {
                            this.formTextareaHeight = 21;
                            this.chatTextareaElem.nativeElement.style.height = "21px";
                            this.chatTextareaElem.nativeElement.focus();
                        }

                    },
                    error => {
                        this.submitted = false;
                        this.alertService.error("There was an error sending the message. Please try again later.");
                    }
                );


                this.form.controls.text.setValue('');

                this.scrollToBottom();

            }

        }
    }

    createConversation() {
        let jobId;
        if (this._job) {
            jobId = this._job.getId();
        }

        this.messengerService.createConversation(this.members, this.isGhost, jobId).subscribe(
            conv => {
                this.members = []; // reset
                let c = new Conversation().deserialize(conv);
                this.conversations.unshift(c); // add top of list
                setTimeout(() => {
                    this.selectConversation(c, false, this.loggedInAccountUser);
                    this.createMessage();
                }, 500);

                this.mixpanelService.track('messenger conversation created');
            },
            error => {
            }
        );
    }

    create() {
        if (this.form.controls.text.invalid
        ) {
            return;
        }

        // new conversation
        if (!this.selectedConversation) {

            this.createConversation();

            // selected conversation
        } else {
            this.createMessage();
        }

    }

    update() {

        if (this.form.controls.id.invalid ||
            this.form.controls.conversation_id.invalid ||
            this.form.controls.text.invalid
           ) {
               return;
           }

           this.messengerService.updateMessage(this.form.value).subscribe(
               msg => {
                   if (this.selectedConversation)
                       this.selectedConversation.loading = false;
                   this.submitted = false;
                   this.saved.emit(true);
                   this.form.controls.id.setValue('');
                   this.form.controls.text.setValue('');
                   this.selectedMessage = null;
                   this.alertService.success("Message updated");
                   this.getMessages();
               },
               error => {
                   if (this.selectedConversation)
                       this.selectedConversation.loading = false;
                   this.submitted = false;
                   this.alertService.error("There was an error updating the message. Please try again later.");
               }
           );

    }

    editMessage(m: Message) {
        this.selectedMessage = m;
        this.form.controls.id.setValue(m.getId());
        this.form.controls.text.setValue(m.getText());
    }

    showMessageActions(m: Message) {
        if (this.loggedInAccountUser && m.getId()) {
            if (m.getAccountUserId() == this.loggedInAccountUser.getId()) {
                this.hoverMessage = m;
            } else {
                this.hoverMessage = null;
            }
        } else {
            this.hoverMessage = null;
        }
    }

    delete() {

      if (confirm("Delete this message for all members?")) {

        this.messengerService.deleteMessage(this.form.controls.id.value).subscribe(
          msg => {
            if (this.selectedConversation)
              this.selectedConversation.loading = false;
            this.submitted = false;
            this.form.controls.id.setValue('');
            this.form.controls.text.setValue('');
            this.getMessages();
            this.deleted.emit(true);
            this.alertService.warning("Message deleted");
          },
          error =>  {
            if (this.selectedConversation)
              this.selectedConversation.loading = false;
            this.submitted = false;
            this.alertService.error("There was an error deleting the message. Please try again later.");
          }
        );

      }

    }

    deleteMessage(m: Message) {
        this.form.controls.id.setValue(m.getId());
        this.delete();
    }

    /*getTimeAgo(time: string) {
        let lang = localStorage.getItem('currentLang');
        if (lang == 'sv') {
            //moment.locale(lang);
            // use this to customize and shorten the locale text
            moment.locale('sv', {
                relativeTime: {
                    future: 'om %s',
                    past: '%s sedan',
                    s:  'sekunder',
                    ss: '%s sekunder',
                    m:  'minuter',
                    mm: '%d minuter',
                    h:  'timme',
                    hh: '%d timmar',
                    d:  'en dag',
                    dd: '%d dagar',
                    M:  'en månad',
                    MM: '%d mån.',
                    y:  'ett år',
                    yy: '%d år'
                }
            });
        } else {
            //moment.locale('en');
            // use this to customize and shorten the locale text
            moment.locale('en', {
                relativeTime: {
                    future: 'in %s',
                    past: '%s ago',
                    s:  'second',
                    ss: '%s seconds',
                    m:  'a minute',
                    mm: '%d minutes',
                    h:  'an hour',
                    hh: '%d hours',
                    d:  'a day',
                    dd: '%d days',
                    M:  'a month',
                    MM: '%d months',
                    y:  'a year',
                    yy: '%d years'
                }
            });
        }
        return moment(time).fromNow();
    }*/

    openShowInterestModal() {
        this.showInterestModal.open();
    }

    closeShowInterestModal() {
        this.showInterestModal.close();

        if (this._job)
            this.getJob(this._job.getId());
    }

    openProfileModal() {
        this.profileModal.open();
    }

}
