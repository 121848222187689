import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent {

    @Input() badge: string;
    @Input() description: string = "Badge";
    @Input() textSize: string = "text-sm";

    tipBadge: boolean = false;


}
