<div class="flex min-h-full flex-col justify-center px-6 pb-12 lg:px-8 z-20">
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 *ngIf="!useInputName" class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Invite a company</h2>
        <h2 *ngIf="useInputName" class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Invite {{ companyName }}</h2>
        <p class="mt-2 text-center text-sm text-gray-600 max-w">
            Earn <span class="text-green-600 font-semibold">+10 <i class="bi bi-check-circle-fill"></i></span> karma points!
        </p>

        <p *ngIf="fromUserProfile" class="mt-2 text-center text-sm text-gray-600 max-w">
        Enhance your profile's appeal by inviting current or past employers, as well as any company you admire.
        </p>
    </div>

    <div class="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
        <div class="space-y-4">
            <div>
                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Contact email</label>
                <div class="mt-2">
                    <input [(ngModel)]="email" type="text" required placeholder="anna-hr@company.com" class="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6">
                    <p class="mt-2 text-xs text-gray-500">Preferably invite owners, managers or HR/recruitment staff</p>
                </div>
            </div>

            <div *ngIf="!useInputName">
                <div class="flex items-center justify-between">
                    <label for="name" class="block text-sm font-medium leading-6 text-gray-900">Full company name</label>
                </div>
                <div class="mt-2">
                    <input [(ngModel)]="companyName" [readonly]="useInputName" type="text" placeholder="Company Ltd" required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6">
                </div>
            </div>

            <div *ngIf="showUrl">
                <div class="flex items-center justify-between">
                    <label for="name" class="block text-sm font-medium leading-6 text-gray-900">Company website</label>
                </div>
                <div class="mt-2">
                    <input [(ngModel)]="url" type="text" placeholder="https://" required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6">
                </div>
            </div>

            <div>
                <button (click)="send()" type="button" class="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">Send invite</button>
            </div>
        </div>

    </div>
</div>

<app-modal #thanksModal [size]="'md'" (closed)="closedThanksModal()">
    <h2 class="text-2xl font-bold leading-9 tracking-tight text-gray-900">Thank you for inviting {{ companyName }}!</h2>

    <div class="mt-2 text-sm text-gray-500">
        Before {{ companyName }} joins, take a moment to provide some basic information to enhance the appeal of their page when it is viewed. Your input can make a positive impact and create a better first impression for {{ companyName }}.
    </div>

    <div class="my-5 ">
        <a [routerLink]="['/' + pageSlug]" class="btn-blue" target="_blank">
            Edit {{ companyName }} page
        </a>
    </div>
</app-modal>
