import { Component, Input, ViewChild } from '@angular/core';

import { Company } from '../../_models/company';

import { AlertService } from '../../_services/alert.service';
import { CompanyService } from '../../_services/company.service';
import { MixpanelService } from '../../_services/mixpanel.service';

@Component({
  selector: 'app-company-invite',
  templateUrl: './company-invite.component.html',
  styleUrls: ['./company-invite.component.scss']
})
export class CompanyInviteComponent {

    @ViewChild('thanksModal') thanksModal;

    @Input() fromUserProfile: boolean = false;
    @Input() useInputName: boolean = false;
    @Input() showUrl: boolean = true;
    @Input() companyName: string;

    email: string;
    url: string;

    pageSlug: number;

    constructor(
        private alertService: AlertService,
        private companyService: CompanyService,
        private mixpanelService: MixpanelService,
    ) { }

    validateEmail(email: string): boolean {
        const emailRegex: RegExp = /\S+@\S+\.\S+/;
        return emailRegex.test(email);
    }

    send() {

        if (!this.email) {
            this.alertService.error('Please enter an email');
            return;
        }

        if (!this.validateEmail(this.email)) {
            this.alertService.error('Please enter a valid email');
            return;
        }

        if (!this.companyName) {
            this.alertService.error('Please enter a company name');
            return;
        }

        this.companyService.sendCompanyInvite(this.email, this.companyName, this.url).subscribe(
            data => {
                this.pageSlug = data.page_slug;

                this.mixpanelService.track('sent company invite');
                this.thanksModal.open();
            },
            error => {
                if (error.status == 403) {
                    this.alertService.error("The company already has active users. To invite a user to join this company, please reach out to the company administrator.");
                }

                if (error.status == 409) {
                    this.alertService.error("This user could not be invited to this company.");
                }
            }
        );
    }

    closedThanksModal() {
        //this.companyName = '';
        this.email = '';
    }


}
