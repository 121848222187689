import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewChild, ElementRef, NgZone, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { first } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

/*
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { HeaderModule } from '../header/header.module';

import { ModalModule } from '../modal/modal.module';
import { LoginComponent } from '../login/login.component';
*/

//import { SocialAuthService } from "@abacritt/angularx-social-login";
//import { FacebookLoginProvider, GoogleLoginProvider } from "@abacritt/angularx-social-login";

//import { SocialUser } from "@abacritt/angularx-social-login";
import { MixpanelService } from '../../_services/mixpanel.service';

import { Country } from '../../_models/country';

import { AlertService } from '../../_services/alert.service';
import { AuthenticationService } from '../../_services/authentication.service';
import { AccountService } from '../../_services/account.service';
import { CountryService } from '../../_services/country.service';
import { GoogleAnalyticsService } from '../../_services/google-analytics.service';
import { NetworkService } from '../../_services/network.service';

import { userCredentials } from '../../_services/authentication.service';

declare var google:any;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  /*standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ModalModule,
    TranslateModule,
    HeaderModule,
    LoginComponent,
  ],*/
})
export class SignupComponent implements OnInit, AfterViewInit {

    _googleSignUpButton: ElementRef;

    @ViewChild('googleSignUpButton') set googleSignUpButton(gb: ElementRef) {
        // if reloaded and the button is re-generated, init again
        if (!this._googleSignUpButton) {
            this._googleSignUpButton = gb;
            //this.renderGoogleButton();
        }
    }

    @ViewChild('loginModalOnSignup') loginModal;
    //@ViewChild('signup') signup: ElementRef;

    signup: any;

    @Input() navigateTo: string;
    @Input() noNavigation: boolean = false; // if true, ignore navigateTo and default nav, stay on same page
    @Input() email: string | null;

    @Input() socialCode: string;
    @Input() socialWebsite: string;
    @Input() socialToken: string;

    //socialUser: SocialUser;

    @Input() pendingJobApplication: boolean = false;
    @Input() showTitle: boolean = true;
    @Input() showLogin: boolean = true;

    @Input() isCompany: boolean = false;
    @Input() set companyName(companyName: string | null) {
        setTimeout(() => {
            if (companyName) {
                this.signupForm.controls['companyName'].setValue(companyName);
            }
        }, 200);
    }
    @Input() set country(country: string | null) {
        setTimeout(() => {

            if (country) {
                const find = this.countries.find(c => c.name == country);
                if (find) {
                    this.signupForm.controls['countryId'].setValue(find.id);
                }
            } else {

                if (isPlatformBrowser(this.platformId)) {
                    const browserLang = navigator.language;
                    if (!browserLang) {
                        return;
                    }

                    const browserLangCountry = browserLang.split('-')[0];

                    if (!browserLangCountry) {
                        return;
                    }

                    if (!this.countries) {
                        return;
                    }

                    const findCountry = this.countries.find(c => c.language_code == browserLangCountry);
                    if (findCountry) {
                        this.signupForm.controls['countryId'].setValue(findCountry.id);
                    }

                }
            }

        }, 200);
    }
    //@Input() draftJobId: number;

    @Input() hubId: number;
    @Input() hubSlug: string;

    @Output() signedUpAccountUser = new EventEmitter<boolean>();
    @Output() loginSuccessful = new EventEmitter<boolean>();

    signupForm: UntypedFormGroup;

    countries: Country[];

    submitted = false;
    loading = false;
    loadingSocial: boolean = false;
    signupWithEmail: boolean = true;
    showPassword: string = 'password'; // switch between password and text

    clientIpAddress: string;

    wizardStep = 1;
    wizardSteps = [
        {'name': 'Company'},
        {'name': 'User'},
    ];

    pageSlug: string;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private ngZone: NgZone,
        private accountService: AccountService,
        private alertService: AlertService,
        private countryService: CountryService,
        private elementRef: ElementRef,
        private formBuilder: UntypedFormBuilder,
        private gaService: GoogleAnalyticsService,
        private router: Router,
        private authenticationService: AuthenticationService,
        private mixpanelService: MixpanelService,
        private networkService: NetworkService,
        //private socialAuthService: SocialAuthService
    ) { }

    ngOnInit() {

        // init once after view init
        if (isPlatformBrowser(this.platformId)) {
            //window.addEventListener('load', () => {
            try {
                google.accounts.id.initialize({
                    client_id: "866218590921-96msrp70fj8v28q0nn2o2ebc53quojl7.apps.googleusercontent.com",
                    callback: this.signUpWithGoogle.bind(this),
                    auto_select: false,
                    auto_prompt: true,
                    cancel_on_tap_outside: true,

                });
            } catch {

            }

                //this.renderGoogleButton();
            //});
        }

        if (!this.isCompany)
            this.wizardStep = 2;

        this.signup = this.elementRef.nativeElement.querySelector('.signup');

        if (this.isCompany) {
            this.getCountries();

            this.signupForm = this.formBuilder.group({
                companyName: ['', Validators.required, this.isCompanyNameAvailable.bind(this)],
                firstName: ['', Validators.required],
                lastName: ['', Validators.required],
                email: ['', Validators.compose([
                        Validators.required,
                        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]),
                        this.isEmailAvailable.bind(this)
                    ],
                password: ['', Validators.compose([
                                Validators.required,
                                Validators.minLength(8)])
                    ],
                countryId: ['', Validators.required],
            });

            this.mixpanelService.track('opened signup form', {'type': 'company', 'step': 'open'});


        } else {
            this.signupForm = this.formBuilder.group({
                firstName: ['', Validators.required],
                lastName: ['', Validators.required],
                email: ['', Validators.compose([
                        Validators.required,
                        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]), this.isEmailAvailable.bind(this)
                    ],
                password: ['', Validators.required],
            });

            this.mixpanelService.track('opened signup form', {'type': 'user', 'step': 'open'});
        }

        if (this.email) {
            this.signupForm.controls['email'].setValue(this.email);
        }

        try {
            if (environment.production) {
                this.accountService.getClientIpAddress().subscribe(
                    ip => {
                        this.clientIpAddress = ip;
                    }
                );
            }
        } catch {
        }
    }

    ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            if (this._googleSignUpButton) {
                try {
                    google.accounts.id.renderButton(
                        this._googleSignUpButton.nativeElement,
                        { theme: "outline", shape: "pill", logo_alignment: "left", text: "continue_with", size: "large", width: "275px" }
                    );
                } catch {
                }
            }
        }
    }

    sendSignUpEvent(){
        this.gaService.eventEmitter("sign_up", "engagement", "method");
    }

    /*renderGoogleButton() {

        if (this._googleSignUpButton) {

            setTimeout(() => {
                google.accounts.id.renderButton(
                    this._googleSignUpButton.nativeElement,
                    { theme: "outline", shape: "pill", logo_alignment: "left", text: "continue_with", size: "large", width: "275px" }
                );
            }, 500);

            //display the One Tap dialog
            //google.accounts.id.prompt();

        }

    }*/

    // convenience getter for easy access to form fields
    get f() { return this.signupForm.controls; }

    loginSucceeded() {
        //this.closeLoginModal();
        this.loginModal.close();
        this.loginSuccessful.emit(true);
    }

    wizardHasNextStep() {
        if (this.wizardStep >= this.wizardSteps.length) {
            return false;
        }
        return true;
    }

    wizardHasPrevStep() {
        if (this.wizardStep <= 1) {
            return false;
        }
        return true;
    }

    wizardGoNextStep() {
        this.submitted = true;

        // check if form is valid else stop
        if (this.wizardStep == 1) {
            if (
                this.signupForm.controls['companyName'].invalid ||
                this.signupForm.controls['countryId'].invalid
            ) {
                return;
            }
        }
        if (this.wizardStep == 2) {
            if (
                this.signupForm.controls['firstName'].invalid ||
                this.signupForm.controls['lastName'].invalid ||
                this.signupForm.controls['email'].invalid ||
                this.signupForm.controls['password'].invalid
            ) {
                return;
            }
        }
        if (this.wizardStep < this.wizardSteps.length) {
            this.wizardStep++;
            if (isPlatformBrowser(this.platformId)) {
                window.scroll(0, 0); // back to top of page
            }
        }

        this.submitted = false;
    }

    wizardGoPrevStep() {
        if (this.wizardStep > 1) {
            this.wizardStep--;
            if (isPlatformBrowser(this.platformId)) {
                window.scroll(0, 0); // back to top of page
            }
        }
    }

    getCountries(): void {
        this.countryService.getCountries().subscribe(
            countries => {
                this.countries = countries;
            }
        );
    }

    isEmailAvailable(control: UntypedFormControl) {
        if (isPlatformBrowser(this.platformId)) {
            const q = new Promise((resolve, reject) => {
                setTimeout(() => {
                    this.authenticationService.checkEmail(control.value).subscribe(
                        (res) => {
                            if(res.length > 0){
                                resolve({ 'emailInUse': true });
                            }
                        },
                        (err) => {
                            resolve(null);
                        });
                }, 1000);
            });
            return q;
        }
        return null;
    }

    isCompanyNameAvailable(control: UntypedFormControl) {
        if (isPlatformBrowser(this.platformId)) {
            const q = new Promise((resolve, reject) => {
                setTimeout(() => {
                    this.authenticationService.checkCompanyName(control.value).subscribe(
                        (res) => {
                            if(res.length > 0){
                                this.pageSlug = res[0].page_slug;
                                resolve({ 'companyNameInUse': true });
                            }
                        },
                        (err) => {
                            resolve(null);
                        });
                }, 1000);
            });
            return q;
        }
        return null;
    }

    setPendingJobApplication() {
        if (this.pendingJobApplication) {
            if (isPlatformBrowser(this.platformId)) {
                localStorage.setItem('pendingJobApplication', this.router.url);
                //localStorage.setItem('pendingJobApplicationWhy', this.formApplication.controls.message.value);
            }
        }
    }

    signInWithDoubleCloud(): void {
        this.socialWebsite = 'doublecloud';
        this.setPendingJobApplication();

        // track signup in Mixpanel
        this.mixpanelService.track('signed up', { 'method': 'hicareer' });

        //this.signupSocial(null, 'doublecloud', null, this.f['email'].value, this.f['password'].value);
    }

    signInWithGithub(): void {
        this.socialWebsite = 'github';
        this.setPendingJobApplication();

        // track signup in Mixpanel
        this.mixpanelService.track('signed up', { 'method': 'github' });

        // on success redirects back to redirect_uri with the code parameter
        let redirectUri = encodeURIComponent(environment.domainFrontend + '/login/?website=github&type=signup'); // NOTE type signup
        if (isPlatformBrowser(this.platformId)) {
            window.location.href = "https://github.com/login/oauth/authorize?scope=user:email&client_id=ebcb2d38f8e56f93bbfb&redirect_uri="+redirectUri;
        }
    }

    // NOTE related to old google login button
    signUpWithGoogle(response: any) {
        this.socialWebsite = 'google';
        this.setPendingJobApplication();

        // track signup in Mixpanel
        this.mixpanelService.track('signed up', { 'method': 'google' });

        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem('currentUser');
            localStorage.removeItem('viewingProfileSlug');
        }

        //console.log("signUp", response);

        //this.signupSocial(null, 'google', response['credential'], null, null);
    }

    /*signUpWithGoogleCredentials(credentials: userCredentials) {
        // track signup in Mixpanel
        this.mixpanelService.track('signed up', { 'method': 'google' });

        //this.signupSocial(null, 'google', credentials.token, credentials.email, null);
        this.signupSocial(credentials);
    }*/

    /*signInWithGoogle(): void {
        this.socialWebsite = 'google';
        this.setPendingJobApplication();

        if (isPlatformBrowser(this.platformId)) {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('viewingProfileSlug');
        }

        this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);

        this.socialAuthService.authState.subscribe(
            user => {
                this.signupSocial(null, 'google', user.authToken, null, null);
                this.socialUser = user;
            },
            error => {
                this.signOut();
            });
    }

  signInWithFacebook(): void {
    this.socialWebsite = 'facebook';
    this.setPendingJobApplication();

    if (isPlatformBrowser(this.platformId)) {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('viewingProfileSlug');
    }

    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);

    this.socialAuthService.authState.subscribe(
      user => {
        this.signupSocial(null, 'facebook', user.authToken, null, null);
        this.socialUser = user;
      },
      error => {
        this.signOut();
      });
  }*/

  signInWithLinkedIn(): void {
    this.socialWebsite = 'linkedin';
    this.setPendingJobApplication();

    // track signup in Mixpanel
    this.mixpanelService.track('signed up', { 'method': 'linkedin' });

    // on success redirects back to redirect_uri with the code parameter
    let redirectUri = encodeURIComponent(environment.domainFrontend + '/login/linkedin/signup');
    if (isPlatformBrowser(this.platformId)) {
        window.location.href = "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=778j3kd346w9ml&redirect_uri="+redirectUri+"&state=dfkjg09385eof&scope=r_liteprofile%20r_emailaddress"; //%20w_member_social
    }
  }

  /*signInWithTwitter(): void {
    this.socialWebsite = 'twitter';
    // on success redirects back to redirect_uri with the code parameter
    let redirectUri = encodeURIComponent(environment.domainFrontend + '/login/twitter/');
    console.log(redirectUri);
    if (isPlatformBrowser(this.platformId)) {
    window.location.href = "https://twitter.com/i/oauth2/authorize?response_type=code&client_id=N3hlajFZQnY4VVFJLVVBSzMwWWI6MTpjaQ&redirect_uri="+redirectUri+"&scope=tweet.read%20users.read%20offline.access&state=45vtfdg57gbd&code_challenge=12345&code_challenge_method=plain";
    }
  }*/

  signOut(): void {
    this.mixpanelService.track('logged out');

    if (isPlatformBrowser(this.platformId)) {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('viewingProfileSlug');
    }

    /*if (this.socialUser) {
      this.socialAuthService.signOut();
    }*/
    //this.socialUser = undefined;

    this.authenticationService.logout();
    this.accountService.setLoggedInAccountUser(undefined);
  }

  //signupSocial(code, website, token, username, password) {
  signupSocial(credentials: userCredentials) {

    this.setPendingJobApplication();

    if (isPlatformBrowser(this.platformId)) {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('viewingProfileSlug');
    }

    if (credentials.provider != 'doublecloud') {
      this.loadingSocial = true;
    }
    if (isPlatformBrowser(this.platformId)) {
        this.ngZone.run(() => {
            this.loading = true;
            //this.authenticationService.getAccess('signup', code, website, token, username, password).subscribe(
            this.authenticationService.signOnViaProvider(credentials, 'signup').subscribe(
                data => {
                    // track signup in Google Analytics
                    this.sendSignUpEvent();

                    // track signup in Mixpanel
                    // this.mixpanelService.track('signed up', { 'method': website });

                    // get and set the user to the service
                    //let user = new User().deserialize(data);
                    //this.accountService.setUser(user);

                    this.accountService.getAccountUser(data['au_id']).subscribe(
                        au => {
                            this.accountService.setLoggedInAccountUser(au);
                        }
                    );

                    // set logged in account user slug to local storage for easy access
                    if (isPlatformBrowser(this.platformId)) {
                        localStorage.setItem('viewingProfileSlug', data['slug']);
                    }

                    if (this.hubId && this.hubSlug) {
                        this.joinHub();
                        this.router.navigate(["/hub/", this.hubSlug]);

                    } else if (!this.noNavigation) {
                        // if the url is the same dont reload it as it will close the opened
                        // job application modal thats opened with pendingJobApplication
                        if (this.navigateTo && this.navigateTo != this.router.url) {
                            this.router.navigate([this.navigateTo]);
                            //this.router.navigate(['/signup-redirect'], { queryParams: { nav: this.navigateTo } });

                            // if navigateTo is empty
                        } else if (!this.navigateTo) {
                            if (this.isCompany) {
                                //this.router.navigate(["/me/dashboard/"], { queryParams: { p: 'company-new' } });
                                // stay on same page

                            } else {
                                // go to users new profile in editing mode
                                if (isPlatformBrowser(this.platformId)) {
                                    localStorage.setItem('profileInEditingMode', 'y');
                                }
                                this.router.navigate(["/hi/", data['slug']]);
                                //this.router.navigate(["/me/profile/"]);
                                //this.router.navigate(['/signup-redirect']);
                            }
                        }
                    }

                    this.loading = false;
                    this.loadingSocial = false;
                },
                error => {
                    if (error == 'Forbidden') {
                        this.alertService.error("This account has been suspended and/or removed.");

                    } else if (error == 'Not Acceptable') {
                        if (this.socialWebsite && this.socialWebsite != "doublecloud") {
                            this.alertService.error("Could not sign in to " + this.capitalizeFirstLetter(this.socialWebsite) + ". Are you registered with " + this.capitalizeFirstLetter(this.socialWebsite) + "?");
                        } else {
                            this.alertService.error("Could not sign in to the selected login provider. Are you selecting the correct login provider?");
                        }

                    } else if (error == 'Not found') {
                        /*if (this.socialUser) {
                            this.alertService.error("The user does not exist, please register first.");
                        } else {
                            this.alertService.error("The user does not exist, please register first.");
                        }*/

                    } else {
                        if (this.socialWebsite == "doublecloud") {
                            this.alertService.error("Could not login. Please check your username and password and try again.");

                        } else if (this.socialWebsite && this.socialWebsite != "doublecloud") {
                            this.alertService.error("Could not sign in to " + this.capitalizeFirstLetter(this.socialWebsite) + ". Are you registered with " + this.capitalizeFirstLetter(this.socialWebsite) + "?");
                        } else {
                            this.alertService.error("Something went wrong, please try again.");
                        }
                    }
                    this.loading = false;
                    this.loadingSocial = false;
                }
            );
        });
    }

  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.signupForm.controls['firstName'].invalid ||
            this.signupForm.controls['lastName'].invalid ||
            this.signupForm.controls['email'].invalid ||
            this.signupForm.controls['password'].invalid
        ){
            return;
        }

        let companyName = null;
        let countryId = null;
        if (this.isCompany) {
            companyName = this.f['companyName'].value;
            countryId = this.f['countryId'].value;

            // stop here if form is invalid
            if (this.signupForm.controls['companyName'].invalid ||
                this.signupForm.controls['firstName'].invalid ||
                this.signupForm.controls['lastName'].invalid ||
                this.signupForm.controls['email'].invalid ||
                this.signupForm.controls['password'].invalid ||
                this.signupForm.controls['countryId'].invalid
            ){
                return;
            }
        }

        let authData = {
            first_name: this.f['firstName'].value,
            last_name: this.f['lastName'].value,
            email: this.f['email'].value,
            password: this.f['password'].value,
            company_name: companyName,
            country_id: countryId,
            //draftJobId: this.draftJobId
        }

        this.loading = true;
        this.authenticationService.signup(authData)
            .pipe(first())
            .subscribe(
                data => {
                    // on success, remove any draft job key
                    if (isPlatformBrowser(this.platformId)) {
                        localStorage.removeItem('jobPostKey');
                    }

                    let credentials: userCredentials = {
                        email: this.f['email'].value,
                        password: this.f['password'].value,
                        firstName: this.f['firstName'].value,
                        lastName: this.f['lastName'].value,
                        picture: null,
                        code: null,
                        token: null,
                        provider: 'doublecloud',
                        type: 'signup'
                    };

                    // On success login
                    //this.authenticationService.login(this.f.email.value, this.f.password.value)
                    //this.authenticationService.getAccess('signup', '', 'doublecloud', '', this.f['email'].value, this.f['password'].value, undefined, this.clientIpAddress).pipe(first()).subscribe(
                    this.authenticationService.signOnViaProvider(credentials, 'signup').subscribe(
                            data => {
                                // track signup in Google Analytics
                                this.sendSignUpEvent();

                                this.signedUpAccountUser.emit(true);

                                this.loading = false;

                                // get and set the user to the service
                                //let user = new User().deserialize(data);
                                //this.accountService.setUser(user);

                                this.accountService.getAccountUser(data['au_id']).subscribe(
                                    au => {
                                        this.accountService.setLoggedInAccountUser(au);

                                        // track signup in Mixpanel
                                        this.mixpanelService.track('signed up', { 'method': 'hicareer' });

                                        // set logged in account user slug to local storage for easy access
                                        if (isPlatformBrowser(this.platformId)) {
                                            localStorage.setItem('viewingProfileSlug', data['slug']);
                                        }

                                        if (this.hubId && this.hubSlug) {
                                            this.joinHub();
                                            this.router.navigate(["/hub/", this.hubSlug]);

                                        } else if (!this.noNavigation) {
                                            if (this.navigateTo) {
                                                this.router.navigate([this.navigateTo]);
                                                //this.router.navigate(['/signup-redirect'], { queryParams: { nav: this.navigateTo } });

                                            } else {
                                                if (this.isCompany) {
                                                    //this.router.navigate(["/me/dashboard/"], { queryParams: { p: 'company', pp: 'jobs' } });

                                                    // if there is only one company on the account, go to it
                                                    let companyId: number | null = null;
                                                    if (au && au.getCompanies().length == 1) {
                                                        companyId = au.getCompanies()[0].id;
                                                        this.router.navigate(["/company/" + companyId]);

                                                    // else go to home feed
                                                    } else {
                                                        this.router.navigate(["/"]);
                                                    }

                                                    //this.router.navigate(['/signup-redirect'], { queryParams: { isCompany: true } });

                                                } else {
                                                    // go to users new profile in editing mode
                                                    if (isPlatformBrowser(this.platformId)) {
                                                        localStorage.setItem('profileInEditingMode', 'y');
                                                    }
                                                    this.router.navigate(["/hi/", data['slug']]);
                                                    //this.router.navigate(["/me/profile/"]);
                                                    //this.router.navigate(['/signup-redirect']);
                                                }
                                            }
                                        }


                                    }
                                );

                            },
                            error => {
                                this.alertService.error("Error logging in, please try again or contact our support");
                                this.loading = false;
                            });
                },
                error => {
                    this.alertService.error("Error signing up, please try again or contact our support");
                });

    }

    toggleShowPassword() {
        if (this.showPassword == 'password') {
            this.showPassword = 'text';
        } else {
            this.showPassword = 'password';
        }
    }

    openLoginModal() {
        this.signup.style.display = 'none';
        this.loginModal.open();
    }

    closeLoginModal() {
        this.signup.style.display = "block";
        // do not call this.loginModal.open() here since modal is already closed on the modal
    }

    joinHub() {
        this.networkService.joinHub(this.hubId).subscribe(
            data => {
                //this.alertService.info(data.message);
            },
            error => {
            }
        );
    }

}
