<div class="head">
    <div class="title">
        {{ 'word.Following' | translate }}
        <div class="tip">
            <i class="bi bi-info-circle"></i>
            <span class="tiptext medium">{{ 'company.Get notified when the companies you follow post a new job opening.' | translate }}</span>
        </div>
        <div style="margin-left: 10px;" class="spinner-border spinner-border-sm" role="status" *ngIf="loading">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div>
        <button style="float: right;" class="btn btn-sm btn-primary" (click)="openFollowDialog()">{{ 'word.Add' | translate }}</button>
    </div>
</div>


<div style="margin-top:10px;" *ngIf="filtered.length == 0 && !loading">
    {{ 'company.Not following any employers yet' | translate }}
</div>
    

<div class="cols" *ngIf="filtered">
    <div class="col" *ngFor="let company of filtered">

        <div [ngClass]="{'empty': !company.getPageIcon()}" (click)="navigateTo(company.getPageSlug())">
            <app-image *ngIf="company.getPageIcon()" [image]="company.getPageIcon()" [ref]="'icon30'" [cache]="true"></app-image>
        </div>
        <div class="title">
            <span class="link" (click)="navigateTo(company.getPageSlug())">{{ company.getPageName() }}</span>
        </div>
        <div>
            <i style="cursor: pointer;" class="bi bi-heart-fill" (click)="unfollowCompany(company)"></i>
        </div>

    </div>
</div>


<app-modal #followCompanyModal [size]="'md'" (closed)="closeFollowDialog()">
    <h4 class="modal-title" id="modal-basic-title">{{ 'company.Follow an Employer' | translate }}</h4>

    <br />

    <app-company-autocomplete *ngIf="followCompanyModal.isOpen()" #companyAutocomplete [companyId]="undefined" (selected)="selectCompany($event)"></app-company-autocomplete>

    <br />

    <button style="width: 150px;" class="btn btn-sm btn-primary" (click)="followCompany()" [disabled]="!selectedCompany">{{ 'word.Follow' | translate }}</button>

</app-modal>

