import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Question, Option } from '../_models/questions';

@Component({
  selector: 'app-question-answer-form',
  templateUrl: './question-answer-form.component.html',
  styleUrls: ['./question-answer-form.component.scss']
})
export class QuestionAnswerFormComponent implements OnInit {

    @Input() set inputQuestions(qs: string) {
        if (qs) {
            try {
                this.questions = JSON.parse(qs);
            } catch (e) {
                this.questions = [];
            }
        }
    }

    @Output() outputAnswers = new EventEmitter<string>();

    questions: Question[] | undefined = [];

    constructor() { }

    ngOnInit() {
        if (this.inputQuestions) {
            try {
                this.questions = JSON.parse(this.inputQuestions);
            } catch (e) {
                this.questions = [];
            }
        }
    }

    /*updateRadioButton(question, option, event) {

        let indexQuestion = this.questions.indexOf(question);

        if (indexQuestion >= 0) {

            let questionToUpdate = this.questions[indexQuestion];

            // uncheck all options
            for (let o of question.options) {
                let oIndex = question.options.indexOf(o);
                if (this.questions)
                    this.questions[indexQuestion].options[oIndex]['selected'] = false;
            }

            // check the selected option
            let indexOption = question.options.indexOf(option);
            if (event && event.target && event.target.checked) {
                if (this.questions)
                    this.questions[indexQuestion].options[indexOption]['selected'] = true;
            } 

            // sort by order
            if (this.questions)
                this.questions[indexQuestion].options.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0)); 

        }

        //this.outputAnswers.emit(JSON.stringify(this.questions));
    }

    updateCheckbox(question, option?, event?) {
        
        let indexQuestion = this.questions.indexOf(question);
        if (option) {
            let indexOption = question.options.indexOf(option);
            if (event && event.target && event.target.checked) {
                this.questions[indexQuestion].options[indexOption]['selected'] = true;
            } else {
                this.questions[indexQuestion].options[indexOption]['selected'] = false;
            }

            // sort by order
            this.questions[indexQuestion].options.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0)); 
        }

        //this.outputAnswers.emit(JSON.stringify(this.questions));
    }

    updateTextarea(question) {
        let indexQuestion = this.questions.indexOf(question);
        this.questions[indexQuestion]['answer'] = question.answer;
        //this.outputAnswers.emit(JSON.stringify(this.questions));
    }*/

    // we need this to avoid setting the emit "on change" on each function
    // above because emit is messing with the form - on change
    emitResult() {
        this.outputAnswers.emit(JSON.stringify(this.questions));
    }

}
