import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AccountUser, AccountUserPublic } from '../../_models/user';
import { Job } from '../../_models/company';

import { AccountService } from '../../_services/account.service';
import { AlertService } from '../../_services/alert.service';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {

    _job: Job;

    @Input() accountUser: AccountUser | AccountUserPublic | undefined | null;
    @Input() companyId: number;
    @Input() set job(j: Job) {
        this._job = j;
        this.setForm();
    }

    @Output() sent = new EventEmitter<boolean>();

    loggedInAccountUser: AccountUser | undefined | null;

    form: UntypedFormGroup;

    types = [
        { value: 'create_profile', viewValue: 'Invite to create a profile' },
        { value: 'create_page', viewValue: 'Invite to create a company page' },
    ]

    roles = [
        { value: 'admin', viewValue: 'Admin' },
        { value: 'manager', viewValue: 'Manager' },
        { value: 'partner', viewValue: 'Partner' },
    ]

    loading: boolean = false;
    submitted: boolean = false;

    constructor(
        private accountService: AccountService,
        private alertService: AlertService,
        private formBuilder: UntypedFormBuilder,
    ) { }

    ngOnInit(): void {
        this.setForm();
    }

    setForm() {
        if (this.companyId) {
            this.form = this.formBuilder.group({
                email: ['', Validators.compose([
                        Validators.required,
                        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]),
                    ],
                name: ['', Validators.required],
                company: ['', Validators.required],
                company_user_role: ['admin', Validators.required]
            });

            this.form.controls.company.setValue(this.companyId);

        } else if (this._job) {
            this.form = this.formBuilder.group({
                email: ['', Validators.compose([
                        Validators.required,
                        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]),
                    ],
                name: ['', Validators.required],
                job: ['', Validators.required],
                note: [''],
            });

            this.form.controls.job.setValue(this._job.getId());


        } else {
            this.form = this.formBuilder.group({
                email: ['', Validators.compose([
                        Validators.required,
                        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]),
                    ],
                name: ['', Validators.required],
                type: ['', Validators.required],
                note: [''],
            });

        }

        this.getLoggedInAccountUser();
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    getLoggedInAccountUser(): void {
        this.accountUser = this.accountService.loggedInAccountUserSig();

        const au = this.accountUser;

        // only admins and managers can invite current users to talent agents
        if (this.accountUser && au && (au.isAdmin() || au.isManager())) {
            this.types = [
                { value: 'become_talent_agent', viewValue: 'Invite to become a Talent Agent' },
            ];

            //this.form.controls.email.setValue(this.accountUser.getEmail());
            //this.form.controls.name.setValue(this.accountUser.getFirstName());

            if(!this._job && !this.companyId) {
                this.form.controls.type.setValue('become_talent_agent');
            }

        }
    }

    createInvite() {
        this.loading = true;
        this.submitted = true;

        if (this.form.invalid) {
            this.loading = false;
            return;
        }

        this.accountService.createInvite(this.form.value).subscribe(
            data => {
                this.loading = false;
                this.submitted = false;
                this.alertService.success("Your invite to " + this.form.controls.name.value + " has been sent!");
                this.sent.emit(true);
            },
            error => {
                this.loading = false;
                this.submitted = false;

                if (error == 'Conflict') {
                    this.alertService.error("This user has already been invited - this invite is cancelled.");

                } else if (error == 'Forbidden') {
                    this.alertService.error("This user has already applied - this invite is cancelled.");

                } else {
                    this.alertService.error("There was an error sending your invite. Please contact our support.");
                }
            });

    }


}
