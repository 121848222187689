<div>
    <label *ngIf="showLabel" class="form-label">{{ 'form.country' | translate }}</label>
    <select class="form-select" [(ngModel)]="country" (change)="emitSelectedCountry()" [ngClass]="{ 'is-invalid': !country && required }" required>
        <option [ngValue]="undefined">{{ 'form.select-country' | translate }}</option>
        <option *ngFor="let country of countries" [ngValue]="country">
            {{ country.name }}
        </option>
    </select>
    <div *ngIf="!country && required" class="error-feedback">
        <div>{{ 'form.this-field-is-required' | translate }}</div>
    </div>
</div>
