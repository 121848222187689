import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { Job } from '../_models/company';

import { CompanyService } from '../_services/company.service'
import { SearchService } from '../_services/search.service'

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss']
})
export class SearchFieldComponent implements OnInit {

    @Input() jobs: Job[];
    @Input() filterWhat: string = '';
    @Input() filterWhere: string = '';

    @Output() whatField = new EventEmitter<string>();
    @Output() whereField = new EventEmitter<string>();
    @Output() search = new EventEmitter<boolean>();

    filtered: Job[];

    filterWhatSelected: boolean = true;
    filterWhereSelected: boolean = false;

    showSearchOptions: boolean = false;

    jobTitleSuggestions: string[] = [];
    filteredTitleSuggestions: string[] = [];

    sugCities: string[] = [];

    constructor(
        private companyService: CompanyService,
        private searchService: SearchService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.getJobTitleSuggestions();
    }

    getJobTitleSuggestions() {
        this.companyService.getJobTitleSuggestions().subscribe(
            ts => {
                this.jobTitleSuggestions = ts;
            }
        );
    }

    getJobs() {
        this.whatField.emit(this.filterWhat);
        this.whereField.emit(this.filterWhere);
        this.search.emit(true);

        this.router.navigate(['/']); // search is currently done only on startpage
        this.searchService.setJobSearch(this.filterWhat);
    }

    doJobSearch() {
        //this.pagesPage = 1;
        this.getJobs();
        this.focusOutWhat();
    }

    clearSearch() {
        this.filterWhat = "";
        this.getJobs();
    }

    filterTitleSuggestions() {

        if (this.filterWhat == '') {
            this.filteredTitleSuggestions = [];

        } else {
            if (this.filterWhat.length > 1) {
                this.filteredTitleSuggestions = this.searchFromArray(this.jobTitleSuggestions, this.filterWhat);
            }
        }
        
    }

    searchFromArray(arr, text) {
        let matches: any[] = [], i;
        for (i = 0; i < arr.length; i++) {
            let title = arr[i].title;
            if (title.toLowerCase().includes(text.toLowerCase())) {
                matches.push(arr[i]);
            }
        }
        return matches;
    };

    selectWhatFilter() {
        this.filterWhatSelected = true;
        this.filterWhereSelected = false;
    }

    selectWhereFilter() {
        this.filterWhereSelected = true;
        this.filterWhatSelected = false;
    }

    focusIn() {
        //setTimeout(() => {
        //    this.showSearchOptions = true;
        //}, 250);
    }

    focusOutWhat() {
        setTimeout(() => {
            //this.sugTitles = [];
            this.filteredTitleSuggestions = [];
            //this.showSearchOptions = false;
        }, 250);
    }

    focusOutWhere() {
        setTimeout(() => {
            this.sugCities = [];
        }, 250);
    }

    selectWhatSuggestion(sug: string) {
        // set filter
        this.filterWhat = sug;

        // give it some time so this.filterWhat can update before the search is made
        setTimeout(() => {
            // new search
            //this.pagesPage = 1;
            this.getJobs();
            this.filteredTitleSuggestions = [];
        }, 250);
    }

    selectWhereSuggestion(sug: string) {
        // new search
        //this.pagesPage = 1;
        this.getJobs();

        // set filter
        this.filterWhere = sug;
        //this.filter();
        this.sugCities = [];
    }

    getWhereSuggestionsFromJobs() {
        let r: string[] = [];
        if (this.jobs)
            for (let j of this.jobs) {
                r.push(j.getCity());
            }

        // remove duplicates
        return r.sort().filter(function(item, pos, ary) {
            return !pos || item.toLowerCase() != ary[pos - 1].toLowerCase();
        });
    }

    getWhereSuggestions() {
        let sCities = this.getWhereSuggestionsFromJobs(); //['Remote', 'Gothenburg'];

        this.sugCities = [];

        if (this.filterWhere != '') {
            for (let s of sCities) {
                if (this.checkStrWhere(s)) {
                    this.sugCities.push(s);
                }
            }
        
        } else {
            this.sugCities = [];
        }
    }

    @HostListener('document:mousedown', ['$event']) onGlobalClick(event): void {
        // close suggestions
        if (event.path)
            if (event.path[0] && event.path[0].className != 'dropdown' && event.path[1] && event.path[1].className != 'dropdown' && event.path[3] && event.path[3].className != 'dropdown') {
                //this.sugTitles = [];
                this.filteredTitleSuggestions = [];
                this.sugCities = [];
            }
    }


    checkStrWhere(search: string) {
        if (search && search.length > 0) {
            if (search.toLowerCase().includes(this.filterWhere.toLowerCase())) {
                return true;
            }
        }
        return false;
    }

    filter() {

        if (this.filterWhat == '' && this.filterWhere == '') {
            this.filtered = this.jobs;

        } else {
            if (this.jobs) {
                this.filtered = this.jobs.filter(j => {
                    if (
                        (
                            this.checkStrWhat(j.getTitle()) ||
                            this.checkStrWhat(j.getCompanyName()) ||
                            this.checkStrWhat(j.getUserSkillSetsAsString())
                        ) && 
                        (
                            this.checkStrWhere(j.getCity())
                        )
                    ) {
                        return true;
                    }
                    return false;
                });

            }
        }

    }

    checkStrWhat(search: string) {
        if (search && search.length > 0) {
            if (search.toLowerCase().includes(this.filterWhat.toLowerCase())) {
                return true;
            }
        }
        return false;
    }

   /* getWhatSuggestionsFromJobs() {
        let r = [];
        if (this.jobs)
            for (let j of this.jobs) {
                r.push(j.getTitle());
            }

        // remove duplicates
        return r.sort().filter(function(item, pos, ary) {
            return !pos || item.toLowerCase() != ary[pos - 1].toLowerCase();
        });
    }

    getWhatSuggestions() {
        let sTitles = this.getWhatSuggestionsFromJobs(); //['Backend developer', 'Software Backend Engineer', 'Frontend dev'];

        this.sugTitles = [];

        if (this.filterWhat != '') {
            for (let s of sTitles) {
                if (this.checkStrWhat(s)) {
                    this.sugTitles.push(s);
                }
            }
        
        } else {
            this.sugTitles = [];
        }
    }*/


    /*strToJson(obj: any) {
        return eval(obj);
    }*/
}
