import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Question, Option } from '../_models/questions';

@Component({
  selector: 'app-question-answers',
  templateUrl: './question-answers.component.html',
  styleUrls: ['./question-answers.component.scss']
})
export class QuestionAnswersComponent implements OnInit {

    @Input() 
        set inputQuestions(qs) {
            this.questions = qs;
        }

    questions: Question[] = [];

    questionsMaxPoints = 0;
    questionsAnswerPoints = 0;
    questionsAnswerPercent = 0;

    constructor() { }

    ngOnInit() {
    }

    getMaxPoints(question) {
        let points = 0;
        let pointList: any[] = [];
        
        if (question.options) {
            for (let option of question.options) {
                if (option.points > 0) {
                    pointList.push(option.points);
                }
            }
            points += Math.max.apply(Math, pointList);
        }
        return points;
    }

}
