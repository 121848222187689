import { Directive, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';


@Directive({
  selector: '[appInfiniteScroll]'
})
export class InfiniteScrollDirective {

    @Output() bottomReached = new EventEmitter<boolean>();

    constructor() { } // private el: ElementRef

    @HostListener('scroll', ['$event'])
    onScroll(event: any): void {
        const div = event.target;
        // check if we're at the bottom
        if (div.offsetHeight + div.scrollTop >= div.scrollHeight) {
            this.bottomReached.emit(true);
        }
    }

}
