import { environment } from '../../environments/environment';

import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { Conversation, Message } from '../_models/messenger';


@Injectable({
  providedIn: 'root'
})
export class MessengerService {

    domain = environment.domain;
    prefix = environment.api_prefix;

    constructor(private http: HttpClient) { }

    // Conversation

    /*getConversations(accountUserId?): Observable<Conversation[]> {
        const salt = (new Date()).getTime(); // avoid browser cache
        let url = this.domain + this.prefix + '/messenger-conv/';

        if (accountUserId) {
            url += '?auId=' + accountUserId;
            url += '&s=' + salt;

        } else {
            url += '?s=' + salt;
        }

        return this.http.get<Conversation[]>(url).pipe(
            map(conversations => conversations
                .map(c => new Conversation().deserialize(c))
            )
        );
    }*/

    getConversationsPaginated(accountUserId?, page = 1, listAll: boolean = false, sponsored: boolean = false): Observable<any> {
        const salt = (new Date()).getTime(); // avoid browser cache
        let url = this.domain + this.prefix + '/messenger-conv/?page=' + page + '&s=' + salt;
        if (accountUserId) {
            url += '&auId=' + accountUserId;
        }
        if (listAll) {
            url += '&lsa=true';
        }
        if (sponsored) {
            url += '&sponsored=true';
        }
        return this.http.get<any>(url);
    }

    getConversation(id): Observable<Conversation> {
        const salt = (new Date()).getTime(); // avoid browser cache
        let url = this.domain + this.prefix + '/messenger-conv/' + id + '/' + '?s=' + salt;
        return this.http.get<Conversation>(url).pipe(
            map(c => new Conversation().deserialize(c))
        );
    }

    getConversationByHash(hash): Observable<Conversation> {
        const salt = (new Date()).getTime(); // avoid browser cache
        let url = this.domain + this.prefix + '/messenger-conv/' + hash + '/get_by_hash/' + '?s=' + salt;
        return this.http.get<Conversation>(url).pipe(
            map(c => new Conversation().deserialize(c))
        );
    }

    getConversationByMembers(members: number[]): Observable<any> {
        const salt = (new Date()).getTime(); // avoid browser cache
        let url = this.domain + this.prefix + '/messenger-conv/get_by_member/' + '?s=' + salt;
        return this.http.post(url, { 'members': members });
    }

    createConversation(members: number[], ghost: boolean = false, jobId: number): Observable<any> {
        let url = this.domain + this.prefix + '/messenger-conv/';
        return this.http.post(url, { 'members': members, 'ghost': ghost, 'jobId': jobId });
    }

    // Messages

    getMessagesPaginated(conversationId, page): Observable<any> {
        const salt = (new Date()).getTime(); // avoid browser cache
        let url = this.domain + this.prefix + '/messenger-msg/?conversation-id=' + conversationId + '&page=' + page + '&s=' + salt;
        return this.http.get<any>(url);
    }

    /*
    getMessages(conversationId): Observable<Message[]> {
        let url = this.domain + this.prefix + '/messenger-msg/?conversation-id=' + conversationId;
        return this.http.get<Message[]>(url).pipe(
            map(messages => messages
                .map(msg => new Message().deserialize(msg))
            )
        );
    }
    */

    createMessage(data): Observable<any> {
        let url = this.domain + this.prefix + '/messenger-msg/';
        return this.http.post(url, data);
    }

    updateMessage(data): Observable<any> {
        let url = this.domain + this.prefix + '/messenger-msg/' + data.id + '/';
        return this.http.put(url, data);
    }

    deleteMessage(id): Observable<any> {
        let url = this.domain + this.prefix + '/messenger-msg/' + id + '/';
        return this.http.delete(url);
    }

}
