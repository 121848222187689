<div class="question-container" *ngFor="let q of questions">
    <div class="question">

        <div class="icon">
            <i class="bi bi-question-square-fill"></i>
        </div>

        <textarea
            class="form-control"
            [ngClass]="{ 'is-invalid':  q.question == '' }"
            placeholder="Question"
            -placeholder
            rows="1"
            [(ngModel)]="q.question"
            (change)="updateQuestion(q)"
            required></textarea>

        <div class="btn-group">
            <button type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            </button>
            <ul class="dropdown-menu">
                <li>
                    <button class="dropdown-item" (click)="deleteQuestion(q)">
                        <i class="bi bi-trash"></i>&nbsp;<span >Remove</span>
                    </button>
                </li>
                <li>
                    <button class="dropdown-item" (click)="moveQuestion(q, -1)">
                        <i class="bi bi-arrow-up"></i>&nbsp;<span >Move up</span>
                    </button>
                </li>
                <li>
                    <button class="dropdown-item" (click)="moveQuestion(q, 1)">
                        <i class="bi bi-arrow-down"></i>&nbsp;<span >Move down</span>
                    </button>
                </li>
            </ul>
        </div>

    </div>

    <div class="question-type">
        <div class="icon">
            <i class="bi bi-arrow-return-right"></i>
        </div>

        <div class="type">
            <div class="form-group">
                <label >How should the user answer?</label>
                <select class="form-select" [(ngModel)]="q.answerType" (keyup)="updateQuestion(q); " [ngClass]="{ 'is-invalid':  (q.answerType == 'options' || q.answerType == 'text+options') && (q.options == undefined || (q.options && q.options.length == 0)) }">
                    <option *ngFor="let answerType of answerTypes" [value]="answerType.key">
                    {{answerType.value}}
                    </option>
                </select>
            </div>
        </div>
    </div>

    <div class="answer-container" *ngIf="q.answerType == 'options' || q.answerType == 'text+options'">

        <div class="option-container">
            <div class="icon">
            </div>

            <div class="option">
                <div class="form-check" style="margin-top: 10px">
                    <label>
                        <input class="form-check-input" type="checkbox" [(ngModel)]="q.selectMultiOptions" (change)="updateQuestion(q)">
                        <span class="form-check-label" >
                            Allow the user to select multiple options
                        </span>
                    </label>
                </div>
            </div>
        </div>

        <div class="option-container" *ngFor="let o of q.options">

            <div class="icon">
                <i class="bi bi-arrow-return-right"></i>
            </div>

            <div class="option">
                <!--
                <input class="form-control" type="text" placeholder="Answer" [(ngModel)]="o.answer" (change)="updateOption(q, o)" [ngClass]="{ 'is-invalid': o.answer == '' }">

                <select class="form-select" style="width: 140px;" [(ngModel)]="o.points" (change)="updateOption(q, o)" [ngClass]="{ 'is-invalid':  o.points == null || o.points == 0 }">
                    <option value="0" disabled selected>Rate the answer</option>
                    <option value="10">Excellent</option>
                    <option value="8">Very good</option>
                    <option value="6">Good</option>
                    <option value="4">Ok</option>
                    <option value="2">Bad</option>
                    <option value="1">Very bad</option>
                </select>
                -->

                <div class="tip">
                    <i class="bi bi-info-circle"></i>
                    <span class="tiptext medium">Rate the answer. This is used to speed up filtering and sorting of applications. The rating is not shown to candidates.</span>
                </div>

                <div class="btn-group">
                    <button type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    </button>
                    <ul class="dropdown-menu">
                        <li>
                            <!--
                            <button class="dropdown-item" (click)="deleteOption(q, o)">
                                <i class="bi bi-trash"></i>&nbsp;<span >Remove</span>
                            </button>
                            -->
                        </li>
                        <li>
                            <button class="dropdown-item" (click)="moveOption(q, o, -1)">
                                <i class="bi bi-arrow-up"></i>&nbsp;<span >Move up</span>
                            </button>
                        </li>
                        <li>
                            <button class="dropdown-item" (click)="moveOption(q, o, 1)">
                                <i class="bi bi-arrow-down"></i>&nbsp;<span >Move down</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>

        </div>

        <div class="option-container">
            <div class="icon">
            </div>

            <div class="option">
                <button class="btn btn-sm btn-primary" (click)="addOption(q)" >Add option</button>
            </div>
        </div>

    </div>

    <!-- we dont need a textarea here where we define the questions since there is no answer here -->
    <div *ngIf="q.answerType == 'text' || q.answerType == 'text+options'">
    </div>


    <br />
</div>

<div class="new-question">

    <button class="btn btn-sm btn-primary" (click)="addQuestion()" >Add qualifying question</button>

</div>
