import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, retry, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { AccountUser } from '../_models/user';
import { Category, Function, Company, CompanyUser, Job, JobStage, JobApplication, JobApplicationMessage, Page, Deal, Project, ProjectMessage, ProjectContact, Sourced, PartnerCompany } from '../_models/company';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

    domain = environment.domain;
    prefix = environment.api_prefix;

    constructor(private http: HttpClient) { }


    getCategories(): Observable<Category[]> {
        let url = this.domain + this.prefix + '/company-category/';

        return this.http.get<Category[]>(url).pipe(
            map(cs => cs
                .map(c => new Category().deserialize(c))
            )
        );
    }

    getFunctions(): Observable<Function[]> {
        let url = this.domain + this.prefix + '/company-function/';

        return this.http.get<Function[]>(url).pipe(
            map(fs => fs
                .map(f => new Function().deserialize(f))
            )
        );
    }

    // Company

    getCompanyByUser(id, slug?: string): Observable<Company> {
        let url = this.domain + this.prefix + '/company/' + id + '/get_detail_by_user/';

        if (slug)
            url = url + '?slug=' + slug;

        return this.http.get<Company>(url).pipe(
            map(company => new Company().deserialize(company))
        );
    }


    hasPremiumOrTrial(id): Observable<any> {
        let url = this.domain + this.prefix + '/company/' + id + '/has_premium_or_trial/';
        return this.http.get<any>(url);
    }


    getCompanies(status?: string): Observable<Company[]> {
        let url = this.domain + this.prefix + '/company/';

        if (status) {
            url += '?status=' + status;
        }

        return this.http.get<Company[]>(url).pipe(
            map(companies => companies
                .map(company => new Company().deserialize(company))
            )
        );
    }

    getCompany(id): Observable<Company> {
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/company/' + id + '/'+ `?timestamp=${timestamp}`;
        return this.http.get<Company>(url).pipe(
            map(company => new Company().deserialize(company))
        );
    }

    getCompanyByName(name): Observable<Company> {
        let url = this.domain + this.prefix + '/company/get_by_name/?name=' + name;
        return this.http.get<Company>(url).pipe(
            map(company => new Company().deserialize(company))
        );
    }

    getOrCreateCompany(name: string): Observable<any> {
        let url = this.domain + this.prefix + '/company/get_or_create/';
        let data = {
            "name": name
        }
        return this.http.post(url, data).pipe(
            map(company => new Company().deserialize(company))
        );;
    }

    // list connected users based on relevance (as suggestions)
    getConnectedFollowers(companyId: number, jobId?: number): Observable<AccountUser[]> {
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/company/' + companyId + '/list_following_connections/'+ `?timestamp=${timestamp}`;

        if (jobId) {
            url += '&job=' + jobId;
        }

        return this.http.get<AccountUser[]>(url).pipe(
            map(aus => aus
                .map(au => new AccountUser().deserialize(au))
            )
        );
    }

    getConnected(companyId: number, keywords: string, filter: any, page: number = 1): Observable<any> {
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/company/' + companyId + '/list_talent_pool/'+ `?timestamp=${timestamp}` + '&page=' + page;

        let data = {
            paginate: 8,
            keywords: keywords,
        };

        // add data type key and data points to filter
        for (let key in filter) {
            data['filter_' + key] = filter[key];
        }

        return this.http.post<any>(url, data);
        //return this.http.get<any>(url);
    }

    getConnectionRequests(companyId: number, page: number = 1): Observable<any> {
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/company/' + companyId + '/list_follow_requests/' + `?timestamp=${timestamp}` + '&page=' + page;

        return this.http.get<any[]>(url);
    }

    createConnectionRequest(companyId: number, accountUserId: number): Observable<any> {
        let url = this.domain + this.prefix + '/company/' + companyId + '/create_follow_request/';

        let data = {
            company: companyId,
            account_user: accountUserId
        };

        return this.http.post<any>(url, data);
    }

    getFollowingCompanies(followerAuId: number): Observable<Company[]> {
        let url = this.domain + this.prefix + '/company/0/list_following/?follower=' + followerAuId;

        return this.http.get<Company[]>(url).pipe(
            map(companies => companies
                .map(company => new Company().deserialize(company))
            )
        );
    }

    isFollowingCompany(companyId): Observable<any> {
        let url = this.domain + this.prefix + '/company/' + companyId + '/is_following/';
        return this.http.post(url, { });
    }

    followCompany(companyId, followerAuId): Observable<any> {
        let url = this.domain + this.prefix + '/company/' + companyId + '/follow/';
        return this.http.post(url, { 'follower': followerAuId });
    }

    unfollowCompany(companyId, followerAuId): Observable<any> {
        let url = this.domain + this.prefix + '/company/' + companyId + '/unfollow/';
        return this.http.post(url, { 'follower': followerAuId });
    }

    // Referral program

    getCompanyReferralPrograms(): Observable<Company[]> {
        let url = this.domain + this.prefix + '/company/list_referral_programs/';

        return this.http.get<Company[]>(url).pipe(
            map(companies => companies
                .map(company => new Company().deserialize(company))
            )
        );
    }

    setCompanyReferralProgramAccepted(company): Observable<any> {
        let url = this.domain + this.prefix + '/company/' + company.id + '/set_referral_program_accepted/';
        return this.http.post(url, {});
    }

    setCompanyReferralProgramDenied(company): Observable<any> {
        let url = this.domain + this.prefix + '/company/' + company.id + '/set_referral_program_denied/';
        return this.http.post(url, {});
    }


    // Search Company and Roaring

    getSearchResults(text: string, country: string): Observable<any[]> {
        let url = this.domain + this.prefix + '/company-search/?search=' + encodeURI(text) + '&country='+ encodeURI(country);
        return this.http.get<any>(url);
    }

    getSearchResult(id, country: string): Observable<Company> {
        let url = this.domain + this.prefix + '/company-search/' + id + '/?country=' + country;
        return this.http.get<Company>(url).pipe(
            map(company => new Company().deserialize(company))
        );
    }


    // Search

    searchJobs(keywords?: string): Observable<Job[]> {
        let url = this.domain + this.prefix + '/company-job-search/';

        if (keywords) {
            url += '?keywords=' + keywords;
        }

        /*if (keywords && location) {
            url += '?keywords=' + keywords + '&location=' + location;

        } else if (keywords) {
            url += '?keywords=' + keywords;

        } else if (location) {
            url += '?location=' + location;
        }*/

        return this.http.get<Job[]>(url).pipe(
            map(jobs => jobs
                .map(job => new Job().deserialize(job))
            )
        );
    }


    // Public Jobs

    /*getPublicJob(id): Observable<Job> {
        let url = this.domain + this.prefix + '/company-job-public/' + id + '/';
        return this.http.get<Job>(url).pipe(
            map(job => new Job().deserialize(job))
        );
    }*/

    getJobDraft(key): Observable<Job> {
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/company-public-job/' + key + '/get_draft/'+ `?timestamp=${timestamp}`;
        return this.http.get<Job>(url).pipe(
            map(job => new Job().deserialize(job)),
            retry(1) // NOTE it retries once if failed
        );
    }

    /*getJobMatchingProfileCount(key): Observable<Job> {
        let url = this.domain + this.prefix + '/company-public-job/' + key + '/get_matching_count/';
        return this.http.get<any>(url);
    }*/

    createJobDraft(data): Observable<any> {
        let url = this.domain + this.prefix + '/company-public-job/create_draft/';
        return this.http.post(url, data);
    }

    updateJobDraft(data): Observable<any> {
        let url = this.domain + this.prefix + '/company-public-job/' + data.id + '/update_draft/';
        return this.http.put(url, data);
    }

    deleteJobDraft(key): Observable<any> {
        let url = this.domain + this.prefix + '/company-public-job/' + key + '/delete_draft/';
        return this.http.delete(url);
    }

    publishJobDraft(data): Observable<any> {
        let url = this.domain + this.prefix + '/company-public-job/publish_draft/';
        return this.http.post(url, data);
    }

    // Jobs

    getJobs(slug?, accountUserId?): Observable<Job[]> {
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/company-job/'+ `?timestamp=${timestamp}`;

        if (slug) {
            url = this.domain + this.prefix + '/company-job/?slug=' + slug + `&timestamp=${timestamp}`;
        }

        if (accountUserId) {
            url = this.domain + this.prefix + '/company-job/?owner=' + accountUserId + `&timestamp=${timestamp}`;
        }

        return this.http.get<Job[]>(url).pipe(
            map(jobs => jobs
                .map(job => new Job().deserialize(job))
            )
        );
    }

    getPendingJobs(): Observable<Job[]> {
        let url = this.domain + this.prefix + '/company-job/list_pending_jobs/';

        return this.http.get<Job[]>(url).pipe(
            map(jobs => jobs
                .map(job => new Job().deserialize(job))
            )
        );
    }

    getJob(id): Observable<Job> {
        // NOTE i use the same id multiple times on places like job-detail component and thats
        // a problem since http will return a cached value and the job will not update, to solve
        // this use timestamp
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/company-job/' + id + `/?timestamp=${timestamp}`;
        return this.http.get<Job>(url).pipe(
            map(job => new Job().deserialize(job))
        );
    }

    getJobListings(page: number = 1, what: string, where: string, paginate: number, dataPointIds: number[][], sort: string, limit: number): Observable<any> {
        if (what == undefined)
            what = '';

        if (where == undefined)
            where = '';

        const timestamp = Date.now(); // Add a unique timestamp to the URL

        //let url = this.domain + this.prefix + '/company-job/list_jobs/?page=' + page + '&what=' + encodeURI(what) + '&where=' + encodeURI(where);
        let url = this.domain + this.prefix + '/company-public-job/list_jobs/'+ `?timestamp=${timestamp}` + '&page=' + page;

        /*if (sort)
            url = url + '&sort=' + sort;

        if (func)
            url = url + '&func=' + func;

        if (workFrom)
            url = url + '&wf=' + workFrom;

        if (experience)
            url = url + '&e=' + experience;

        if (paginate)
            url = url + '&paginate=' + paginate;

        if (dataPointIds)
            // matrix to string list
            url = url + '&dps=' + JSON.stringify(dataPointIds);*/


        let data = {
            paginate: paginate,
            what: what,
            where: where,
            sort: sort,
            limit: limit,
            //dps: dataPointIds
        };

        // break down dataPointIds into a string list
        // this sends a list of data points for each group starting with "dps" followed by a number: dpsN
        let index = 0;
        for (let [key, values] of Object.entries(dataPointIds)) {
            index++;
            data['dps' + index.toString()] = values.join(',');
        }

        return this.http.post<any>(url, data);
    }

    getJobRelatedDataPoints(jobId: number): Observable<any> {
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/company-job/' + jobId + '/list_related/'+ `?timestamp=${timestamp}`;
        return this.http.get<any>(url);
    }

    getJobFilterPopularRolesDataPoints() {
        let url = this.domain + this.prefix + '/company-job/list_popular_roles/';
        return this.http.get<any>(url);
    }

    getJobTitleSuggestions(): Observable<string[]> {
        let url = this.domain + this.prefix + '/company-public-job/list_title_suggestions/';
        return this.http.get<string[]>(url);
    }

    getJobMatchingProfiles(jobId: number, page: number = 1): Observable<any> {
        let url = this.domain + this.prefix + '/company-job/' + jobId + '/list_matching/?page=' + page;
        return this.http.get<any>(url);
    }

    getJobsAgentReferralHiredCommission(limit?: number): Observable<Job[]> {
        let url = this.domain + this.prefix + '/company-job/list_referral_jobs/';

        if (limit)
            url = url + '?limit=' + limit;

        return this.http.get<Job[]>(url).pipe(
            map(jobs => jobs
                .map(job => new Job().deserialize(job))
            )
        );
    }

    analyzeJob(data): Observable<any> {
        let url = this.domain + this.prefix + '/company-job/' + data['job_id'] + '/analyze_job/';
        return this.http.post(url, data);
    }

    formatAndSumJob(data): Observable<any> {
        let url = this.domain + this.prefix + '/company-job/' + data['job_id'] + '/format_and_sum/';
        return this.http.post(url, data);
    }


    createJob(slug): Observable<any> {
        let url = this.domain + this.prefix + '/company-job/?slug=' + slug;
        return this.http.post(url, {});
    }

    updateJob(job): Observable<any> {
        return this.http.put(this.domain + this.prefix + '/company-job/' + job.id + '/', job);
    }

    publishJob(job): Observable<any> {
        let url = this.domain + this.prefix + '/company-job/' + job.id + '/publish/';
        return this.http.post(url, {});
    }

    unPublishJob(job): Observable<any> {
        let url = this.domain + this.prefix + '/company-job/' + job.id + '/unpublish/';
        return this.http.post(url, {});
    }

    renew30dJob(job): Observable<any> {
        let url = this.domain + this.prefix + '/company-job/' + job.id + '/renew30d/';
        return this.http.post(url, {});
    }

    addHiringManager(job): Observable<any> {
        let url = this.domain + this.prefix + '/company-job/' + job.id + '/add_hiring_manager/';
        return this.http.post(url, {});
    }

    removeHiringManager(job): Observable<any> {
        let url = this.domain + this.prefix + '/company-job/' + job.id + '/remove_hiring_manager/';
        return this.http.post(url, {});
    }

    cancelJob(job): Observable<any> {
        let url = this.domain + this.prefix + '/company-job/' + job.id + '/cancel/';
        return this.http.post(url, {});
    }

    approveJob(job): Observable<any> {
        let url = this.domain + this.prefix + '/company-job/' + job.id + '/approve/';
        return this.http.post(url, {});
    }

    declineJob(job): Observable<any> {
        let url = this.domain + this.prefix + '/company-job/' + job.id + '/decline/';
        return this.http.post(url, {});
    }

    archiveJob(job): Observable<any> {
        let url = this.domain + this.prefix + '/company-job/' + job.id + '/archive/';
        return this.http.post(url, {});
    }

    createJobApplication(data, jobId): Observable<any> {
        let url = this.domain + this.prefix + '/company-job/' + jobId + '/send_job_application/';
        return this.http.post(url, data);
    }

    createJobExternalClick(jobId): Observable<any> {
        let url = this.domain + this.prefix + '/company-job/' + jobId + '/external_click/';
        return this.http.post(url, {});
    }

    notifyAgents(jobId): Observable<any> {
        let url = this.domain + this.prefix + '/company-job/' + jobId + '/notify_agents/';
        return this.http.post(url, {});
    }

    deleteJob(job): Observable<any> {
        let url = this.domain + this.prefix + '/company-job/' + job.id + '/';
        return this.http.delete(url);
    }

    // job stage

    getJobStages(jobId): Observable<JobStage[]> {
        let url = this.domain + this.prefix + '/company-job-stage/?job-id=' + jobId;
        return this.http.get<JobStage[]>(url).pipe(
            map(stages => stages
                .map(stage => new JobStage().deserialize(stage))
            )
        );
    }

    dndJobApplication(event, jobId): Observable<any> {
        let card_id = event.item.element.nativeElement.dataset.cardId;
        let cards_in_list = event.container.data;
        let cards_in_order: number[] = [];
        let list_id = event.container.element.nativeElement.dataset.listId;

        for (let card of cards_in_list) {
            cards_in_order.push(card.id);
        }

        let url = this.domain +  this.prefix + '/company-job-application/dnd/' + card_id + '/' + jobId + '/' + list_id + '/' + cards_in_order.join(',') + '/';
        return this.http.put(url, {});
    }


    // get for an accountUser or for a job
    getJobApplications(accountUserSlug, jobId): Observable<JobApplication[]> {
        let url = this.domain + this.prefix + '/company-job-application/';

        if (accountUserSlug) {
            url = url + '?slug=' + accountUserSlug;

        } else if (jobId) {
            url = url + '?job=' + jobId;

        }

        return this.http.get<JobApplication[]>(url).pipe(
            map(applications => applications
                .map(application => new JobApplication().deserialize(application))
            )
        );
    }

    getJobApplicationReferrals(): Observable<JobApplication[]> {
        let url = this.domain + this.prefix + '/company-job-application/list_my_referrals/';

        return this.http.get<JobApplication[]>(url).pipe(
            map(applications => applications
                .map(application => new JobApplication().deserialize(application))
            )
        );
    }

    getJobApplication(id): Observable<JobApplication> {
        let url = this.domain + this.prefix + '/company-job-application/' + id + '/';
        return this.http.get<JobApplication>(url).pipe(
            map(ja => new JobApplication().deserialize(ja))
        );
    }

    setJobApplicationStatusActive(jobApplication): Observable<any> {
        let url = this.domain + this.prefix + '/company-job-application/' + jobApplication.id + '/set_status_active/';
        return this.http.post(url, {});
    }

    setJobApplicationStatusCompleted(jobApplication): Observable<any> {
        let url = this.domain + this.prefix + '/company-job-application/' + jobApplication.id + '/set_status_completed/';
        return this.http.post(url, {});
    }

    setJobApplicationHired(jobApplication): Observable<any> {
        let url = this.domain + this.prefix + '/company-job-application/' + jobApplication.id + '/set_hired/';
        return this.http.post(url, {});
    }

    setJobApplicationDeclined(jobApplication): Observable<any> {
        let url = this.domain + this.prefix + '/company-job-application/' + jobApplication.id + '/set_declined/';
        return this.http.post(url, {});
    }

    setJobApplicationArchived(jobApplication): Observable<any> {
        let url = this.domain + this.prefix + '/company-job-application/' + jobApplication.id + '/archive/';
        return this.http.post(url, {});
    }

    setJobApplicationRating(jobApplication, rating): Observable<any> {
        let url = this.domain + this.prefix + '/company-job-application/' + jobApplication.id + '/set_rating/';
        return this.http.post(url, {'rating':rating});
    }

    // Job Application Message

    getJobApplicationMessages(jobAppId): Observable<JobApplicationMessage[]> {
        let url = this.domain + this.prefix + '/company-job-application-message/?job-appli-id=' + jobAppId;
        return this.http.get<JobApplicationMessage[]>(url).pipe(
            map(messages => messages
                .map(msg => new JobApplicationMessage().deserialize(msg))
            )
        );
    }

    getJobApplicationMessage(id): Observable<JobApplicationMessage> {
        let url = this.domain + this.prefix + '/company-job-application-message/' + id + '/';
        return this.http.get<JobApplicationMessage>(url).pipe(
            map(ja => new JobApplicationMessage().deserialize(ja))
        );
    }

    createJobApplicationMessage(data): Observable<any> {
        let url = this.domain + this.prefix + '/company-job-application-message/';
        return this.http.post(url, data);
    }

    updateJobApplicationMessage(data): Observable<any> {
        let url = this.domain + this.prefix + '/company-job-application-message/' + data.id + '/';
        return this.http.put(url, data);
    }

    deleteJobApplicationMessage(id): Observable<any> {
        let url = this.domain + this.prefix + '/company-job-application-message/' + id + '/';
        return this.http.delete(url);
    }

    // Project

    getProjects(slug?: string): Observable<Project[]> {
        let url = this.domain + this.prefix + '/company-project/';

        if (slug) {
            url = this.domain + this.prefix + '/company-project/?slug=' + slug;
        }

        return this.http.get<Project[]>(url).pipe(
            map(projects => projects
                .map(project => new Project().deserialize(project))
            )
        );
    }

    getProjectsByCompany(id): Observable<Project[]> {
        let url = this.domain + this.prefix + '/company-project/list_by_company/?company-id=' + id;

        return this.http.get<Project[]>(url).pipe(
            map(projects => projects
                .map(project => new Project().deserialize(project))
            )
        );
    }

    getProjectByJob(jobId): Observable<Project> {
        let url = this.domain + this.prefix + '/company-project/get_by_job/?job-id=' + jobId;
        return this.http.get<Project>(url).pipe(
            map(p => new Project().deserialize(p))
        );
    }

    getProject(id): Observable<Project> {
        let url = this.domain + this.prefix + '/company-project/' + id + '/';
        return this.http.get<Project>(url).pipe(
            map(p => new Project().deserialize(p))
        );
    }

    createProject(slug, data): Observable<any> {
        let url = this.domain + this.prefix + '/company-project/?slug=' + slug;
        return this.http.post(url, data);
    }

    createProjectJob(projectId: number): Observable<any> {
        let url = this.domain + this.prefix + '/company-project/' + projectId + '/create_project_job/';
        return this.http.post(url, {});
    }

    updateProject(data): Observable<any> {
        let url = this.domain + this.prefix + '/company-project/' + data.id + '/';
        return this.http.put(url, data);
    }

    searchSourced(project_id, name?: string, email?: string, location?: string, job_title?: string, company?: string, skills?: string, keyword?: string): Observable<any> {
        let data = {
            project_id: project_id,
            name: name,
            email: email,
            location: location,
            job_title: job_title,
            company: company,
            skills: skills,
            keyword: keyword
        }
        let url = this.domain + this.prefix + '/company-project/'+project_id+'/search_sourced/';
        return this.http.post(url, data);
    }

    // ProjectMessage

    getProjectMessages(projectId, status?: string): Observable<ProjectMessage[]> {
        let url = this.domain + this.prefix + '/company-project-message/?projectId=' + projectId;

        if (status) {
            url = url + '&status=' + status;
            //console.log(url);
        }

        return this.http.get<ProjectMessage[]>(url).pipe(
            map(messages => messages
                .map(message => new ProjectMessage().deserialize(message))
            )
        );
    }

    getProjectMessageRecipients(messageId): Observable<ProjectContact[]> {
        let url = this.domain + this.prefix + '/company-project-message/' + messageId + '/recipients/';

        return this.http.get<ProjectContact[]>(url).pipe(
            map(ss => ss
                .map(s => new ProjectContact().deserialize(s))
            )
        );
    }

    createProjectMessage(data): Observable<any> {
        let url = this.domain + this.prefix + '/company-project/'+data.project_id+'/create_message/';
        return this.http.post(url, data);
    }

    sendProjectMessage(data): Observable<any> {
        let url = this.domain + this.prefix + '/company-project/'+data.project_id+'/send_message/';
        return this.http.post(url, data);
    }

    clickProjectMessage(projectContactMessageId): Observable<any> {
        let url = this.domain + this.prefix + '/cpmc/' + projectContactMessageId + '/';
        return this.http.get(url);
    }


    removeProjectMessageRecipient(messageId, contactId): Observable<any> {
        let data = {
            contact_id: contactId,
        }
        let url = this.domain + this.prefix + '/company-project-message/' + messageId + '/remove_recipient/';
        return this.http.post(url, data);
    }


    // Project Contact

    getProjectContacts(projectId: number, stage?: string): Observable<ProjectContact[]> {
        let url = this.domain + this.prefix + '/company-project-contact/?project-id=' + projectId + '&stage=' + stage;

        return this.http.get<ProjectContact[]>(url).pipe(
            map(cs => cs
                .map(c => new ProjectContact().deserialize(c))
            )
        );
    }

    getProjectContact(id: number): Observable<ProjectContact> {
        let url = this.domain + this.prefix + '/company-project-contact/' + id + '/';
        return this.http.get<ProjectContact>(url).pipe(
            map(p => new ProjectContact().deserialize(p))
        );
    }

    createProjectContact(data: any): Observable<any> {
        let url = this.domain + this.prefix + '/company-project-contact/';
        return this.http.post(url, data);
    }

    createProjectContactGhost(data: any): Observable<any> {
        let url = this.domain + this.prefix + '/company-project-contact/create_ghost/';
        return this.http.post(url, data);
    }

    createProjectContacts(data: any): Observable<any> {
        // data needs to contain "project_id", "stage", "au-contacts" and "sourced-contacts"
        let url = this.domain + this.prefix + '/company-project-contact/create_contacts/';
        return this.http.post(url, data);
    }

    updateProjectContact(data: any, id: number): Observable<any> {
        let url = this.domain + this.prefix + '/company-project-contact/' + id + '/';
        return this.http.put(url, data);
    }

    deleteProjectContact(id: number): Observable<any> {
        let url = this.domain + this.prefix + '/company-project-contact/' + id + '/';
        return this.http.delete(url);
    }

    // Project Contact Note

    createProjectContactNote(data: any): Observable<any> {
        let url = this.domain + this.prefix + '/company-project-contact-note/';
        return this.http.post(url, data);
    }

    updateProjectContactNote(data: any, id: number): Observable<any> {
        let url = this.domain + this.prefix + '/company-project-contact-note/' + id + '/';
        return this.http.put(url, data);
    }

    deleteProjectContactNote(id: number): Observable<any> {
        let url = this.domain + this.prefix + '/company-project-contact-note/' + id + '/';
        return this.http.delete(url);
    }


    // Sourced

    reportSourced(sourcedId): Observable<any> {
        let url = this.domain + this.prefix + '/company-sourced/' + sourcedId + '/report/';
        return this.http.post(url, {});
    }

    // Public Company Page

    getPublicCompanyPage(slug: string): Observable<Page> {
        const timestamp = Date.now();
        let url = this.domain + this.prefix + '/company-page-public/' + slug + '/?timestamp=' + timestamp;
        return this.http.get<Page>(url).pipe(
            map(p => new Page().deserialize(p))
        );
    }

    // check if a page exists with a given name (iexact)
    getPublicCompanyPageExists(pageName: string): Observable<any> {
        let url = this.domain + this.prefix + '/company-page-public/exists/?name=' + pageName;
        return this.http.get(url);
    }

    // Company Page

    /*getPromotedPages(): Observable<Page[]> {
        let url = this.domain + this.prefix + '/company-page-public/?promoted=1';

        return this.http.get<Page[]>(url).pipe(
            map(pages => pages
                .map(page => new Page().deserialize(page))
            )
        );
    }*/

    getPages(page: number = 1, paginate: number, dataPointIds: number[][], sort: string, limit: number): Observable<any> {
        //let url = this.domain + this.prefix + '/company-job/list_jobs/?page=' + page + '&what=' + encodeURI(what) + '&where=' + encodeURI(where);
        //let url = this.domain + this.prefix + '/company-public-job/list_jobs/'+ `?timestamp=${timestamp}` + '&page=' + page;
        const timestamp = Date.now();
        let url = this.domain + this.prefix + '/company-page-public/all/?page=' + page + '&timestamp=' + timestamp;

        if (sort)
            url = url + '&sort=' + sort;

        if (paginate)
            url = url + '&paginate=' + paginate;
        else
            url = url + '&paginate=8';

        if (limit)
            url = url + '&limit=' + limit;

        /*let data = {
            paginate: paginate,
            sort: sort,
            limit: limit,
            //dps: dataPointIds
        };*/

        // break down dataPointIds into a string list
        // this sends a list of data points for each group starting with "dps" followed by a number: dpsN
        let index = 0;
        for (let [key, values] of Object.entries(dataPointIds)) {
            index++;
            url = url + '&dps' + index.toString() + '=' + values.join(',');
            //data['dps' + index.toString()] = values.join(',');
        }

        //return this.http.post<any>(url, data);
        return this.http.get<any>(url);
    }

    getPromotedPages(page = 1, paginate?: number): Observable<any> {
        let url = this.domain + this.prefix + '/company-page-public/?page=' + page + '&promoted=1';

        if (paginate) {
            url = url + '&paginate=' + paginate;
        }

        return this.http.get<any>(url);
    }

    requestPageAnalysis(webAddress: string, pageId: number): Observable<any> {
        let url = this.domain + this.prefix + '/company-page/' + pageId + '/request_analysis/';

        let data = {
            url: webAddress,
            page_id: pageId
        }

        return this.http.post(url, data);
    }

    getPagesBy(section: any, paginate?: number, params?: any): Observable<any> {
        const timestamp = Date.now();
        let url = this.domain + this.prefix + '/company-page-public/list_by/?section=' + section.key + '&timestamp=' + timestamp;

        if (paginate) {
            url = url + '&paginate=' + paginate;
        }

        if (params && params.page_id) {
            url = url + '&page_id=' + params.page_id;
        }

        if (params && params.dp_id) {
            url = url + '&dp_id=' + params.dp_id;
        }

        if (params && params.dp_name) {
            url = url + '&dp_name=' + params.dp_name;
        }

        return this.http.get<any>(url);
    }

    claimCompany(pageId: number): Observable<any> {
        let url = this.domain + this.prefix + '/company-page-public/' + pageId + '/claim/';
        return this.http.post(url, {});
    }

    createEmpty(companyName: string, webAddress: string): Observable<any> {
        let url = this.domain + this.prefix + '/company-page-public/create_empty/';
        return this.http.post(url, {company_name: companyName, url: webAddress});
    }

    sendCompanyInvite(email: string, companyName: string, webAddress: string): Observable<any> {
        let url = this.domain + this.prefix + '/invite/new_company_invite/';
        return this.http.post(url, {email: email, company_name: companyName, url: webAddress});
    }

    getCompanyPages(): Observable<Page[]> {
        let url = this.domain + this.prefix + '/company-page/';
        return this.http.get<Page[]>(url).pipe(
            map(pages => pages
                .map(page => new Page().deserialize(page))
            )
        );
    }

    getCompanyPage(id): Observable<Page> {
        const timestamp = Date.now();
        let url = this.domain + this.prefix + '/company-page/' + id + '/?timestamp=' + timestamp;

        return this.http.get<Page>(url).pipe(
            map(p => new Page().deserialize(p))
        );
    }

    getCompanyPageUsers(slug): Observable<CompanyUser[]> {
        let url = this.domain + this.prefix + '/company-page/' + slug + '/users/';
        return this.http.get<CompanyUser[]>(url).pipe(
            map(cus => cus
                .map(cu => new CompanyUser().deserialize(cu))
            )
        );
    }

    changeCompanyUserRole(pageId, accountUserId, role): Observable<any> {
        let data = {
            account_user_id: accountUserId,
            role: role
        }
        let url = this.domain + this.prefix + '/company/' + pageId + '/change_user_role/';
        return this.http.post(url, data);
    }

    deleteCompanyUser(pageId, accountUserId): Observable<any> {
        let data = {
            account_user_id: accountUserId,
        }
        let url = this.domain + this.prefix + '/company/' + pageId + '/delete_user/';
        return this.http.post(url, data);
    }

    updateCompanyPage(page): Observable<any> {
        return this.http.put(this.domain + this.prefix + '/company-page/' + page.id + '/', page)
    }

    createCompanyAdmin(companyId: number): Observable<any> {
        let url = this.domain + this.prefix + '/company/create_company_admin/';
        let data = {
            "company_id": companyId
        }
        return this.http.post(url, data);
    }

    createCompanyPartner(companyId: number, partnerId: number): Observable<any> {
        let url = this.domain + this.prefix + '/company/create_company_partner/';
        let data = {
            "company_id": companyId,
            "partner_id": partnerId
        }
        return this.http.post(url, data);
    }

    // both promotes and unpromotes a page
    promotePage(page): Observable<any> {
        let url = this.domain + this.prefix + '/company-page/' + page.id + '/promote/';
        return this.http.post(url, {});
    }


    // Deal

    addPerson(deal, person): Observable<any> {
        return this.http.put(this.domain + this.prefix + '/company-deal/' + deal.id + '/add_person/', person)
    }

    softDeletePerson(deal, person): Observable<any> {
        return this.http.put(this.domain + this.prefix + '/company-deal/' + deal.id + '/remove_person/', person)
    }

    setDealProgress (deal, progress): Observable<any> {
        return this.http.put(this.domain + this.prefix + '/company-deal/' + deal.id + '/set_progress/', progress)
    }

    getDeals(company_id?: number, account_user_id?: number): Observable<Deal[]> {
        let url = this.domain + this.prefix + '/company-deal/';

        if (company_id && account_user_id) {
            url += '?company-id=' + company_id + '&account-user-id=' + account_user_id;

        } else if(company_id) {
            url += '?company-id=' + company_id;

        } else if(account_user_id) {
            url += '?account-user-id=' + account_user_id;
        }

        return this.http.get<Deal[]>(url).pipe(
            map(deals => deals
                .map(deal => new Deal().deserialize(deal))
            )
        );
    }

    getDeal(id): Observable<Deal> {
        let url = this.domain + this.prefix + '/company-deal/' + id + '/';
        return this.http.get<Deal>(url).pipe(
            map(deal => new Deal().deserialize(deal))
        );
    }

    createDeal(data): Observable<any> {
        let url = this.domain + this.prefix + '/company-deal/';
        return this.http.post(url, data);
    }

    updateDeal(deal): Observable<any> {
        return this.http.put(this.domain + this.prefix + '/company-deal/' + deal.id + '/', deal)
    }

    deleteDeal(id): Observable<any> {
        let url = this.domain + this.prefix + '/company-deal/' + id + '/';
        return this.http.delete(url);
    }

    createDealContract(id: number): Observable<any> {
        let url = this.domain + this.prefix + '/company-deal/' + id + '/contract_create/';
        return this.http.post(url, "{}");
    }

    createDealRecruitment(id: number): Observable<any> {
        let url = this.domain + this.prefix + '/company-deal/' + id + '/recruitment_create/';
        return this.http.post(url, "{}");
    }


    // Partner Company

    getPartnerCompanies(partnerId: number): Observable<PartnerCompany[]> {
        let url = this.domain + this.prefix + '/partner-company/?partner-id=' + partnerId;

        return this.http.get<PartnerCompany[]>(url).pipe(
            map(pcs => pcs
                .map(pc => new PartnerCompany().deserialize(pc))
            )
        );
    }

    getPartnerPromotedPages(partnerSlug: string): Observable<Page[]> {
        let url = this.domain + this.prefix + '/partner-company-public-promoted/?partner-slug=' + partnerSlug;
        //return this.http.get<any>(url);
        return this.http.get<Page[]>(url).pipe(
            map(pages => pages
                .map(page => new Page().deserialize(page))
            )
        );
    }

    createPartnerCompany(data): Observable<any> {
        let url = this.domain + this.prefix + '/partner-company/?company-id=' + data.company_id;
        return this.http.post(url, data);
    }

    deletePartnerCompany(id): Observable<any> {
        let url = this.domain + this.prefix + '/partner-company/' + id + '/';
        return this.http.delete(url);
    }

    // Leads

    saveLead(fullName: string, email: string, tel: string, companyName: string, note:string, listKey: string): Observable<any> {
        let url = this.domain + this.prefix + '/company-person-public/add_lead/';
        let data = {
            "full_name": fullName,
            "email": email,
            "tel": tel,
            "company_name": companyName,
            "note": note,
            "list_key": listKey
        }
        return this.http.post(url, data);
    }

}
