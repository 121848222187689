import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
//import { ShareModule } from 'ngx-sharebuttons';
import { ModalModule } from '../../modal/modal.module';
import { NetworkSharedModule } from '../../network/network-shared.module';
import { PipeModule } from '../../_pipe/pipe.module';
import { FooterModule } from '../../footer/footer.module';
import { ImageModule } from '../../image/image.module';

import { InfiniteScrollDirective } from '../../_directive/infinite-scroll.directive';

import { CompanyAutocompleteComponent } from '../../company/company-autocomplete/company-autocomplete.component';
import { CompanyCreateFormComponent } from '../../company/company-create-form/company-create-form.component';
//import { CompanyFollowButtonComponent } from '../../company-follow-button/company-follow-button.component';
import { CompanyInviteComponent } from '../../company/company-invite/company-invite.component';
import { CompanyListComponent } from '../../company/company-list/company-list.component';

import { CountrySelectComponent } from '../../country-select/country-select.component';
import { CurrencySelectComponent } from '../../currency-select/currency-select.component';

import { InviteComponent } from '../../account-user/invite/invite.component';
import { MenuComponent } from '../../account-user/menu/menu.component';
import { HomeMenuComponent } from '../../home/home-menu/home-menu.component';
import { CompanyMenuComponent } from '../../company/company-menu/company-menu.component';

import { QuestionFormComponent } from '../../question-form/question-form.component';
import { QuestionAnswerFormComponent } from '../../question-answer-form/question-answer-form.component';
import { QuestionAnswersComponent } from '../../question-answers/question-answers.component';

//import { ImageComponent } from '../../image/image.component';
//import { LoginComponent } from '../../login/login.component';
import { SearchFieldComponent } from '../../search-field/search-field.component';
//import { SignupComponent } from '../../signup/signup.component';

//import { PostFeedComponent } from '../../network/post-feed/post-feed.component';


@NgModule({
  declarations: [
    CompanyAutocompleteComponent,
    CompanyCreateFormComponent,
    //CompanyFollowButtonComponent,
    CompanyInviteComponent,
    CompanyListComponent,
    CountrySelectComponent,
    CurrencySelectComponent,
    HomeMenuComponent,
    CompanyMenuComponent,
    //ImageComponent,
    InfiniteScrollDirective,
    InviteComponent,
    MenuComponent,
    QuestionFormComponent,
    QuestionAnswerFormComponent,
    QuestionAnswersComponent,
    SearchFieldComponent,
    //SignupComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ImageModule,
    ModalModule,
    NetworkSharedModule,
    PipeModule,
    FooterModule,
    //ShareModule,
    //LoginComponent,
    //SignupComponent,
    TranslateModule.forChild({
        extend: true
    })
  ],
  exports: [
    RouterModule,
    CompanyAutocompleteComponent,
    CompanyCreateFormComponent,
    //CompanyFollowButtonComponent,
    CompanyInviteComponent,
    CompanyListComponent,
    CountrySelectComponent,
    CurrencySelectComponent,
    HomeMenuComponent,
    CompanyMenuComponent,
    //ImageComponent,
    InfiniteScrollDirective,
    InviteComponent,
    MenuComponent,
    //LoginComponent,
    QuestionFormComponent,
    QuestionAnswerFormComponent,
    QuestionAnswersComponent,
    SearchFieldComponent,
    //SignupComponent,
  ]
})
export class GeneralModule { }
