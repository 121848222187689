<div class="question" *ngFor="let q of questions; let i = index">
    
    <div class="text">
        {{ q.question }}
    </div>

    <div *ngIf="q.answerType == 'options' || q.answerType == 'text+options'">

        <div *ngIf="!q.selectMultiOptions">
            <div class="form-check" *ngFor="let o of q.options">
                <label>
                    <!--
                    <input type="radio" name="multiOption-{{ i }}" [value]="o" (change)="updateRadioButton(q, o, $event)" [checked]="o.selected">
                    -->
                    {{ o.answer }}
                </label>
            </div>
        </div>

        <div *ngIf="q.selectMultiOptions">
            <div class="form-check" *ngFor="let o of q.options">
                <label>
                    <!--
                    <input type="checkbox" [value]="o" (change)="updateCheckbox(q, o, $event)" [checked]="o.selected">
                    -->
                    {{ o.answer }}
                </label>
            </div>
        </div>

    </div>

    <!-- we dont need a textarea here where we define the questions since there is no answer here -->
    <div *ngIf="q.answerType == 'text' || q.answerType == 'text+options'">
        <!--
        <textarea 
            class="form-control"
            rows="2"
            [(ngModel)]="q.answer"
            (change)="updateTextarea(q)" 
            placeholder="Answer"
            i18n-placeholder></textarea>
        -->
    </div>

</div>
