import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

import { AccountUser, UserSkillSet } from '../_models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

    domain = environment.domain;
    prefix = environment.api_prefix;

    constructor(
        private http: HttpClient, 
    ) {
    }

    // User Skill Sets
    
    createUserSkillSet(skillSet: UserSkillSet) {
        return this.http.post(this.domain + this.prefix + '/account-user-skill-set/', skillSet);
    }

    updateUserSkillSet(skillSet: UserSkillSet) {
        return this.http.put(this.domain + this.prefix + '/account-user-skill-set/' + skillSet.getId() + '/', skillSet)
    }

    deleteUserSkillSet(id) {
        return this.http.delete(this.domain + this.prefix + '/account-user-skill-set/' + id + '/');
    }

}

