import { Component, OnInit, AfterViewInit, OnDestroy, Input, ViewChild, HostListener, ElementRef, Inject, PLATFORM_ID, effect } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { Subscription } from 'rxjs';

import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { App, AppState } from '@capacitor/app';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';

import { Invite } from '../_models/account';
import { AccountUser } from '../_models/user';
import { Company } from '../_models/company';
import { Image } from '../_models/media';
import { Function } from '../_models/company';

import { AccountService } from '../_services/account.service';
import { AlertService } from '../_services/alert.service';
import { CompanyService } from '../_services/company.service';
import { HeaderService } from '../_services/header.service';
import { SearchService } from '../_services/search.service';
//import { NavService } from '../_services/nav.service';
import { NetworkService } from '../_services/network.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() keywords: string = '';
    @Input() location: string = '';
    @Input() hideSearch: boolean = false
    @Input() hideHeader: boolean = false

    @ViewChild('shareProfileModule') shareProfileModule;
    @ViewChild('headerContainer') headerContainer: ElementRef;
    @ViewChild('forBusinessModal') forBusinessModal;
    @ViewChild('addCompanyModal') addCompanyModal;
    @ViewChild('loginModal') loginModal;
    @ViewChild('signupModal') signupModal;
    @ViewChild('signupCompanyModal') signupCompanyModal;
    @ViewChild('menuModal') menuModal;
    @ViewChild('messenger') messenger;
    @ViewChild('notificationsModal') notificationsModal;
    @ViewChild('nList') nList;
    //@ViewChild('messengerComponent') messengerComponent;

    accountUser: AccountUser | undefined | null;

    receivedInvites: Invite[];

    displayMessage: any;
    displayWarningSubscriptionId: number;

    selectedCompanyToUpgrade: any;
    selectedCompanyId: number;

    selectedCompany: Company;
    selectedCompanyName: string;
    selectedCompanyNameTwoLetter: string;
    selectedCompanyIcon: string;

    pp: string; // career-page, jobs, applications, settings etc

    //openFollowing: boolean = false;

    mobileMainMenu: boolean = false;
    dropdownProfile: boolean = false;
    dropdownEmployer: boolean = false;
    dropdownMessenger: boolean = false;

    promoteBannerTop: boolean = true;

    searchField: string = '';

    private notificationStatusSubscription: Subscription;
    notificationUnreadCount: number = 0;

    selectedTopNav: string = 'home';

    isNativePlatform: boolean = true;
    isIOS: boolean = false;
    allowesPushNotifications: boolean = false;

    showDesktop: boolean = true;
    showMobile: boolean = true;
    showBackBtn: boolean = false;

    titleMobile: string = '';

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        public accountService: AccountService,
        private alertService: AlertService,
        private companyService: CompanyService,
        private headerService: HeaderService,
        private networkService: NetworkService,
        private searchService: SearchService,
        private route: ActivatedRoute,
        private router: Router,
        private elementRef: ElementRef,
        //private navService: NavService,
    ) {
        effect(() => {
            this.accountUser = this.accountService.loggedInAccountUser();
            this.getLoggedInAccountUser();

            this.allowesPushNotifications = this.accountService.pushNotificationsAllowedSig();
        });

        effect(() => {
            this.showDesktop = this.headerService.showDesktop();
            this.showMobile = this.headerService.showMobile();
            this.showBackBtn = this.headerService.showBackBtn();
            this.titleMobile = this.headerService.titleMobile();
        });

        this.initializePermissionListener();
    }

    initializePermissionListener() {
      if (isPlatformBrowser(this.platformId)) {
          App.addListener('appStateChange', (state: AppState) => {
            if (state.isActive) {
              // The app has become active again
              this.checkPushNotificationPermission();
            }
          });
      }
    }

    checkPushNotificationPermission() {
      if (isPlatformBrowser(this.platformId)) {
        if (Capacitor.isNativePlatform()) {
          PushNotifications.checkPermissions().then((status) => {
            switch (status.receive) {
              case 'granted':
                this.allowesPushNotifications = true;
              this.accountService.pushNotificationsAllowedSig.set(true);
              PushNotifications.register();
              break;
              case 'denied':
                this.allowesPushNotifications = false;
              this.accountService.pushNotificationsAllowedSig.set(false);
              break;
              case 'prompt':
                this.allowesPushNotifications = false;
              this.accountService.pushNotificationsAllowedSig.set(false);
              break;
            }
          });
        }
      }
    }

    ngOnInit(): void {

        if (isPlatformBrowser(this.platformId)) {
            this.isNativePlatform = Capacitor.isNativePlatform();

            if (Capacitor.getPlatform() == 'ios') {
                this.isIOS = true;
            }
        }


        this.alertService.getMessage().subscribe(
            msg => {
                this.displayMessage = msg;
            }
        );

        this.searchService.searchField.subscribe(searchField => {
                this.searchField = searchField;
            }
        );

        /*
        this.route.queryParams.subscribe(params => {
            this.pp = params['pp'];
            this.selectedCompanyId = params['sc'];
            this.getCompany();
            //this.hideSearch = true;
        });
        */

        this.route.paramMap.subscribe(params => {
            if (params) {
                if (isPlatformBrowser(this.platformId)) {
                    const path = window.location.pathname;
                    // if browsing a company get the company Id
                    if (path.startsWith('/company/')) {
                        this.selectedTopNav = 'companies';
                        const id = this.route.snapshot.paramMap.get('id');
                        if (id) {
                            this.selectedCompanyId = +id;
                            this.getCompany();
                        }
                    }

                    // top menu select correct tab after navigation
                    if (path.startsWith('/hubs') || path.includes('/hub/')) {
                        this.selectedTopNav = 'hubs';

                    } else if (path.startsWith('/companies')) {
                        this.selectedTopNav = 'companies';

                    } else if (path.startsWith('/jobs') || path.includes('/job/')) {
                        this.selectedTopNav = 'jobs';

                    } else if (path.startsWith('/knowledge')) {
                        this.selectedTopNav = 'knowledge';

                    } else if (path.startsWith('/people')) {
                        this.selectedTopNav = 'people';

                    } else if (path.startsWith('/')) {
                        this.selectedTopNav = 'hubs';

                    } else {
                        this.selectedTopNav = 'none';
                    }
                }
            }
        });

        // On NavigationEnd
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                //this.navService.updateSelectedPage();
                this.headerService.setHeight(this.headerContainer.nativeElement.offsetHeight);
            }
        });

    }

    ngAfterViewInit() {
        this.headerService.setHeight(this.headerContainer.nativeElement.offsetHeight);
    }

    ngOnDestroy(): void {
        // unsubscribe when the component is destroyed to prevent memory leaks
        if (this.notificationStatusSubscription) {
            try {
                this.notificationStatusSubscription.unsubscribe();
            } catch (e) {
                console.log(e);
            }
        }
    }

    @HostListener('document:mouseup', ['$event']) onGlobalClick(event): void {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            // clicked outside => close dropdown list
            this.dropdownProfile = false;
            this.dropdownEmployer = false;
        }
    }

    goBack() {
        if (isPlatformBrowser(this.platformId)) {
            window.history.back();
            this.headerService.showBackBtn.set(false);
        }
    }

    openNotificationSettings() {
      NativeSettings.open({
        optionAndroid: AndroidSettings.ApplicationDetails,
        optionIOS: IOSSettings.App
      });
    }

    getLoggedInAccountUser(): void {

        let ranInvite = false;
        let ranNotification = false;

        const au = this.accountUser;

        if (au) {
            this.displayWarningSubscriptionId = au.hasUnpaidSubscription();

            // run once
            if (!ranNotification) {
                ranNotification = true;

                // get number of unread notifications once
                this.networkService.getNotificationUnreadCountServer().subscribe(
                    data => {
                        this.notificationUnreadCount = +data['count'];
                        this.networkService.setNotificationUnreadCount(this.notificationUnreadCount);
                    }
                );

                // get the number of unread notifications every 1.5 minutes
                this.notificationStatusSubscription = this.networkService.getNotificationUnreadCountIntervalServer().subscribe(
                    data => {
                        this.notificationUnreadCount = +data['count'];
                        this.networkService.setNotificationUnreadCount(this.notificationUnreadCount);
                    }
                );

            }

        }

        // run once
        if (au && au.hasInvites() && !ranInvite) {
            this.getReceivedInvites();
            ranInvite = true;
        }

        //this.getCompany();
    }

    isStaff() {
        if(this.accountUser && (this.accountUser.isAdmin() || this.accountUser.isManager() || this.accountUser.isSeller())) {
            return true;
        }
        return false;
    }

    isCompanyUser() {
        if (this.selectedCompanyId && this.accountUser &&
            (
                this.accountUser.isCompanyAdminFor(this.selectedCompanyId) ||
                this.accountUser.isCompanyManagerFor(this.selectedCompanyId) ||
                this.accountUser.isCompanyPartnerFor(this.selectedCompanyId)
            )
           ) {
            return true;
        }
        return false;
    }

    logout() {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem('currentUser');
            localStorage.removeItem('viewingProfileSlug');
        }
        console.log('logout');
        this.accountService.setLoggedInAccountUser(null);
        this.router.navigate(['/']);
    }

    doSearch() {
        this.searchService.setSearchField(this.searchField);
        this.router.navigate(['/search/page']);
    }

    getImage(img: string) {
        if (img) {
            return new Image().deserialize(img);
        }
        return new Image();
    }

    getCompany() {
        if (this.selectedCompanyId) {
            this.companyService.getCompany(this.selectedCompanyId).subscribe(
                company => {
                    this.selectedCompany = company;
                },
                error => {
                }
            );
        }
    }

    /*getCompany() {
        if (!this.accountUser)
            return;

        const found = this.accountUser.getCompanies().find(c => c.id == this.selectedCompanyId);

        // if company is selected and is in users company list
        if (found) {
            this.selectedCompanyName = found.name;
            this.selectedCompanyNameTwoLetter = found.name.slice(0, 2).toUpperCase();
            this.selectedCompanyIcon = found.page_icon;

        // if there is only one company in the users list auto select it
        } else if (this.accountUser.getCompanies().length == 1) {
            let c = this.accountUser.getCompanies()[0];
            this.selectedCompanyId = c.id;
            this.selectedCompanyName = c.name;
            this.selectedCompanyNameTwoLetter = c.name.slice(0, 2).toUpperCase();
            this.selectedCompanyIcon = c.page_icon;

        // if a staff user is browsing other companies
        } else if (!found && this.isStaff() && this.selectedCompanyId) {
            this.companyService.getCompany(this.selectedCompanyId).subscribe(
                company => {
                    this.selectedCompanyName = company.name;
                    this.selectedCompanyNameTwoLetter = company.name.slice(0, 2).toUpperCase();
                    this.selectedCompanyIcon = company.page_icon;

                }
            );
        } else {
            this.selectedCompanyName = null;
            this.selectedCompanyNameTwoLetter = null;
            this.selectedCompanyIcon = null;
        }
    }*/

    getReceivedInvites() {
        if (this.accountUser) {
            this.accountService.getReceivedInvites(this.accountUser.getId()).subscribe(
                r => {
                    this.receivedInvites = r;
                },
            );
        }
    }

    acceptInvite(invite) {
        this.accountService.acceptInvite(invite.getId()).subscribe(
            accepted => {
                this.alertService.success("Invite accepted!");
                this.getReceivedInvites();
            },
            error => {
                this.alertService.error("There was an error accepting the invite. Please contact our support.");
            },
        );
    }

    ignoreInvite(invite) {
        this.accountService.ignoreInvite(invite.getId()).subscribe(
            accepted => {
                this.alertService.warning("Invite ignored!");
                this.getReceivedInvites();
            },
            error => {
                this.alertService.error("There was an error ignoring the invite. Please contact our support.");
            },
        );
    }

    // the main company that the current user is using, this company
    // can have sub companies ("accounts") so the frontend needs to know
    // who is the master
    setMasterCompany(c: any) {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('masterId', c.id);
            localStorage.setItem('masterName', c.name);
            localStorage.setItem('masterSlug', c.page_slug);
        };
    }

    navigateToEarningsPage() {
        if (!this.accountUser) {
            return;
        }
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('epau', this.accountUser?.getId().toString() || ''); // display earnings page account user id
        };
        this.router.navigate(['/me/earnings']);
    }

    openAddCompanyModal() {
        this.addCompanyModal.open();
    }

    closeAddCompanyModal() {
        this.addCompanyModal.close();
    }

    openLoginModal() {
        this.loginModal.open();
    }

    closeLoginModal() {
        this.loginModal.close();
    }

    openSignupModal() {
        this.signupModal.open();
    }

    openSignupCompanyModal() {
        this.signupCompanyModal.open();
    }

    openMenuModal() {
        this.menuModal.open();
    }

    closeMenuModal() {
        this.menuModal.close();
    }

    //openMessenger() {
    //    this.messenger.open();
    //    this.messengerComponent.scrollToBottom();
    //}

    openNotifications() {
        this.notificationsModal.open();
        this.notificationUnreadCount = 0;
    }

}
