import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Currency } from '../_models/currency';

import { CurrencyService } from '../_services/currency.service';

@Component({
  selector: 'app-currency-select',
  templateUrl: './currency-select.component.html',
  styleUrls: ['./currency-select.component.scss']
})
export class CurrencySelectComponent implements OnInit {

    private _currencyId: number;

    @Input() set currencyId(id: number) {
        this._currencyId = id;
        this.getCurrencies();
    }

    @Input() required: boolean = false;
    @Input() disabled: boolean = false;

    @Output() selected = new EventEmitter<Currency>();

    currencies: Currency[];
    currency: Currency | undefined;

    constructor(
        private currencyService: CurrencyService
    ) { }

    ngOnInit() {
        this.getCurrencies();
    }

    getCurrencies(): void {
        this.currencyService.getCurrencies().subscribe(
            currencies => {
                this.currencies = currencies;
                
                if (this._currencyId) {
                    this.currency = this.currencies.find(currency => currency.id === this._currencyId)
                }
            }
        );
    }

    emitSelectedCurrency() {
        this.selected.emit(this.currency);
    }

}
