<div style="margin: auto; width: 100%;">
    <form [formGroup]="form" (ngSubmit)="createInvite()" class="invite-form">
        <div>
            <h3 class="text-lg font-semibold" *ngIf="!companyId">{{ 'Invite via email' | translate }}</h3>
            <h3 class="text-lg font-semibold" *ngIf="companyId">{{ 'invite.add-user' | translate }}</h3>

            <div *ngIf="_job" class="info">{{ 'Know someone ideal for this job? Invite them to apply; HiCareer will track the invitation.' | translate }}</div>

            <div class="form-group mt-3">
                <label for="email">{{ 'form.email' | translate }}</label>
                <input type="text" formControlName="email" placeholder="user@email.com" class="form-control" [ngClass]="{ 'is-invalid':  submitted && f.email.errors }" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">{{ 'form.this-field-is-required' | translate }}</div>
                    <div *ngIf="f.email.errors.pattern">{{ 'form.enter-a-valid-email' | translate }}</div>
                </div>
            </div>

            <div class="form-group" style="margin-top: 8px;">
                <label for="email">{{ 'form.first-name' | translate }}</label>
                <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid':  submitted && f.name.errors }" />
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors.required">{{ 'form.this-field-is-required' | translate }}</div>
                </div>
            </div>

            <div class="form-group" style="margin-top: 8px;" *ngIf="companyId">
                <label for="email">{{ 'form.Role' | translate }}</label>
                <select class="form-select" formControlName="company_user_role" [ngClass]="{ 'is-invalid':  submitted && f.company_user_role.errors }">
                    <option *ngFor="let r of roles" [value]="r.value">
                    {{ r.viewValue }}
                    </option>
                </select>
                <div *ngIf="submitted && f.company_user_role.errors" class="invalid-feedback">
                    <div *ngIf="f.company_user_role.errors.required">{{ 'form.this-field-is-required' | translate }}</div>
                </div>
            </div>

            <div *ngIf="!_job && !companyId">
                <label>{{ 'invite.type-of-invite' | translate }}</label>
                <select class="form-select" formControlName="type" [ngClass]="{ 'is-invalid':  submitted && f.type.errors }">
                    <option *ngFor="let r of types" [value]="r.value">
                    {{ r.viewValue }}
                    </option>
                </select>
                <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
                    <div *ngIf="f.type.errors.required">{{ 'form.this-field-is-required' | translate }}</div>
                </div>
            </div>

            <div style="margin-top: 8px;" *ngIf="!companyId">
                <label>{{ 'invite.message-optional' | translate }}</label>
                <textarea
                    class="form-control"
                    placeholder="{{ 'invite.a-personal-message' | translate }}"
                    formControlName="note"
                    maxlength="350"
                    rows="4"></textarea>
            </div>

            <br />

            <button type="submit" class="btn btn-blue" [disabled]="loading">{{ 'invite.send-invite' | translate }}</button>

        </div>

    </form>

</div>
