import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, HostListener } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { AlertService } from '../_services/alert.service';
import { MediaService } from '../_services/media.service';

import { Image } from '../_models/media';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {

    _imgLib: any[];

    @Input() image: Image;
    @Input() set setImage(img: Image) {
        this.image = img;
    }
    @Input() set imageLibrary(lib: any[]) {
        this._imgLib = lib;
    }
    @Input() edit: boolean = false;
    @Input() error: boolean = false;
    @Input() cache: boolean = true;
    @Input() order: number = 0;
    @Input() ref: string = "normal"; // "normal", "icon", "cover", "story", "circle"
    @Input() hint: string;
    @Input() justEditButton: boolean = false;
    @Input() showOnHover: boolean = false;

    @Input() accountUserId: number;
    @Input() companyId: number;
    @Input() articleId: number;
    @Input() hubId: number;
    @Input() postId: number | string;

    @Output() saved = new EventEmitter<boolean>();
    @Output() savedImage = new EventEmitter<Image>();
    @Output() deleted = new EventEmitter<boolean>();


    @ViewChild('upload') upload: ElementRef;
    //@ViewChild('modal') modal;

    loading: boolean = false;

    milliseconds = Date.now();

    imageToUpload: File | null
    imageControl = new UntypedFormControl();

    updated = false;

    dropdownOptions: boolean = false;

    constructor(
        private alertService: AlertService,
        private mediaService: MediaService,
        private elementRef: ElementRef
    ) { }

    ngOnInit(): void {
    }

    @HostListener('document:mouseup', ['$event']) onGlobalClick(event): void {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            // clicked outside => close dropdown list
            this.dropdownOptions = false;
        }
    }

    imageFileInput(event: any | null) {

        const files: FileList = event.target.files;

        this.imageToUpload = files.item(0);
        this.imageControl.markAsDirty();

        if (this.imageToUpload) {

            if (this.imageToUpload.type != 'image/jpeg' &&
                this.imageToUpload.type != 'image/png' &&
                this.imageToUpload.type != 'image/heic' &&
                this.imageToUpload.type != 'image/heif') {

                this.imageControl.setErrors({'incorrect': true});
                this.alertService.error("Incorrect image format, please use JPEG, PNG or HEIC");

            } else if(this.imageToUpload.size > 31457280) { // 30 MB
                this.imageControl.setErrors({'size': true});
                this.alertService.error("The image is too large, plase keep it smaller or equal to 30 MB");

            } else {
                // Image passed checks continue with upload

                this.imageControl.setErrors(null); // clear error
                this.alertService.clear();

                let formData = new FormData();

                if (this.accountUserId) {
                    formData.append('account_user_id', this.accountUserId.toString());
                }
                if (this.companyId) {
                    formData.append('company_id', this.companyId.toString());
                }
                if (this.articleId) {
                    formData.append('article_id', this.articleId.toString());
                }
                if (this.hubId) {
                    formData.append('hub_id', this.hubId.toString());
                }
                if (this.postId) {
                    formData.append('post_id', this.postId.toString());
                }

                // if removing an icon send this key to the backend
                if (this.ref == 'icon') {
                    formData.append('ref', 'icon');
                }

                // Update
                if (this.image && this.image.getId()) {
                    this.loading = true;

                    formData.append('image', this.imageToUpload, this.image.getId().toString());

                    this.mediaService.updateImage(formData, this.image.getId()).subscribe(
                        data => {
                            this.loading = false;
                            this.imageControl.markAsPristine(); // remove dirty
                            this.saved.emit(true);
                            this.savedImage.emit(new Image().deserialize(data));
                            this.updated = true;
                            this.milliseconds = Date.now();
                            this.alertService.success("Image successfully uploaded");
                        },
                        error => {
                            this.loading = false;
                            this.upload.nativeElement.value = '';
                            this.alertService.error("Error uploading the image, please contact our support for help");
                            console.log(error);
                        }
                    );
                // Create
                } else {
                    this.loading = true;
                    formData.append('image', this.imageToUpload);
                    formData.append('order', this.order.toString());

                    this.mediaService.createImage(formData).subscribe(
                        data => {
                            this.loading = false;

                            if (this.postId) {
                                this.image = new Image().deserialize(data);
                            }

                            this.imageControl.markAsPristine(); // remove dirty
                            this.saved.emit(true);
                            this.savedImage.emit(new Image().deserialize(data));
                            this.updated = true;
                            this.milliseconds = Date.now();
                            this.alertService.success("Image successfully uploaded");
                        },
                        error => {
                            this.loading = false;
                            this.upload.nativeElement.value = '';
                            this.alertService.error("Error uploading the image, please contact our support for help");
                            console.log(error);
                        }
                    );
                }

            }

        }

    }

    //openModal() {
    //    this.modal.open();
    //}

    openUploadDialog() {
        this.upload.nativeElement.click();
        this.dropdownOptions = false;
    }

    // 90 degrees
    // TODO WORK IN PROGRESS NOT WORKING
    // PROBLEM IS FETCHING THE IMAGE AND MAKING IT A BLOB
    rotatePhoto() {
        this.imageControl.setErrors(null); // clear error
        this.alertService.clear();

        let formData = new FormData();

        if (this.accountUserId) {
            formData.append('account_user_id', this.accountUserId.toString());
        }
        if (this.companyId) {
            formData.append('company_id', this.companyId.toString());
        }
        if (this.articleId) {
            formData.append('article_id', this.articleId.toString());
        }
        if (this.hubId) {
            formData.append('hub_id', this.hubId.toString());
        }
        if (this.postId) {
            formData.append('post_id', this.postId.toString());
        }

        if (this.image) {
            this.loading = true;

            console.log(this.image);

            //const f = this.createFile(this.image.img, 'image/jpeg');
            /*
            this.mediaService.getImageBlob(this.image.img).subscribe(
                file => {
                    console.log(file);
                },
                error => {
                    console.log("err", error);
                }
            );


            formData.append('image', f, this.image.getId().toString());

            this.mediaService.rotateImage(formData, this.image.getId()).subscribe(
                data => {
                    this.loading = false;
                    this.imageControl.markAsPristine(); // remove dirty
                    this.saved.emit(true);
                    this.updated = true;
                    this.milliseconds = Date.now();
                    this.alertService.success("Image rotated");
                },
                error => {
                    this.loading = false;
                    this.upload.nativeElement.value = '';
                    this.alertService.error("Error rotating the image, please contact our support for help");
                    console.log(error);
                }
            );
            */
        }

        this.dropdownOptions = false;

    }

    selectPhoto() {
    }

    removePhoto() {
        if (this.image && confirm("Remove the image?")) {

            this.loading = true;

            this.mediaService.deleteImage(this.image.getId()).subscribe(
                data => {
                    this.loading = false;
                    this.imageControl.markAsPristine(); // remove dirty
                    this.saved.emit(true);
                    this.deleted.emit(true);
                    this.image = new Image();
                    this.upload.nativeElement.value = '';
                    //this.milliseconds = Date.now();
                    this.alertService.warning("Image removed");
                },
                error => {
                    this.alertService.error("Error removing the image, please contact our support for help");
                    console.log(error);
                }
            );

        } else if (!this.image) {
            this.alertService.warning("No image to remove");

        }
    }

    deletePhoto() {
        if (confirm("Detele the image from the library?")) {

        }
    }


}
