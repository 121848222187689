<nav aria-label="Sidebar" class="divide-y divide-gray-300 flex flex-col justify-between" *ngIf="company">

    <div class="space-y-1 pb-8">

        <!--
        <div class="text-gray-700 group flex items-center px-3 py-2 text-sm font-base rounded-md" aria-current="page">
            <img *ngIf="company.getPageIcon()" class="h-6 w-6 mr-3 flex-shrink-0 -ml-1 rounded-full" src="{{ company.getPageIcon().getImg() }}" alt="">
            <div *ngIf="!company.getPageIcon()" class="relative inline-flex items-center justify-center w-6 h-6 mr-3 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                <span class="font-base text-gray-600 dark:text-gray-300">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
                    </svg>
                </span>
            </div>

            <span class="truncate">{{ company.getName() }}</span>
        </div>
        -->

        <a (click)="navigate()" [routerLink]="['/company/' + company.getId() ]" [queryParams]="{ pp: 'talent' }" [ngClass]="{ 'bg-gray-200 text-gray-900': pp == 'talent' ||  !pp }" class="text-gray-700 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-base rounded-md">
            <!-- profile img -->
            <img *ngIf="company.getPageIcon() && company.getPageIcon().getImg()" class="h-6 w-6 mr-3 flex-shrink-0 -ml-1 rounded-full" src="{{ company.getPageIcon().getImg() }}" alt="">
            <!-- avatar -->
            <div *ngIf="!(company.getPageIcon() && company.getPageIcon().getImg())">
                <svg class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 8.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v8.25A2.25 2.25 0 0 0 6 16.5h2.25m8.25-8.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-7.5A2.25 2.25 0 0 1 8.25 18v-1.5m8.25-8.25h-6a2.25 2.25 0 0 0-2.25 2.25v6" />
                </svg>
            </div>

            <span class="truncate">{{ company.getName() }}</span>
        </a>

        <!--
        <a *ngIf="!hasAccess" (click)="navigate()" [routerLink]="['/company/' + company.getId() ]" [queryParams]="{ pp: 'upgrade' }" [ngClass]="{ 'bg-gray-200 text-gray-900': pp == 'upgrade' }" class="text-gray-700 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-base rounded-md">
            <svg class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
            </svg>
            <span class="truncate">Get verified</span>
        </a>

        <a (click)="navigate()" [routerLink]="['/company/' + company.getId() ]" [queryParams]="{ pp: 'pool' }" [ngClass]="{ 'bg-gray-200 text-gray-900': pp == 'pool' }" class="text-gray-700 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-base rounded-md">
            <svg class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
            </svg>
            <span class="truncate">Talent pool</span>
            <span class="ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-base leading-5 text-gray-600 ring-1 ring-inset ring-gray-200" aria-hidden="true">{{ company.getFollowerCount() | number }}</span>
        </a>
        -->

        <a (click)="navigate()" [routerLink]="['/company/' + company.getId() ]" [queryParams]="{ pp: 'career-page' }" [ngClass]="{ 'bg-gray-200 text-gray-900': pp == 'career-page' }" class="text-gray-700 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-base rounded-md">
            <svg class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
            </svg>

            <span class="truncate">Page</span>
            <span class="ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-base leading-5 text-gray-600 ring-1 ring-inset ring-gray-200" aria-hidden="true">
                {{ company.getPageCompleted() | number:'1.0-0' }}%
            </span>
        </a>

        <a (click)="navigate()" [routerLink]="['/company/' + company.getId() ]" [queryParams]="{ pp: 'ads' }" [ngClass]="{ 'bg-gray-200 text-gray-900': pp == 'ads' }" class="text-gray-700 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-base rounded-md">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 1 1 0-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 0 1-1.44-4.282m3.102.069a18.03 18.03 0 0 1-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 0 1 8.835 2.535M10.34 6.66a23.847 23.847 0 0 0 8.835-2.535m0 0A23.74 23.74 0 0 0 18.795 3m.38 1.125a23.91 23.91 0 0 1 1.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 0 0 1.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 0 1 0 3.46" />
            </svg>
            <span class="truncate">Ads</span>
        </a>

        <a (click)="navigate()" [routerLink]="['/company/' + company.getId() ]" [queryParams]="{ pp: 'jobs' }" [ngClass]="{ 'bg-gray-200 text-gray-900': pp == 'job' || pp == 'jobs' }" class="text-gray-700 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-base rounded-md">
            <svg class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
            </svg>
            <span class="truncate">Jobs</span>
            <span class="ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-base leading-5 text-gray-600 ring-1 ring-inset ring-gray-200" aria-hidden="true">{{ company.getJobActiveCount() | number }}</span>
        </a>

        <!--
        <a [routerLink]="['/company/' + company.getId() ]" [queryParams]="{ pp: 'projects' }" [ngClass]="{ 'bg-gray-200 text-gray-900': pp == 'projects' }" class="text-gray-700 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-base rounded-md">
            <svg class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z" />
            </svg>
            <span class="truncate">Projects</span>
        </a>
        -->

        <a (click)="navigate()" [routerLink]="['/company/' + company.getId() ]" [queryParams]="{ pp: 'services' }" [ngClass]="{ 'bg-gray-200 text-gray-900': pp == 'services' }" class="text-gray-700 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-base rounded-md">
            <svg class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
            </svg>
            <span class="truncate">Services</span>
        </a>

        <a (click)="navigate()" [routerLink]="['/company/' + company.getId() ]" [queryParams]="{ pp: 'settings' }" [ngClass]="{ 'bg-gray-200 text-gray-900': pp == 'settings' }" class="text-gray-700 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-base rounded-md">
            <svg class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
            </svg>

            <span class="truncate">Team</span>
        </a>

    </div>

    <a (click)="navigate()" [routerLink]="['/companies']" class="text-gray-700 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-base">
        <svg class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
        </svg>

        <span class="truncate">Back</span>
    </a>
</nav>
