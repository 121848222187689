import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';

import { Company } from '../../_models/company';

import { CompanyService } from '../../_services/company.service'

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent implements OnInit {

    @ViewChild('followCompanyModal') followCompanyModal;
    @ViewChild('companyAutocomplete') companyAutocomplete;

    _followerAccountUserId: number;

    @Input() set followerAccountUserId(id: number) {
        this._followerAccountUserId = id;
        this.getCompanies();
    }

    companies: Company[];
    filtered: Company[] = [];

    selectedCompany: Company | undefined;

    loading = false;

    constructor(
        private companyService: CompanyService,
        private router: Router,
    ) { 
    }

    ngOnInit() {
    }

    // get the companies that the user is following
    getCompanies() {
        if (this._followerAccountUserId) {
            this.loading = true;
            this.companyService.getFollowingCompanies(this._followerAccountUserId).subscribe(
                cs => {
                    this.companies = cs;
                    this.filtered = cs;
                    this.loading = false;
                }
            );
        }
    }

    navigateTo(slug: string) {
        this.router.navigate(['/' + slug + '/']);
    }

    selectCompany(company: Company) {
        this.selectedCompany = company;
    }

    followCompany() {
        if (this.selectedCompany) {
            this.companyService.followCompany(this.selectedCompany.getId(), this._followerAccountUserId).subscribe(
                c => {
                    this.getCompanies();
                    this.followCompanyModal.close();
                }
            );
        } else {
            alert('Please select a company to follow');
        }
    }

    unfollowCompany(company: Company) {
      if (confirm("Are you sure you want to unfollow the company?")) {
        this.companyService.unfollowCompany(company.getId(), this._followerAccountUserId).subscribe(
            c => {
                this.getCompanies();
            }
        );
      }
    }

    openFollowDialog() {
        this.selectedCompany = undefined;
        this.followCompanyModal.open();
    }

    closeFollowDialog() {
        this.companyAutocomplete.form.setValue('');
        this.companyAutocomplete.company = undefined;
    }

}
