import { Component, OnInit, Input, Output, EventEmitter, effect, inject, afterNextRender } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AccountUser } from '../../_models/user';

import { AccountService } from '../../_services/account.service';
import { NavService } from '../../_services/nav.service';

@Component({
  selector: 'app-header-sub-menu',
  templateUrl: './header-sub-menu.component.html',
  styleUrls: ['./header-sub-menu.component.scss']
})
export class HeaderSubMenuComponent implements OnInit {

    accountService = inject(AccountService);
    navService = inject(NavService);
    route = inject(ActivatedRoute);

    @Input() showForwardBtn: boolean = false;

    @Output() forwardBtnClick = new EventEmitter();

    selected: string = 'community';

    loggedInAccountUser: AccountUser | undefined | null;

    constructor() {
      effect(() => {
        this.loggedInAccountUser = this.accountService.loggedInAccountUser();
        this.selected = this.navService.selected();
      });
      afterNextRender(() => {
        this.route.paramMap.subscribe(params => {
          if (params) {
            const path = window.location.pathname;
            let page = 'none';

            if (path.startsWith('/company/')) {
              page = 'companies';

            } else if (path.startsWith('/community')) {
              page = 'hubs';

            } else if (path.startsWith('/hubs') || path.includes('/hub/')) {
              page = 'hubs';

            } else if (path.startsWith('/companies')) {
              page = 'companies';

            } else if (path.startsWith('/jobs') || path.includes('/job/')) {
              page = 'jobs';

            } else if (path.startsWith('/referral')) {
              page = 'referral';

            } else if (path.startsWith('/knowledge')) {
              page = 'stories';

            } else if (path.startsWith('/people')) {
              page = 'people';

            } else if (path.startsWith('/dc/staff')) {
              page = 'staff';

            } else if (path == '/') {
              page = 'career';

            } else {
              page = 'none';
            }

            this.navService.selected.set(page);

          }
        });
      });
    }

    ngOnInit() {
    }

    selectPage(page: string) {
        this.navService.selected.set(page);
    }

}
