import { environment } from '../../environments/environment';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { Currency } from '../_models/currency';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

    domain = environment.domain;
    prefix = environment.api_prefix;

    constructor(private http: HttpClient) { }

    getCurrencies(): Observable<Currency[]> {
        let url = this.domain + this.prefix + '/currency/';
        return this.http.get<Currency[]>(url).pipe(
            map(objs => objs
                .map(obj => new Currency().deserialize(obj))
            )
        );
    }
    
    getCurrency(id: number): Observable<Currency> {
        let url = this.domain + this.prefix + '/currency/' + id + '/';
        return this.http.get<Currency>(url).pipe(
            map(obj => new Currency().deserialize(obj))
        );
    }
}
