<div class="p-2" [ngClass]="notification.getStatus() == 'u' ? 'bg-blue-50' : 'bg-white'">

    <!-- campaign ad -->
    <div *ngIf="isNotificationAd()" class="text-sm flex gap-3">
        <!-- company icon -->
        <div class="h-8 w-8 flex-none mt-1">
            <div class="h-8 w-8">
                <img *ngIf="notification.getObjectIcon()" class="flex-none w-8 h-8 object-cover rounded-full" src="{{ notification.getObjectIcon() }}" alt="">
                <!--
                <span *ngIf="!(notification.getObjectIcon() && notification.getObjectIcon().getImg())" class="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100">
                    <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                </span>
                -->
            </div>
        </div>

        <!-- main campaign -->
        <div>
            <!--
            <div class="text-xs mb-1">
                Sponsored
            </div>
            -->
            <div>
                <div class="flex flex-wrap gap-2 items-center">
                    <a class="font-semibold" [href]="getLinkUrl()" target="_blank">
                      {{ notification.getObjectName() }}
                    </a>

                    <!-- sponsored -->
                    <span class="text-lg text-gray-500" title="This notification is sponsored">
                      <i class="bi bi-badge-ad"></i>
                    </span>

                    <!-- time -->
                    <span class="flex-none text-sm text-gray-500">{{ notification.getCreated() | timeAgo }}</span>
                </div>

                <div class="my-1 text-sm text-gray-800">
                    <a [href]="getLinkUrl()" target="_blank">
                        {{ notification.getObjectDescription() }}
                    </a>
                </div>

            </div>
            <!-- tracking pixel -->
            <img *ngIf="loggedInAccountUser" src="{{ domain }}/pix/?c={{ notification.getObjectId() }}&u={{ loggedInAccountUser.id }}&t=i" width="1" height="1" alt="" style="width:1px;height:1px;border:none;text-decoration:none;color:#ffffff;">
        </div>

    </div>

    <!-- other -->
    <div *ngIf="!isNotificationAd()" class="text-sm flex gap-3">

        <!-- avatar -->
        <div class="h-8 w-8 flex-none">
            <!-- created by image -->
            <div class="h-8 w-8" *ngIf="notification.getAvatar() == 'au_from_image'">
                <img *ngIf="notification && notification.getCreatedByImage() && notification.getCreatedByImage().getImg()" class="flex-none w-8 h-8 object-cover rounded-full" src="{{ notification.getCreatedByImage().getImg() }}" alt="">
                <span *ngIf="!(notification.getCreatedByImage() && notification.getCreatedByImage().getImg())" class="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100">
                    <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                </span>
            </div>

            <!-- icon -->
            <div *ngIf="notification.getAvatar() == 'icon'">
                <span *ngIf="notification.getObjectIcon()">
                    <span [innerHTML]="notification.getObjectIcon() | safeHtml"></span>
                </span>
            </div>

            <!-- hicareer logo -->
            <div *ngIf="notification.getAvatar() == 'hi_logo'">
                <img class="h-8 w-8" src="/assets/img/logo-hi.png" alt="">
            </div>

            <div class="h-8 w-8" *ngIf="!notification.getAvatar()">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-400">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                </svg>
            </div>

            <div class="h-8 w-8" *ngIf="notification.getAvatar() == 'none'"></div>
        </div>

        <!-- main -->
        <div>
            <div>

                <!-- object name and description -->
                <div>
                    <div *ngIf="notification.getObjectName()" class="flex flex-wrap gap-2">
                        <a class="font-semibold" [href]="getLinkUrl()">
                          {{ notification.getObjectName() }}
                        </a>
                        <!-- time -->
                        <span class="flex-none text-sm text-gray-500">{{ notification.getCreated() | timeAgo }}</span>
                    </div>

                    <div *ngIf="notification.getObjectDescription()" class="my-1 text-sm text-gray-800">
                        <a [href]="getLinkUrl()">
                            {{ notification.getObjectDescription() }}
                        </a>
                    </div>

                </div>

                <!-- created by name -->
                <div *ngIf="!notification.getObjectName() && notification.getCreatedByName()" class="flex flex-wrap gap-2">
                    <!--
                       (mouseover)="openProfilePopupDelayed()" (mouseleave)="closeProfilePopupDelayed()"
                    -->
                    <a class="font-semibold"
                       href="/hi/{{ notification.getCreatedBySlug() }}/">{{ notification.getCreatedByName() }}</a>

                    <!--
                    <ng-container *ngIf="openProfilePopup"
                       (mouseleave)="closeIfCanCloseProfilePopup()"
                       (mouseover)="openProfilePopup=true; mouseOverProfilePopup=true">
                        <app-profile-popup *ngIf="openProfilePopup" [setSlug]="notification.getCreatedBySlug()" (canClose)="canClosePopup=$event"></app-profile-popup>
                    </ng-container>
                    -->

                    <!-- time -->
                    <span class="flex-none text-sm text-gray-500">{{ notification.getCreated() | timeAgo }}</span>
                </div>

                <!-- text -->
                <span *ngIf="notification.getText()" class="text-sm text-gray-800">
                    <a *ngIf="notification.getObjectSlug()" [href]="getLinkUrl()">
                        <span>{{ notification.getText() }}</span>
                    </a>
                    <span *ngIf="!notification.getObjectSlug()">{{ notification.getText() }}</span>
                </span>

            </div>

            <!-- related object -->
            <a *ngIf="notification.getObjectSlug() && notification.getObjectPreview()" [href]="getLinkUrl()">

                <!-- preview text -->
                <div class="my-3 text-sm text-gray-500">
                    {{ notification.getObjectPreview() }}
                </div>

            </a>
            <span *ngIf="!notification.getObjectSlug() && notification.getObjectPreview()">

                <!-- preview text -->
                <div class="my-3 text-sm text-gray-500">
                    {{ notification.getObjectPreview() }}
                </div>

            </span>


            <!-- actions -->
            <div>
                <!--
                is hub invite: {{ notification.isHubInvite() }}
                aa {{ notification.getHubInviteStatus() }}
                -->
                <!-- connection actions -->
                <div *ngIf="notification.isConnection()">
                    <!-- connection status pending -->
                    <div class="my-3 flex gap-3" *ngIf="notification.getConnectionStatus() == 'pending'">
                        <button class="btn-blue-sm" (click)="acceptConnection()">Accept</button>
                        <button class="text-sm text-gray-500" (click)="ignoreConnection()">Ignore</button>
                    </div>

                    <!-- connection status accepted -->
                    <div class="my-3 text-sm text-green-600" *ngIf="notification.getConnectionStatus() == 'accepted'">
                        <i class="bi bi-check-circle"></i> Accepted
                    </div>

                    <!-- connection status accepted -->
                    <div class="my-3 text-sm text-gray-500" *ngIf="notification.getConnectionStatus() == 'ignored'">
                        <i class="bi bi-x-circle"></i> Ignored
                    </div>
                </div>

                <!-- hub invite actions -->
                <div *ngIf="notification.isHubInvite()">

                    <!-- hub invite -->
                    <div>
                        <!-- invite status pending -->
                        <div class="my-3 flex gap-3" *ngIf="notification.getHubInviteStatus() == 'pending'">
                            <button class="btn-blue-sm" (click)="acceptHubInvite()">Accept</button>
                            <button class="text-sm text-gray-500" (click)="ignoreHubInvite()">Ignore</button>
                        </div>

                        <!-- status accepted -->
                        <div class="my-3 text-sm text-green-600" *ngIf="notification.getHubInviteStatus() == 'accepted'">
                            <i class="bi bi-check-circle"></i> Accepted
                        </div>

                        <!-- status ignored -->
                        <div class="my-3 text-sm text-gray-500" *ngIf="notification.getHubInviteStatus() == 'ignored'">
                            <i class="bi bi-x-circle"></i> Ignored
                        </div>
                    </div>

                </div>
            </div>

        </div>

    </div>

    <div class="text-xs text-gray-200" *ngIf="loggedInAccountUser && loggedInAccountUser.isAdmin()" title="">
        {{ notification.getId() }}
    </div>

</div>


