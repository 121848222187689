<div class="alert alert-info" *ngIf="_accountUser && loggedInAccountUser && _accountUser.getId() != loggedInAccountUser.getId()">
    Viewing <b>{{ _accountUser.getFullName() }}</b> conversations
</div>


<!-- conversations -->
<ng-template #templateConversations>
        <div class="flex flex-col flex-row gap-3"> <!--  -->
            <div class="sticky top-0 bg-white flex gap-4 items-center justify-between border-b border-gray-500">
                <div class="flex gap-4 items-center pt-5 pb-3">
                    <div class="flex gap-2 truncate">
                        <div class="font-medium">
                            {{ 'Inbox' | translate }}
                        </div>
                        <div>
                            <i (click)="showMenu = !showMenu" class="bi bi-chevron-down cursor-pointer"></i>
                            <!-- dropdown menu -->
                            <div #conversationMenu *ngIf="showMenu" class="absolute z-10 w-48 p-3 bg-white border border-gray-200 rounded-lg text-sm shadow-lg">
                                <ul>
                                    <li class="cursor-pointer px-2 py-2" (click)="getConversations(false, true); showMenu = false">
                                        {{ 'Reload' | translate }}
                                    </li>
                                    <li class="cursor-pointer px-2 py-2" (click)="toggleSponsoredConversations(); showMenu = false">
                                        {{ 'Show' | translate }} {{ showSponsored ? ('all' | translate) : ('sponsored' | translate) }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div role="status" *ngIf="loadingConversations">
                        <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div class="actions">
                    <!--
                    <button *ngIf="!loadingConversations" class="btn-start" (click)="getConversations(false, true)"><i class="bi bi-arrow-clockwise"></i></button>
                    <button
                        class="btn-start"
                        *ngIf="chatWithMembers && chatWithMembers.length > 0 && _accountUser && loggedInAccountUser && _accountUser.getId() != loggedInAccountUser.getId()"
                        (click)="newConversation(chatWithMembers)"><i class="bi bi-plus-lg"></i></button>
                    -->
                    <span *ngIf="showSponsored" class="text-xs text-gray-600 mr-3">{{ 'Sponsored' | translate }}</span>
                </div>

            </div>

            <div class="overflow-y-auto overflow-x-hidden" (scroll)="onScrollConversationContainer($event)" #conversationContainer>

                <div class="flex space-between cursor-pointer p-3"
                     *ngFor="let conv of conversations"
                     [ngClass]="{ 'font-semibold bg-gray-100 border-l border-gray-500': conv && conv.getUnreadMessagesCount() > 0, 'bg-blue-50': selectedConversation && conv && conv.id == selectedConversation.id }"
                     (click)="selectConversation(conv, true, loggedInAccountUser)">

                    <div *ngIf="conv">

                        <span class="flex gap-3 justify-between text-base">
                            <span class="truncate">
                                {{ conv.getTitle(loggedInAccountUser) }}
                            </span>
                            <span class="rounded-full p-0.5 bg-blue-400 text-white w-5 h-5 flex justify-center items-center" style="font-size: 10px" *ngIf="conv.getUnreadMessagesCount() > 0">
                                <span>
                                    {{ conv.getUnreadMessagesCount() }}
                                </span>
                            </span>
                        </span>

                        <!--
                            <div class="badge bg-primary" *ngIf="conv.getUnreadMessagesCount() > 0">
                            {{ conv.getUnreadMessagesCount() }}
                            </div>
                        -->

                        <!--
                        <div class="spinner-border spinner-border-sm ms-auto" style="margin-right: 5px" role="status" *ngIf="conv.loading">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        -->

                        <div>
                            <div class="text-xs text-gray-600">
                                {{ conv.getUpdated() | date:'h:mm a' }}
                            </div>
                            <!--
                                <div class="unread-count" *ngIf="conv.getUnreadMessagesCount() > 0">
                                <i class="bi bi-chat"></i> {{ conv.getUnreadMessagesCount() }}
                                </div>
                            -->
                        </div>

                    </div>

                </div>

                <div class="conversation" (click)="newConversation([_accountUser.getId()])" *ngIf="_accountUser && loggedInAccountUser && _accountUser.getId() != loggedInAccountUser.getId() && conversations.length == 0">
                    <span class="title">
                        {{ 'messenger.new-conversation' | translate }}
                    </span>
                </div>

            </div>
        </div>
</ng-template>


<!-- messages -->
<ng-template #templateMessages>
        <div class="flex flex-col flex-row gap-3"> <!-- -->

          <!-- messages header -->
          <div class="sticky top-0 w-full bg-white z-10">
            <div class="flex items-center border-b border-gray-500 pt-2 pb-0">
              <button class="xs:block md:hidden text-lg py-1 px-2" (click)="showConversationsOnMobile()" (touchend)="showConversationsOnMobile()" *ngIf="conversations && conversations.length > 0"><i class="bi bi-arrow-left"></i></button>
              <div class="p-3 font-medium ml-2">
                <span *ngIf="selectedConversation && !selectedConversation.isGhost()">
                  <span *ngFor="let member of selectedConversation.getMembers()">
                    <a *ngIf="member.id != loggedInAccountUser?.getId()" [routerLink]="['/hi/' + member.slug]">
                      {{ member.full_name }}
                    </a>
                  </span>
                </span>
                <span *ngIf="selectedConversation && selectedConversation.isGhost()">
                  Messenger
                </span>
                <span *ngIf="!selectedConversation">
                  {{ 'messenger.new-conversation' | translate }}
                </span>
              </div>
              <div role="status" *ngIf="loadingMessages">
                <svg aria-hidden="true" class="w-6 h-6 ml-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>


        <!--
        <form class="messagesBody" #messageBody [formGroup]="form">
        <form class="h-svh" #messageBody [formGroup]="form">
        -->

            <!-- listing internal comments and emails/messages sent to the user -->


                    <!-- job apply -->
                    <!--
                    <div class="message" *ngIf="_job && messages.length > 0">
                        <div class="img w-10 h-10">
                        </div>
                        <div>
                            <div class="flex gap-2 items-center">

                                <span class="text-sm font-medium pr-2">
                                    HiCareer
                                </span>
                                <span class="text-xs text-gray-500">
                                </span>
                            </div>
                            <div class="content">
                                <a class="link" [routerLink]="['/' + _job.getPageSlug()]" target="_blank">{{ _job.getPageName() }}</a>
                                has shown interest in you regarding the job
                                <a class="link" [routerLink]="[_job.getUrlPath()]" target="_blank">{{ _job.getTitle() }}</a>

                                <br />
                                When you start the conversation, your name and profile picture will be shown.
                                <br />

                                <div style="margin-top: 10px; margin-bottom: 20px;" *ngIf="selectedConversation">
                                    <button type="button" *ngIf="selectedConversation.getJobApplicationId()" (click)="openProfileModal()" class="bt-primary-sm" style="margin-right: 5px;">
                                        <span *ngIf="_job.hasAccountUserApplied()">{{ 'Showed interest' | translate }} {{ _job.hasAccountUserApplied() }}</span>
                                        <span *ngIf="!_job.hasAccountUserApplied()">{{ 'Profile' | translate }}</span>
                                    </button>
                                    <button
                                        type="button"
                                        *ngIf="!_job.hasAccountUserApplied() && !selectedConversation.getJobApplicationId()"
                                        (click)="openShowInterestModal()"
                                        [disabled]="loggedInAccountUser?.getId() == selectedConversation.getCreatedById()"
                                        class="bt-primary-sm"
                                        style="margin-right: 5px;">{{ 'Show interest back' | translate }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    -->

                    <div #messageBox>

                        <div *ngFor="let date of objectKeys(groupedMessages); let i = index">
                          <div class="mb-4 font-normal text-sm text-gray-400" [ngClass]="{ 'border-t border-gray-200 pt-2': i > 0 }">
                            {{ date | date:'mediumDate' }}
                          </div>
                          <div class="mb-2 space-y-3 rounded-md p-1" *ngFor="let msg of groupedMessages[date]" [ngClass]="{ 'bg-amber-50': selectedMessage == msg, 'bg-white': !selectedMessage }">

                            <!--
                              <div *ngIf="msg.isSponsored()" class="text-xs text-gray-900">
                              {{ 'Sponsored' | translate }}
                              </div>
                            -->

                            <div class="flex">
                              <div class="flex-none img w-10 h-10 z-0">
                                <img *ngIf="msg.getAccountUserMainImage() && msg.getAccountUserMainImage().getImg()" class="h-8 w-8 rounded-lg" src="{{ msg.getAccountUserMainImage().getImg() }}" alt="">
                                <div *ngIf="!(msg.getAccountUserMainImage() && msg.getAccountUserMainImage().getImg())" class="w-8 h-8 rounded-lg bg-gray-200 flex items-center justify-center">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-white">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                  </svg>
                                </div>
                              </div>
                              <div class="relative w-full" (mouseenter)="showMessageActions(msg)" (mouseleave)="hoverMessage = null">
                                <div class="grow pl-2">

                                  <div class="flex gap-2 items-center">

                                    <div class="text-base font-normal flex gap-2 items-center">
                                      <a *ngIf="msg.getAccountUserSlug()" [routerLink]="['/hi/' + msg.getAccountUserSlug()]">
                                        {{ msg.getAccountUserFullName() }}
                                      </a>
                                      <span *ngIf="!msg.getAccountUserSlug()">
                                        {{ msg.getAccountUserFullName() }}
                                      </span>
                                      <svg
                                          *ngIf="msg.getAccountUserIsMember()"
                                          class="w-4 h-4 fill-white stroke-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                                      </svg>

                                    </div>

                                    <!--
                                      <a [routerLink]="['/pro/' + msg.getAccountUserSlug()]" class="name" *ngIf="msg.getAccountUserSlug()">
                                      {{ msg.getAccountUserFullName() }}
                                      </a>
                                    -->

                                    <span class="text-xs text-gray-500" title="{{ msg.getCreated() | date:'mediumDate' }}">
                                      {{ msg.getCreated() | date:'shortTime' }}
                                    </span>

                                    <!-- sponsored -->
                                    <span *ngIf="msg.isSponsored()" class="text-lg text-gray-500" title="{{ 'This message is sponsored' | translate }}">
                                      <i class="bi bi-badge-ad"></i>
                                    </span>
                                  </div>
                                  <pre class="p-0 m-0 text-base" [innerHtml]="msg.getText()"></pre>

                                </div>

                                <!-- message actions -->
                                <div class="absolute z-10 p-2 px-4 bg-gray-50 border border-gray-200 rounded-md top-0 right-0 md:right-5" [ngClass]="{ 'block': hoverMessage == msg, 'hidden': hoverMessage != msg }">
                                  <div class="flex gap-x-4 items-center">
                                    <button class="btn btn-sm" (click)="editMessage(msg)">
                                      <i class="bi bi-pencil"></i>
                                    </button>
                                    <button class="btn btn-sm" (click)="deleteMessage(msg)">
                                      <i class="bi bi-trash"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>

                    </div>

        </div>


        <!-- show scroll to bottom btn -->
        <!--
        <div *ngIf="messageContainerScrollPos < 50" class="sticky bottom-16 float-end w-9 h-9">
          <button (click)="scrollToBottom()" class="text-3xl text-blue-600 py-2 pl-2"><i class="bi bi-arrow-down-circle"></i></button>
        </div>
        -->

        <form class="sticky bottom-0 min-h-10 w-full md:w-auto" [formGroup]="form">

          <div class="pt-0.5 bg-white z-30" [ngClass]="{ 'pb-3': !isKeyboardVisible, 'pb-0': isKeyboardVisible }">

                <div class="flex gap-1 items-center border border-gray-400 rounded-full px-3" [ngClass]="{ 'rounded-3xl': formTextareaHeight > 50, 'rounded-full': formTextareaHeight <= 50 }">
                    <textarea
                        #chatTextarea
                        (keydown)="chatTextareaHeight()"
                        (focus)="scrollToBottomOnFocus()"
                        class="m-2 w-full focus:outline-none overflow-hidden"
                        style="resize: none;"
                        placeholder="{{ 'Write a message' | translate }}"
                        formControlName="text"
                        [ngClass]="{ 'is-invalid':  submitted && f.text.errors }"
                        rows="1"></textarea>

                    <div>
                      <!-- active btn -->
                      <div *ngIf="form.controls.text.value != '' && !submitted" (click)="save($event)" class="text-4xl text-blue-600">
                          <i class="bi bi-arrow-up-circle-fill"></i>
                      </div>
                      <!-- disabled btn -->
                      <div *ngIf="form.controls.text.value == '' || submitted" class="text-4xl text-gray-300">
                          <i class="bi bi-arrow-up-circle-fill"></i>
                      </div>
                    </div>
                </div>

            </div>

        </form>
</ng-template>


<!-- main -->
<div class="flex flex-col md:flex-row gap-3" [ngStyle]="{'height': 'calc(100vh - ' + getExcessHeightTotal() + 'px)'}"> <!--  -->
    <!-- conversations -->
    <div class="hidden md:block min-w-60 overflow-y-auto"
      [ngClass]="{'block': mobileShowConversations, 'hidden': !mobileShowConversations}"
      [ngStyle]="{'height': 'calc(100vh - ' + getExcessHeightTotal() + 'px)'}"
      *ngIf="_showConversations && (loadingConversations || conversations)">

      <ng-container *ngTemplateOutlet="templateConversations"></ng-container>
    </div>

    <!-- messages -->
    <div #messageContainer class="overflow-y-auto w-full mt-auto"
      [ngClass]="{'block': !mobileShowConversations, 'hidden': mobileShowConversations, 'scroll-smooth': loadingMessages, 'scroll-auto': !loadingMessages }"
      (scroll)="onScrollMessageContainer($event)"
      [ngStyle]="{'height': 'calc(100vh - ' + getExcessHeightTotal() + 'px)'}">

      <ng-container *ngTemplateOutlet="templateMessages"></ng-container>
    </div>
</div>

<!--
<div class="flex flex-col md:flex-row gap-3" [ngStyle]="{'height': 'calc(100vh - ' + heightReducedInPx + 'px)'}">

</div>
-->


  <!--
<app-modal #profileModal [size]="'xl'">
    <app-profile
        *ngIf="profileModal.isOpen() && selectedConversation && selectedConversation.getJobApplicationAccountUserId()"
        [openAccountUserId]="selectedConversation.getJobApplicationAccountUserId()"
        [preview]="true"
        [hideHeader]="true"></app-profile>
</app-modal>

<app-modal #showInterestModal [size]="'md'">
    <app-job-apply
        *ngIf="showInterestModal.isOpen() && _job && selectedConversation"
        [setJob]="_job"
        [conversation]="selectedConversation"
        [respondToRequest]="true"
        [accountUser]="loggedInAccountUser"
        (sent)="closeShowInterestModal()"></app-job-apply>
</app-modal>

<app-modal #applyThanksModal [size]="'md'">
    <h2>{{ 'Interest Successfully Sent!' | translate }} &nbsp; <i style="color: #FFD700" class="bi bi-stars-lg"></i></h2>

    <br />

    <div>
        {{ "Thank you for expressing your interest in joining the companies team. The company has been notified and will reach out to you if they are interested. Keep in mind that this is just a preliminary step to connect with the company, not a guarantee that they will respond." | translate }}
    </div>

    <h3>{{ "Your profile" | translate }}</h3>

    <div>
        {{ "Updating your profile is a great way to improve your chances of getting noticed. Having a detailed and accurate profile can help employers evaluate your qualifications and skills more accurately." | translate }}
    </div>

    <br />

    <button class="btn btn-primary" [routerLink]="['/me/profile/']">{{ "Update your profile now" | translate }}</button>

    <br />

</app-modal>
      -->
