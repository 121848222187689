import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SafePipe } from './safe.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { TimeAgoPipe } from './time-ago.pipe';
import { ShortNumberPipe } from './short-number.pipe';
import { TruncatePipe } from './truncate.pipe';


@NgModule({
  declarations: [
    SafePipe,
    SafeHtmlPipe,
    ShortNumberPipe,
    TimeAgoPipe,
    TruncatePipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SafePipe,
    SafeHtmlPipe,
    ShortNumberPipe,
    TimeAgoPipe,
    TruncatePipe,
  ]
})
export class PipeModule { }
