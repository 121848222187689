import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Inject, PLATFORM_ID, effect } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { AccountUser, AccountUserPublic } from '../../_models/user';

import { AccountService } from '../../_services/account.service';
import { AlertService } from '../../_services/alert.service';
import { NetworkService } from '../../_services/network.service';

@Component({
  selector: 'app-profile-popup',
  templateUrl: './profile-popup.component.html',
  styleUrls: ['./profile-popup.component.scss']
})
export class ProfilePopupComponent implements OnInit {

    @ViewChild('messenger') messenger;

    @Input() set setSlug(slug: string) {
        this.slug = slug;
    }

    @Output() acceptedConnection = new EventEmitter<number>();

    //@Output() canClose = new EventEmitter<boolean>();

    loggedInAccountUser: AccountUser | undefined | null;
    profileAccountUser: AccountUserPublic | null;

    slug: string;

    loading: boolean = false;

    openProfilePopup: boolean = false;
    openProfilePopupTimer: number = 0;
    mouseOverProfilePopup: boolean = false;
    canClosePopup: boolean = true;

    tipPremium: boolean = false;
    tipRecruiter: boolean = false;
    tipKarma: boolean = false;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private accountService: AccountService,
        private alertService: AlertService,
        private networkService: NetworkService,
    ) {
        effect(() => {
            this.loggedInAccountUser = this.accountService.loggedInAccountUserSig();
        });
    }

    ngOnInit(): void {
    }

    getProfile() {
        this.loading = true;

        this.profileAccountUser = null;

        this.accountService.getAccountUserPublicPopup(this.slug).subscribe(
            data => {
                this.profileAccountUser = data;
                this.loading = false;
            },
            error => {
                this.alertService.error("Could not get profile");
                this.loading = false;
            }
        );
    }

    calculateVhInPixels(vh: number) {
        if (isPlatformBrowser(this.platformId)) {
            const pixels = (vh * window.innerHeight) / 100;
            return pixels;
        } else {
            return 0;
        }
    }

    createConnection() {
        if (this.loggedInAccountUser && this.profileAccountUser && this.loggedInAccountUser.getId() == this.profileAccountUser.getId()) {
            this.alertService.error("You cannot connect to yourself.");
            return;
        }
        if (!this.profileAccountUser) {
            this.alertService.error("You cannot connect to this profile.");
            return;
        }
        this.networkService.createConnection(this.profileAccountUser.getId()).subscribe(
            data => {
                this.alertService.success("Connection request sent");
                if (this.profileAccountUser) {
                    this.profileAccountUser.can_connect = false;
                    this.profileAccountUser.connection_pending = true;
                }
            },
            error => {
                if (error.status == 409) {
                    this.alertService.info("You have already sent a connection request to this profile");
                } else {
                    this.alertService.error("You cannot connect to this profile");
                }
            }
        );
    }

    acceptConnection(): void {
        if (this.profileAccountUser) {
            this.networkService.acceptConnection(this.profileAccountUser.getConnectionId()).subscribe(
                data => {
                    if (this.profileAccountUser) {
                        this.profileAccountUser.connection_accepted = true;
                        this.acceptedConnection.emit(this.profileAccountUser.getConnectionId());
                    }
                    this.alertService.success("Connection request accepted");
                },
                error => {
                    console.log(error);
                }
            );
        }
    }

    openProfilePopupDelayed() {

        if (!this.profileAccountUser) {
            this.getProfile();
        }

        // if mouse leaves the button before setTimerout, cancel openProfilePopupTimer with 0
        this.openProfilePopupTimer = 1;

        setTimeout(() => {
            if (this.openProfilePopupTimer == 1) {
                this.openProfilePopup = true;
            }
        }, 500);
    }

    closeProfilePopupDelayed() {
        this.openProfilePopupTimer = 0;
        setTimeout(() => {
            if (this.openProfilePopupTimer == 0 && this.mouseOverProfilePopup == false) {
                this.openProfilePopup = false;
            }
        }, 500);
    }

    closeProfilePopup() {
        this.openProfilePopupTimer = 0;
        this.openProfilePopup = false;
    }

    closeIfCanCloseProfilePopup() {
        if (this.canClosePopup) {
            this.openProfilePopup = false;
            this.mouseOverProfilePopup = false;
        }
    }

    openMessenger() {
        this.messenger.open();
        //this.canClose.emit(false);
    }

    closeMessenger() {
        //this.canClose.emit(true);
    }

}
