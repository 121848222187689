import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

import { AccountUser } from '../../_models/user';
import { Company } from '../../_models/company';

import { AccountService } from '../../_services/account.service';
import { CompanyService } from '../../_services/company.service';

@Component({
  selector: 'app-company-autocomplete',
  templateUrl: './company-autocomplete.component.html',
  styleUrls: ['./company-autocomplete.component.scss']
})
export class CompanyAutocompleteComponent implements OnInit {

    @ViewChild('popup') popup;

    private _companyId: number | undefined;

    @Input() set companyId(id: number | undefined) {
        if (id) {
            this.form.setValue('');
            this._companyId = id;
            this.getCompany(id);

        } else {
            this._companyId = undefined;
            this.company = undefined;
            //this.select(this.company);
        }
    }
    @Input() disabled: boolean = false;
    @Input() fieldName: string;
    @Input() viewRole: string;
    @Input() viewStyle: string = 'rows'; // rows, cols

    @Output() selected = new EventEmitter<Company>();

    company: Company | undefined;

    results: any = [];
    filtered: AccountUser[];
    country: string = 'se';

    form = new UntypedFormControl(Validators.required);

    loading: boolean = false;


    constructor(
        private accountService: AccountService,
        private companyService: CompanyService,
    ) { }

    ngOnInit() {
        this.form.setValue('');
        if (this.disabled) {
            this.form.disable();
        }
    }

    @HostListener('document:mousedown', ['$event'])
    onGlobalClick(event): void {
        // close list on click outside
        if (this.popup && !this.popup.nativeElement.contains(event.target)) {
            this.filtered = [];

            // set the correct user on clicking outside
            if (this.company) {
                this.getSearchResult(this.company.getId());
                this.form.setValue(this.company.getName());
            } else {
                this.select(null);
            }
        }
    }

    /*getAccountUsers(): void {
        this.loading = true;
        this.form.disable();

        this.accountService.getAccountUsersSmall(this.viewRole).subscribe(
            aus => {
                this.accountUsers = aus;
                this.loading = false;
                this.form.enable();
            }
        );
    }*/

    /*getAccountUser(): void {
        this.accountService.getAccountUserSmall(this._accountUserId).subscribe(
            au => {
                this.accountUser = au;
                this.form.setValue(au.getFullName());
                this.selected.emit(au);
            }
        );
    }*/

    clearCompany() {
        if (this.form.value.length == 0) {
            this.company = undefined;
            this.form.setValue(undefined);
            this.selected.emit(undefined);
        }
    }

    getCompany(id: number) {
        this.companyService.getCompany(id).subscribe(
            company => {
                this.company = company;
                this.form.setValue(company.getName());
                this.selected.emit(company);
            }
        );

    }

    getSearchResults(): void {
        this.results = [];

        //setTimeout(() => {
            if (this.form.value && this.form.value.length > 0) {
                this.loading = true;
                this.companyService.getSearchResults(this.form.value, this.country).subscribe(
                    results => {
                        this.results = results['hits'];
                        this.filtered = this.results; // no filtering now
                        //this.filter();
                        //console.log(this.results);
                        this.loading = false;
                    },
                    error => {
                    }
                );
            }
        //}, 1000);
    }

    getSearchResult(companyId): void {
        this.loading = true;
        this.form.disable();

        this.companyService.getSearchResult(companyId, this.country).subscribe(
            company => {
                this.form.enable();
                this.company = company;
                this.form.setValue(company.getName());
                this.selected.emit(company);
                this.loading = false;
            },
            error => {
                this.form.enable();
                this.loading = false;
            }
        );
    }

    select(res: any) {
        if (res) {
            this.getSearchResult(res['companyId']);
        } else {
            this.form.setValue('');
        }
        this.filtered = [];
    }


    /*filter() {
        let text = this.form.value;
        this.filtered = [];

        if (text.length > 2) {
            this.filtered = this.results; //this.searchFromArray(this.results, text);
        }

        // clear result
        if (text.length == 0) {
            this.select(null);
        }
    }*/

    /*searchFromArray(arr, text) {
        let matches = [], i;
        for (i = 0; i < arr.length; i++) {
            let auFullName = arr[i].getFullName();
            let auEmail = arr[i].getEmail();
            if (auFullName.toLowerCase().includes(text.toLowerCase())) {
                matches.push(arr[i]);

            } else if(auEmail.toLowerCase().includes(text.toLowerCase())) {
                matches.push(arr[i]);
            }
        }
        return matches;
    };*/

}
