import { Component, Output, EventEmitter } from '@angular/core';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-for-business-popup',
  standalone: true,
  imports: [
      CommonModule,
      RouterModule,
      TranslateModule
  ],
  templateUrl: './for-business-popup.component.html',
  styleUrl: './for-business-popup.component.scss'
})
export class ForBusinessPopupComponent {

  @Output() closePopup = new EventEmitter<boolean>();

  constructor() { }

  close(): void {
    this.closePopup.emit(true);
  }

}
