<label class="form-label">{{ 'form.currency' | translate }}</label>
<select class="form-select" [(ngModel)]="currency" (change)="emitSelectedCurrency()" [ngClass]="{ 'is-invalid': !currency && required }" [disabled]="disabled">
    <option [ngValue]="undefined">{{ 'form.select-currency' | translate }}</option>
    <option *ngFor="let currency of currencies" [ngValue]="currency">
        {{ currency.code }}
    </option>
</select>
<div *ngIf="!currency && required" class="error-feedback">
    <div>{{ 'form.this-field-is-required' | translate }}</div>
</div>
