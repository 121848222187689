import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { CollectionService } from '../../_services/collection.service';

import { AccountUser } from '../../_models/user';
//import { DataPoint } from '../../_models/collection';

@Component({
  selector: 'app-home-menu',
  templateUrl: './home-menu.component.html',
  styleUrls: ['./home-menu.component.scss']
})
export class HomeMenuComponent implements OnInit {

    @ViewChild('notificationsModal') notificationsModal;
    @ViewChild('nList') nList;

    @Input() accountUser: AccountUser | undefined | null;

    //dataPoints: DataPoint[] = [];

    constructor(
        private collectionService: CollectionService,
    ) { }

    ngOnInit(): void {
        //this.getDataPoints();
    }

    isStaff() {
        if (this.accountUser && (this.accountUser.isAdmin() || this.accountUser.isManager() || this.accountUser.isSeller())) {
            return true;
        }
        return false;
    }


    // url encode string function
    encodeString(str: string) {
        return encodeURIComponent(str.toLowerCase());
    }

    openNotifications() {
        this.notificationsModal.open();
    }

    /*getDataPoints() {

        // gets the most popular data points
        this.collectionService.getDataPoints('experience_industries', undefined, 10, undefined, undefined, true).subscribe(
            dps => {
                this.dataPoints = dps;
                dps.sort((a, b) => (a.getValue() < b.getValue()) ? -1 : 1);
            },
            error => {
                console.log("Error fetching dps");
            }
        );
    }*/

}
