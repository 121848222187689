import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

import { ModalModule } from '../../modal/modal.module';

import { LoginComponent } from '../../auth/login/login.component';

import { SignupComponent } from './signup.component';

import { AppleSignonBtnComponent } from '../../auth/apple-signon-btn/apple-signon-btn.component';
import { GoogleSignonBtnComponent } from '../../auth/google-signon-btn/google-signon-btn.component';


@NgModule({
  declarations: [
    SignupComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ModalModule,
    TranslateModule.forChild({
        extend: true
    }),
    LoginComponent,
    AppleSignonBtnComponent,
    GoogleSignonBtnComponent,
  ],
  exports: [
    SignupComponent
  ]
})
export class SignupModule { }
