import { Injectable } from '@angular/core';
//import * as mixpanel from 'mixpanel-browser';
import { init, identify, track } from 'mixpanel-browser';

import { AccountUser } from '../_models/user';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {

    initAccountUser: AccountUser | undefined | null;

    constructor(
        private accountService: AccountService
    ) {
        //init('273c2f750bf257823b1df4c1fd2cb6ed');

        //this.getLoggedInAccountUser();
    }

    /**
     * Initialize mixpanel.
     *
     * @param {string} userToken
     * @memberof MixpanelService
     */
    /*identify(userToken: string | undefined): void {
        if (isPlatformBrowser(this.platformId)) {
            if (userToken)
                identify(userToken);
        }
    }*/

    /*getLoggedInAccountUser(): void {
        const au = this.accountService.loggedInAccountUserSig();
        if (au) {
            // identify user
            this.identify(au.getId()?.toString());
        } else {
            this.identify(undefined);
        }
        this.initAccountUser = au;
    }*/

    /**
     * Push new action to mixpanel.
     *
     * @param {string} id Name of the action to track.
     * @param {*} [action={}] Actions object with custom properties.
     * @memberof MixpanelService
     */
    track(id: string, action: any = {}): void {

        if (!this.initAccountUser) {
            //track(id, action); // Track the action

        } else {
            track(id, action); // Track the action
        }

    }
}
