import { Component, OnInit, ViewChild, inject, Inject, PLATFORM_ID, output, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthenticationService } from '../../_services/authentication.service';
import { AccountService } from '../../_services/account.service';
import { AlertService } from '../../_services/alert.service';
import { NetworkService } from '../../_services/network.service';

import { Capacitor } from '@capacitor/core';

import { TranslateModule } from '@ngx-translate/core';

import { initializeApp } from 'firebase/app';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';

import { userCredentials } from '../../_services/authentication.service';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-apple-signon-btn',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
  ],
  templateUrl: './apple-signon-btn.component.html',
  styleUrl: './apple-signon-btn.component.scss'
})
export class AppleSignonBtnComponent implements OnInit {

    type = input<string>('login');
    disabled = input<boolean>(false);

    navigateTo = input<string | undefined>();
    noNavigation = input<boolean>(false);

    hubId = input<number | undefined>();
    hubSlug = input<string | undefined>();

    loginSuccessful = output<boolean>();

    loading: boolean = false;

    clientIpAddress: string = '';

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private authenticationService: AuthenticationService,
        private accountService: AccountService,
        private alertService: AlertService,
        private networkService: NetworkService,
        private route: ActivatedRoute,
        private router: Router,
    ) {
        const firebaseConfig = {
          apiKey: "AIzaSyBZbNKWLTcrpj17fNJ7iR5RjR-sceoboMo",
          //authDomain: "hicareer-8ce86.firebaseapp.com",
          authDomain: "auth.hicareer.com",
          projectId: "hicareer-8ce86",
          storageBucket: "hicareer-8ce86.appspot.com",
          messagingSenderId: "259048599478",
          appId: "1:259048599478:web:4c573c2453bb6aade95b17",
          measurementId: "G-PJE8RN9PET"
        };

        initializeApp(firebaseConfig);
    }

    ngOnInit() {
        try {
            if (environment.production) {
                this.accountService.getClientIpAddress().subscribe(
                    ip => {
                        this.clientIpAddress = ip;
                    }
                );
            }
        } catch {
        }
    }


    async signInWithApple(): Promise<void> {
      const result = await FirebaseAuthentication.signInWithApple();

      //alert(JSON.stringify(result));

      const user = result.user;
      const credential = result.credential;
      const token = credential?.idToken;

      if (!user) {
        return;
      }

      const userCredentials: userCredentials = {
        email: user.email,
        password: null,
        firstName: user.displayName?.split(' ')[0],
        lastName: user.displayName?.split(' ')[1],
        picture: user.photoUrl,
        code: null,
        token: token,
        provider: 'apple',
        type: this.type()
      };

      //this.credentials.emit(userCredentials);
      this.signOn(userCredentials);
    };

    redirectOnSignOn(data: any = {}) {
        if (this.hubId() && this.hubSlug()) {
            this.joinHub();
            this.router.navigate(["/hub/", this.hubSlug()]);

        } else if (!this.noNavigation()) {
            // if the url is the same dont reload it as it will close the opened
            // job application modal thats opened with pendingJobApplication
            if (this.navigateTo() && this.navigateTo() != this.router.url) {
                this.router.navigate([this.navigateTo()]);

            // if navigateTo is empty
            } else if (!this.navigateTo()) {
                //if (this.isCompany) {
                    //this.router.navigate(["/me/dashboard/"], { queryParams: { p: 'company-new' } });
                    // stay on same page
                //} else {
                // go to users new profile in editing mode
                if (isPlatformBrowser(this.platformId)) {
                    localStorage.setItem('profileInEditingMode', 'y');
                }
                this.router.navigate(["/hi/", data['slug']]);
                //}
            }
        }
    }

    signOn(credentials: userCredentials) {

        this.loading = true;

        this.authenticationService.signOnViaProvider(credentials, this.type(), null, this.clientIpAddress).subscribe(
            data => {
                this.accountService.getAccountUser(data['au_id']).subscribe(
                    au => {
                        this.accountService.setLoggedInAccountUser(au);

                        // redirect
                        this.redirectOnSignOn(data);
                    }
                );

                // set logged in account user slug to local storage for easy access
                if (isPlatformBrowser(this.platformId)) {
                    localStorage.setItem('viewingProfileSlug', data['slug']);
                }

                // track login in Mixpanel
                //this.mixpanelService.track('logged in', { 'method': website });

                this.loginSuccessful.emit(true);
                this.loading = false;
            },
            error => {
                if (error.statusText == 'Forbidden') {
                    this.alertService.error("This account has been suspended and/or removed.");

                } else if (error.statusText == 'Not Acceptable') {
                    this.alertService.error("Could not sign in using Apple. Are you registered with Apple?");

                } else if (error.status == '404') {
                    this.alertService.warning("No user with that email, please sign up first");

                } else {
                    this.alertService.error("Could not sign in to Apple. Are you registered with Apple?");
                }

                this.loginSuccessful.emit(false);
                this.loading = false;
            }
        );

    }


    joinHub() {
        let hubId = this.hubId();
        if (hubId != undefined) {
            this.networkService.joinHub(hubId).subscribe(
                data => {
                    //this.alertService.info(data.message);
                },
                error => {
                }
            );
        }
    }

}
