import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormControl } from '@angular/forms';

import { Question, Option } from '../_models/questions';


@Component({
  selector: 'app-question-form',
  templateUrl: './question-form.component.html',
  styleUrls: ['./question-form.component.scss']
})
export class QuestionFormComponent implements OnInit {

    @Input() 
        set inputQuestions(qs: string) {
            if (qs) {
                this.questions = JSON.parse(qs);
            }
        }

    @Output() outputQuestions = new EventEmitter<string>();
    @Output() errors = new EventEmitter<boolean>();

    questions: Question[] = [];

    newQuestion = new UntypedFormControl('');
    
    newAnswer = new UntypedFormControl('');
    newAnswerPoints = new UntypedFormControl('');
    newAnswerOrder = new UntypedFormControl('');

    answerTypes = [
        {key: 'text', value: 'Write text', selected: true}, 
        {key: 'options', value: 'Select options'}, 
        {key: 'text+options', value: 'Write text and select options'}
    ];

    constructor() { }

    ngOnInit() {
        if (this.inputQuestions) {
            this.questions = JSON.parse(this.inputQuestions);
        }
    }

    checkForErrors() {
        let err = false;

        for (let q of this.questions) {

            if (q.question == '') {
                err = true;
            }

            if ((q.answerType == 'options' || q.answerType == 'text+options') && (q.options == undefined || (q.options && q.options.length == 0))) {
                err = true;
            }

            if (q.options) {
                for (let o of q.options) {
                    if (o.answer == '') {
                        err = true;
                    }
                }
            }

        }

        this.errors.emit(err);
    }

    addQuestion() {
        this.questions.push({ "question": '', 'answerType': 'text' });

        this.checkForErrors();
    }

    updateQuestion(q) {
        let index = this.questions.indexOf(q);

        this.questions[index]['question'] = q.question;
        this.questions[index]['answerType'] = q.answerType;
        this.questions[index]['answer'] = q.answer;
        this.questions[index]['selectMultiOptions'] = q.selectMultiOptions;
        this.questions[index]['required'] = q.required;

        this.checkForErrors();
    }

    deleteQuestion(q) {
        let index = this.questions.indexOf(q);
        
        // remove element
        if (index > -1) {
            this.questions.splice(index, 1);
        }

        this.checkForErrors();
    }

    elemMove(arr, fromIndex, toIndex) {
        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
    }

    // pos 1 for one down, -1 for one up etc
    moveQuestion(q, pos) {
        let index = this.questions.indexOf(q);
        this.elemMove(this.questions, index, index + pos);
    }

    // pos 1 for one down, -1 for one up etc
    moveOption(q, o, pos) {
        let indexQuestion = this.questions.indexOf(q);
        let indexOption = q.options.indexOf(o);

        this.elemMove(this.questions[indexQuestion].options, indexOption, indexOption + pos);
    }

    addOption(question) {
        let q = this.questions.find(q => q.question == question.question);
        if (!q) {
            return;
        }

        let index = this.questions.indexOf(q);

        if (q) {
            if (!this.questions[index].options) {
                this.questions[index]['options'] = [];
            }

            // add answer
            this.questions[index].options?.push( { 
                "answer": '', //this.newAnswer.value, 
                "points": undefined, //this.newAnswerPoints.value, 
                //"order": this.newAnswerOrder.value, 
                "selected": false, 
            } );

            this.newAnswer.setValue('');
            this.newAnswerPoints.setValue('');
            this.newAnswerOrder.setValue('');

        } else {
            console.log(question.question + " cant be found in questions var");
        }

        this.checkForErrors();

    }

    /*updateOption(question, option) {
        let indexQuestion = this.questions.indexOf(question);
        let indexOption = question.options.indexOf(option);

        if (this.questions) {
            this.questions[indexQuestion].options[indexOption]['answer'] = option.answer;
            this.questions[indexQuestion].options[indexOption]['points'] = option.points;
            //this.questions[indexQuestion].options[indexOption]['order'] = option.order;
            this.questions[indexQuestion].options[indexOption]['selected'] = false;
        }
        this.checkForErrors();
    }

    deleteOption(question, option) {
        let indexQuestion = this.questions.indexOf(question);
        let indexOption = question.options.indexOf(option);
        
        // remove element
        if (indexOption > -1) {
            this.questions[indexQuestion].options.splice(indexOption, 1);
        }

        this.checkForErrors();
    }*/

    // we need this to avoid setting the emit "on change" on each function
    // above because emit is messing with the form
    emitResult() {
        this.outputQuestions.emit(JSON.stringify(this.questions));
    }

}
