import { environment } from '../../environments/environment';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { Country } from '../_models/country';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

    domain = environment.domain;
    prefix = environment.api_prefix;

    constructor(private http: HttpClient) { }

    getCountries(): Observable<Country[]> {
        let url = this.domain + this.prefix + '/country/';
        return this.http.get<Country[]>(url).pipe(
            map(objs => objs
                .map(obj => new Country().deserialize(obj))
            )
        );
    }

    getCountry(id: number): Observable<Country> {
        let url = this.domain + this.prefix + '/country/' + id + '/';
        return this.http.get<Country>(url).pipe(
            map(obj => new Country().deserialize(obj))
        );
    }
}
