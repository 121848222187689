<!--
<div class="search-container" [ngClass]="{ 'selected-what': filterWhatSelected, 'selected-where': filterWhereSelected }">

    <div class="area" [ngClass]="{ 'selected': filterWhatSelected }">
        <label>{{ 'word.What' | translate }}</label>
        <input 
         [(ngModel)]="filterWhat" 
         (keyup)="filterTitleSuggestions(); selectWhatFilter()"
         (keyup.enter)="doJobSearch()"
         (click)="selectWhatFilter()"
         (change)="selectWhatFilter()"
         (focusout)="focusOutWhat()"
         type="text" 
         class="search-field" 
         placeholder="{{ 'Search job' | translate }}"
         autofocus />

        <div class="dropdown" *ngIf="filteredTitleSuggestions.length > 0">
            <div class="items">
                <div class="item" *ngFor="let sug of filteredTitleSuggestions">
                    <i class="bi bi-search"></i>
                    <div (click)="selectWhatSuggestion(sug.title)">
                        {{ sug.title }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="area" [ngClass]="{ 'selected': filterWhereSelected }">
        <label>{{ 'word.Where' | translate }}</label>
        <input 
         [(ngModel)]="filterWhere" 
         (click)="selectWhereFilter()"
         (keyup)="filter(); getWhereSuggestions(); selectWhereFilter()"
         (keyup.enter)="doJobSearch()"
         (change)="selectWhereFilter()"
         (focusout)="focusOutWhere()"
         type="text" 
         class="search-field" 
         placeholder="{{ 'search.City' | translate }}" />

        <div class="dropdown" *ngIf="sugCities.length > 0">
            <div class="items">
                <div class="item" *ngFor="let sug of sugCities">
                    <i class="bi bi-geo-alt"></i>
                    <div (click)="selectWhereSuggestion(sug)">
                        {{ sug }}
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="area" style="padding: 0px;">
        <i class="bi bi-search" style="cursor: pointer; padding-top: 10px; padding-bottom: 10px;" (click)="getJobs();"></i>
    </div>
</div>
-->

<div class="search-container-sm" [ngClass]="{ 'selected-what': filterWhatSelected, 'selected-where': filterWhereSelected }">

    <div class="area" [ngClass]="{ 'selected': filterWhatSelected }">
        <div class="with-btn">
            <div>
                <input 
                 [(ngModel)]="filterWhat" 
                 (keyup)="filterTitleSuggestions(); selectWhatFilter()"
                 (keyup.enter)="doJobSearch()"
                 (click)="selectWhatFilter()"
                 (change)="selectWhatFilter()"
                 (focusout)="focusOutWhat()"
                 (focusin)="focusIn()"
                 type="text" 
                 class="search-field border-transparent focus:border-transparent focus:ring-0" 
                 placeholder="{{ 'Search' | translate }}" />
            </div>
            <div>
                <i class="bi bi-x-circle" *ngIf="filterWhat && filterWhat.length > 0" style="cursor: pointer;" (click)="clearSearch();"></i>
            </div>
        </div>

        <!--
        <div class="dropdown" *ngIf="showSearchOptions">
            <div class="items">
                <div class="item">
                    hey hey
                </div>
            </div>
        </div>
        -->

        <div class="dropdown" *ngIf="filteredTitleSuggestions.length > 0">
            <div class="items">
                <div class="item" *ngFor="let sug of filteredTitleSuggestions">
                    <i class="bi bi-search"></i>
                    <div (click)="selectWhatSuggestion(sug)">
                        {{ sug }}
                    </div>
                </div>
            </div>
        </div>
        
    </div>

    <!--
    <div class="area" [ngClass]="{ 'selected': filterWhereSelected }">
        <label>{{ 'word.Where' | translate }}</label>
        <input 
         [(ngModel)]="filterWhere" 
         (click)="selectWhereFilter()"
         (keyup)="filter(); getWhereSuggestions(); selectWhereFilter()"
         (keyup.enter)="doJobSearch()"
         (change)="selectWhereFilter()"
         (focusout)="focusOutWhere()"
         type="text" 
         class="search-field" 
         placeholder="{{ 'search.City' | translate }}" />

        <div class="dropdown" *ngIf="sugCities.length > 0">
            <div class="items">
                <div class="item" *ngFor="let sug of sugCities">
                    <i class="bi bi-geo-alt"></i>
                    <div (click)="selectWhereSuggestion(sug)">
                        {{ sug }}
                    </div>
                </div>
            </div>
        </div>

    </div>
    -->

    <!--
        <div class="area" style="padding: 0px;">
        <i class="bi bi-coin"></i>
        </div>
    -->

    <div class="area" style="padding: 0px;">
        <i class="bi bi-search" style="cursor: pointer" (click)="getJobs();"></i>
    </div>
</div>

<div class="options">

</div>
