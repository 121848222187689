import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

    private jobSearchSubject = new BehaviorSubject<any>(null);
    jobSearch = this.jobSearchSubject.asObservable();

    private searchFieldSubject = new BehaviorSubject<string>('');
    searchField = this.searchFieldSubject.asObservable();

    constructor() { }

    setJobSearch(s: string) {
        this.jobSearchSubject.next(s);
    }

    setSearchField(data: string) {
        this.searchFieldSubject.next(data);
    }
    
}
