import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { Company, Page } from '../../_models/company';

import { AccountService } from '../../_services/account.service';
import { AlertService } from '../../_services/alert.service';
import { GoogleAnalyticsService } from '../../_services/google-analytics.service';

import { CompanyService } from '../../_services/company.service';
import { MixpanelService } from '../../_services/mixpanel.service';

@Component({
  selector: 'app-company-create-form',
  templateUrl: './company-create-form.component.html',
  styleUrls: ['./company-create-form.component.scss']
})
export class CompanyCreateFormComponent implements OnInit {

    @Input() set company(c: Company) {
        if (c)
            this.name = c.getName();
    }

    @Input() name: string;
    @Input() pageId: number;

    @Input() partner: Company;
    @Input() displayStyle: string = 'normal'; // mini

    @Input() isClaim: boolean = false;
    @Input() navigateToCompany: boolean = true;

    @Output() created = new EventEmitter<boolean>();
    @Output() createdCompany = new EventEmitter<Company>();

    form: UntypedFormGroup;

    selectedCompany: Company;

    submitted = false;
    loading = false;
    checkingCompanyPageName = false;

    constructor(
        private accountService: AccountService,
        private alertService: AlertService,
        private companyService: CompanyService,
        private formBuilder: UntypedFormBuilder,
        private gaService: GoogleAnalyticsService,
        private router: Router,
        private mixpanelService: MixpanelService,
    ) { }

    ngOnInit(): void {
        this.mixpanelService.track('opened add company page');
    }

    selectCompany(company: Company) {
        this.selectedCompany = company;
    }

    createInitialUser() {
        this.submitted = true;

       if (!this.selectedCompany) {
           return;
       }

        this.loading = true;

        if (this.partner) {
            // try to create the first partner user for the company
            this.companyService.createCompanyPartner(this.selectedCompany.getId(), this.partner.getId()).subscribe(
                data => {
                    //let page = new Page().deserialize(data);

                    // navigate to the company career page
                    //this.router.navigateByUrl('/' + page.getSlug() + '/overview?p=career-page');

                    this.created.emit(true);

                    this.alertService.success("Company added!");
                    this.submitted = false;
                    this.loading = false;

                    // reload the logged in user
                    //this.accountService.getAccountUserObject();
                    this.accountService.reloadLoggedInAccountUser();
                },
                error => {
                    this.submitted = false;
                    this.loading = false;
                    this.alertService.error("This company has already been added");
                });

        } else {
            // try to create the first admin user for the company
            this.companyService.createCompanyAdmin(this.selectedCompany.getId()).subscribe(
                data => {
                    let page = new Page().deserialize(data);

                    // navigate to the company get started page
                    //this.router.navigateByUrl('/' + page.getSlug() + '/overview');
                    if (this.navigateToCompany)
                        this.router.navigate(["/company/" + page.getCompanyId()]);

                    this.created.emit(true);

                    // trigger Google Adwords conversion
                    this.gaService.eventConversionEmitter();

                    // set as master company, if partner is not already provided, making the
                    // newly created company a master company (just like selecting it from header component does)
                    /*if (!this.partner) {
                        localStorage.setItem('masterId', this.selectedCompany.getId().toString());
                        localStorage.setItem('masterName', this.selectedCompany.getName());
                        localStorage.setItem('masterSlug', this.selectedCompany.getPageSlug());
                    }*/

                    this.alertService.success("Welcome!");
                    this.submitted = false;
                    this.loading = false;

                    // reload the logged in user
                    //this.accountService.getAccountUserObject();
                    this.accountService.reloadLoggedInAccountUser();
                },
                error => {
                    this.submitted = false;
                    this.loading = false;
                    this.alertService.error("This company has already been added");
                });
        }

    }

    createCompany() {

        if (this.name && this.name.length > 0) {

            this.loading = true;

            this.companyService.getOrCreateCompany(this.name).subscribe(
                company => {
                    this.createdCompany.emit(company);
                    this.loading = false;

                    // reload the logged in user to get its new company
                    this.accountService.reloadLoggedInAccountUser();

                    // navigate to the company get started page
                    //this.router.navigateByUrl('/' + page.getSlug() + '/overview');
                    if (this.navigateToCompany)
                        this.router.navigate(["/company/" + company.getId() ]);

                    this.created.emit(true);

                    // trigger Google Adwords conversion
                    this.gaService.eventConversionEmitter();


                    this.mixpanelService.track('added company', { 'company name': this.name });
                    
                    //this.selectedCompany = company;
                    //this.createInitialUser();
                },
                error => {
                    this.alertService.error("This company has already been added");
                    this.loading = false;
                    this.mixpanelService.track('company already added', { 'company name': this.name });
                }
            );

        } else {
            this.alertService.error("Please add your company name");
        }

    }

    claimCompany() {
        this.loading = true;

        if (this.pageId) {
            this.companyService.claimCompany(this.pageId).subscribe(
                page => {
                    this.alertService.success("Company claimed!");
                    this.submitted = false;
                    this.loading = false;

                    // reload the logged in user to get its new company
                    this.accountService.reloadLoggedInAccountUser();

                    this.created.emit(true);

                    // navigate to the company get started page
                    if (this.navigateToCompany)
                        this.router.navigate(["/company/" + page.company ]);
                },
                error => {
                    this.submitted = false;
                    this.loading = false;
                    this.alertService.error("This company has already been claimed");
                }
            );
        } else {
            this.alertService.error("Please select a company");
        }

    }

}
