import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input, Output, EventEmitter, Inject, PLATFORM_ID, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../../environments/environment';

import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { ModalModule } from '../../modal/modal.module';

//import { SocialAuthService } from "@abacritt/angularx-social-login";
//import { FacebookLoginProvider, GoogleLoginProvider } from "@abacritt/angularx-social-login";

//import { SocialUser } from "@abacritt/angularx-social-login";
//import { MixpanelService } from '../_services/mixpanel.service';

import { AccountService } from '../../_services/account.service';
import { AlertService } from '../../_services/alert.service';
import { AuthenticationService } from '../../_services/authentication.service';
import { GoogleAnalyticsService } from '../../_services/google-analytics.service'
import { UserService } from '../../_services/user.service';

import { userCredentials } from '../../_services/authentication.service';
import { AppleSignonBtnComponent } from '../../auth/apple-signon-btn/apple-signon-btn.component';
import { GoogleSignonBtnComponent } from '../../auth/google-signon-btn/google-signon-btn.component';

//import { User } from '../_models/user'

declare var google: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        ModalModule,
        TranslateModule,
        AppleSignonBtnComponent,
        GoogleSignonBtnComponent,
    ],
})
export class LoginComponent implements OnInit, AfterViewInit {

    /*_googleButton: ElementRef;

    @ViewChild('googleButton') set googleButton(gb: ElementRef) {
        // if reloaded and the button is re-generated, init again
        if (!this._googleButton) {
            this._googleButton = gb;
            //this.renderGoogleButton();
        }
    }*/

    @ViewChild('forgotModal') forgotModal;

    @Input() navigateTo: string | null;
    @Input() noNavigation: boolean = false; // if true, ignore navigateTo and default nav, stay on same page

    @Input() socialCode: string | null;
    @Input() socialWebsite: string | null;
    @Input() socialToken: string | null;

    // login or signup
    _requestType: string | null = 'login';
    @Input() set requestType(type: string | null){
        this._requestType = type;
    }

    socialUser: any; //SocialUser;

    @Input() pendingJobApplication: boolean = false;

    @Output() loginSuccessful = new EventEmitter<boolean>();

    loginForm: UntypedFormGroup;
    passwordRequestResetForm: UntypedFormGroup;
    returnUrl: string;

    loading: boolean = false;
    loadingSocial: boolean = false;
    submitted: boolean = false;
    loginWithEmail: boolean = true;
    showLegacyForgotPassword: boolean = false;

    account_id: number;
    private sub: any;
    key: string;
    hostname: string;
    page: any;

    clientIpAddress: string;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private ngZone: NgZone,
        private accountService: AccountService,
        private alertService: AlertService,
        private formBuilder: UntypedFormBuilder,
        private gaService: GoogleAnalyticsService,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        //private mixpanelService: MixpanelService,
        //private socialAuthService: SocialAuthService
    ) { }

    ngOnInit() {
        /*this.key = this.route.snapshot.queryParamMap.get("k")
          this.route.queryParamMap.subscribe(queryParams => {
          this.key = queryParams.get("k")
          });*/
        /*this.sub = this.route.params.subscribe(params => {
          this.account_id = +params['account_id']; // (+) converts string 'id' to a number
          });*/

        //this.renderButton();

        //this.facebookSdk();


        // init once after view init
        /*if (isPlatformBrowser(this.platformId)) {
            //window.addEventListener('load', () => {
            try {
                google.accounts.id.initialize({
                    client_id: "866218590921-96msrp70fj8v28q0nn2o2ebc53quojl7.apps.googleusercontent.com", // old
                    //client_id: "259048599478-bo6mnd8csk225v08gqj47lop2g8dajbu.apps.googleusercontent.com", // new
                    callback: this.signInWithGoogle.bind(this),
                    auto_select: false,
                    auto_prompt: true,
                    cancel_on_tap_outside: true,
                });
            } catch {

            }

                //this.renderGoogleButton();
            //});
        }*/

        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });

        this.passwordRequestResetForm = this.formBuilder.group({
            email: ['', Validators.compose([
                Validators.required,
                Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])
            ],
        });

        // sometimes we want to redirect to a specific page on login
        if (this.navigateTo) {
            this.returnUrl = this.navigateTo;

        } else {
            // get return url from route parameters, or the current url or default to '/'
            this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || this.router.url || '/';
        }

        // login using incoming code or token
        // legacy method
        /*if (this.socialWebsite && (this.socialCode || this.socialToken)) {
            this.loginSocial(this.socialCode, this.socialWebsite, this.socialToken, null, null);
        }*/

        try {
            if (environment.production) {
                this.accountService.getClientIpAddress().subscribe(
                    ip => {
                        this.clientIpAddress = ip;
                    }
                );
            }
        } catch {
        }

    }

    ngAfterViewInit(): void {
        /*if (isPlatformBrowser(this.platformId)) {
            if (this._googleButton) {
                this.renderGoogleButton();
            }
        }*/
    }

    /*renderGoogleButton() {
        if (this._googleButton) {

            try {
                google.accounts.id.renderButton(
                    this._googleButton.nativeElement,
                    { theme: "outline", shape: "pill", logo_alignment: "left", text: "continue_with", size: "large", width: "275px" }
                );
            } catch {
                console.log('google button error');
            }

            //display the One Tap dialog
            //google.accounts.id.prompt();

        }
    }*/

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    loginSucceeded() {
        this.loginSuccessful.emit(true);
    }

    /*login() {
      this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
    return;
    }

    this.loading = true;
    this.authenticationService.login(this.f.email.value, this.f.password.value)
    .pipe(first())
    .subscribe(
    data => {
    // get and set the user to the service
    let user = new User().deserialize(data.user);
    this.accountService.setUser(data.user);

    this.loginSuccessful.emit(true);

    // set logged in account user slug to local storage for easy access
    localStorage.setItem('viewingProfileSlug', data.user.profile.slug);

    this.router.navigate([this.returnUrl]);
    this.loading = false;
    },
    error => {
    if (error == 'Forbidden') {
    this.alertService.error("This account has been suspended and/or removed.");

    } else {
    this.alertService.error("Wrong username or password, please try again.");
    }
    this.loading = false;
    });
    }*/

    setPendingJobApplication() {
        if (this.pendingJobApplication) {
            if (isPlatformBrowser(this.platformId)) {
                localStorage.setItem('pendingJobApplication', this.router.url);
            }
            //localStorage.setItem('pendingJobApplicationWhy', this.formApplication.controls.message.value);
        }
    }

    signInWithDoubleCloud(): void {
        //this.socialWebsite = 'doublecloud';
        this.setPendingJobApplication();

        const userCredentials: userCredentials = {
            email: this.f.email.value,
            password: this.f.password.value,
            firstName: null,
            lastName: null,
            picture: null,
            code: null,
            token: null,
            provider: 'doublecloud',
            type: 'login'
        };

        //this.loginSocial(null, 'doublecloud', null, this.f.email.value, this.f.password.value);
        this.loginSocial(userCredentials);
    }

    signInWithGithub(): void {
        this.socialWebsite = 'github';
        this.setPendingJobApplication();

        // on success redirects back to redirect_uri with the code parameter
        if (isPlatformBrowser(this.platformId)) {
            window.location.href = "https://github.com/login/oauth/authorize?scope=user:email&client_id=ebcb2d38f8e56f93bbfb";
        };
    }

    // NOTE related to old google login button
    /*signInWithGoogle(response: any) {
        this.socialWebsite = 'google';
        this.setPendingJobApplication();

        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem('currentUser');
            localStorage.removeItem('viewingProfileSlug');
        }

        //console.log('signIn', response);

        this.loginSocial(null, 'google', response['credential'], null, null, null);
    }*/

    /*signInWithGoogleCredentials(credentials: userCredentials) {
        this.socialWebsite = 'google';
        this.setPendingJobApplication();

        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem('currentUser');
            localStorage.removeItem('viewingProfileSlug');
        }

        //this.loginSocial(null, 'google', credentials.token, credentials.email, credentials.password, null);
        this.loginSocial(credentials);
    }*/

    /*signInWithGoogle(): void {
      this.socialWebsite = 'google';
      this.setPendingJobApplication();

      localStorage.removeItem('currentUser');
      localStorage.removeItem('viewingProfileSlug');

      this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);

      this.socialAuthService.authState.subscribe(
      user => {
      this.loginSocial(null, 'google', user.authToken, null, null, user);
      this.socialUser = user;
      },
      error => {
      this.signOut();
      });
      }

      signInWithFacebook(): void {
      this.socialWebsite = 'facebook';
      this.setPendingJobApplication();

      localStorage.removeItem('currentUser');
      localStorage.removeItem('viewingProfileSlug');

      this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);

      this.socialAuthService.authState.subscribe(
      user => {
      this.loginSocial(null, 'facebook', user.authToken, null, null, user);
      this.socialUser = user;
      },
      error => {
      this.signOut();
      });
      }*/

    //openCapacitorSite = async (url: string) => {
    //    await Browser.open({ url: url });
    //};

    signInWithLinkedIn(): void {
        this.socialWebsite = 'linkedin';
        this.setPendingJobApplication();

        // on success redirects back to redirect_uri with the code parameter
        let redirectUri = encodeURIComponent(environment.domainFrontend + '/login/linkedin/login');
        const urlLinkedIn = "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=778j3kd346w9ml&redirect_uri="+redirectUri+"&state=dfkjg09385eof&scope=r_liteprofile%20r_emailaddress"; //%20w_member_social
        window.location.href = urlLinkedIn;
        /*if (isPlatformBrowser(this.platformId)) {
            //if (Capacitor.isNativePlatform()) {
            //    this.openCapacitorSite(urlLinkedIn);
            //} else {
            //    window.location.href = urlLinkedIn;
            //}
            window.open(urlLinkedIn, '_self', 'rel=opener');
            window.addEventListener('message', async event => { // wait for data from window.opener.postMessage

                console.log("gotEvent", JSON.parse(event.data));

                // code, website, token, type
            });
        };*/
    }

    /*signInWithTwitter(): void {
      this.socialWebsite = 'twitter';
    // on success redirects back to redirect_uri with the code parameter
    let redirectUri = encodeURIComponent(environment.domainFrontend + '/login/twitter/');
    console.log(redirectUri);
    window.location.href = "https://twitter.com/i/oauth2/authorize?response_type=code&client_id=N3hlajFZQnY4VVFJLVVBSzMwWWI6MTpjaQ&redirect_uri="+redirectUri+"&scope=tweet.read%20users.read%20offline.access&state=45vtfdg57gbd&code_challenge=12345&code_challenge_method=plain";
    }*/

    signOut(): void {
        //this.mixpanelService.track('logged out');

        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem('currentUser');
            localStorage.removeItem('viewingProfileSlug');
        }
        /*if (this.socialUser) {
          this.socialAuthService.signOut();
          }*/
        this.socialUser = undefined;

        this.authenticationService.logout();
        this.accountService.setLoggedInAccountUser(null);
    }

    // load facebook sdk
    /*facebookSdk() {
      if (isPlatformBrowser(this.platformId)) {
      (window as any).fbAsyncInit = function() {
      window['FB'].init({
appId      : '652449142424642',
cookie     : true,
xfbml      : true,
version    : 'v3.1'
});
window['FB'].AppEvents.logPageView();
};

(function(d, s, id){
var js, fjs = d.getElementsByTagName(s)[0];
if (d.getElementById(id)) {return;}
js = d.createElement(s); js.id = id;
js.src = "https://connect.facebook.net/en_US/sdk.js";
fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));
}
}*/

/*facebookLogin() {

  if (isPlatformBrowser(this.platformId)) {
  window['FB'].login((response) => {
//console.log('login response',response);
if (response.authResponse) {

window['FB'].api('/me', {
fields: 'last_name, first_name, email, picture'
}, (userInfo) => {

//console.log("user information");
//console.log(userInfo);

// do login
let token = response.authResponse.accessToken;
if (userInfo && token) {
this.loginSocial(null, 'facebook', token);
}

});

} else {
this.alertService.error("Login failed, please try again.");
}
}, {scope: 'email'});
}

}*/

    sendSignUpEvent(){
        this.gaService.eventEmitter("sign_up", "engagement", "method");
    }

    //loginSocial(code, website, token, username, password, providerResponse?: any) {
    loginSocial(credentials: userCredentials, providerResponse?: any) {
        if (isPlatformBrowser(this.platformId)) {
            if (credentials.provider != 'doublecloud') {
                this.loadingSocial = true;
            }
            this.ngZone.run(() => {
                this.loading = true;
                //this.authenticationService.getAccess(this._requestType ?? 'login', code, website, token, username, password, providerResponse, this.clientIpAddress).subscribe(
                this.authenticationService.signOnViaProvider(credentials, 'login', providerResponse, this.clientIpAddress).subscribe(
                    data => {
                        // get and set the user to the service
                        //let user = new User().deserialize(data);
                        //this.accountService.setUser(user);

                        /*this.accountService.getCurrentAccountUser(data['au_id']).subscribe(
                            au => {
                                console.log(au);
                                this.accountService.setLoggedInAccountUser(au);
                            }
                        );*/

                        this.accountService.getAccountUser(data['au_id']).subscribe(
                            au => {
                                this.accountService.setLoggedInAccountUser(au);

                                if (!this.noNavigation) {
                                  this.router.navigate([this.returnUrl]);
                                }
                            }
                        );

                        // set logged in account user slug to local storage for easy access
                        if (isPlatformBrowser(this.platformId)) {
                            localStorage.setItem('viewingProfileSlug', data['slug']);
                        }

                        this.loginSuccessful.emit(true);

                        // track signup in Google Analytics
                        // used for LinkedIn and Github
                        if (this._requestType == 'signup') {
                            this.sendSignUpEvent();
                        }

                        // track login in Mixpanel
                        //this.mixpanelService.track('logged in', { 'method': website });

                        this.loading = false;
                        this.loadingSocial = false;
                    },
                    error => {
                        if (error == 'Forbidden') {
                            this.alertService.error("This account has been suspended and/or removed.");

                        } else if (error == 'Not Acceptable') {
                            if (this.socialWebsite && this.socialWebsite != "doublecloud") {
                                this.alertService.error("Could not sign in using " + this.capitalizeFirstLetter(this.socialWebsite) + ". Are you registered with " + this.capitalizeFirstLetter(this.socialWebsite) + "?");
                            } else {
                                this.alertService.error("Could not sign in using the selected login provider. Are you selecting the correct login provider?");
                            }

                        } else if (error == 'Not found') {
                            if (this.socialUser) {
                                this.alertService.error("The user does not exist, please register first.");
                            } else {
                                this.alertService.error("The user does not exist, please register first.");
                            }

                        } else {
                            if (this.socialWebsite == "doublecloud") {
                                this.alertService.error("Could not login. Please check your username and password and try again.");

                            } else if (this.socialWebsite && this.socialWebsite != "doublecloud") {
                                this.alertService.error("Could not sign in to " + this.capitalizeFirstLetter(this.socialWebsite) + ". Are you registered with " + this.capitalizeFirstLetter(this.socialWebsite) + "?");
                            } else {
                                this.alertService.error("Something went wrong, please try again.");
                            }
                        }

                        // on timeout, try again
                        //setTimeout(() => {
                        //    this.renderGoogleButton();
                        //}, 300);

                        this.loading = false;
                        this.loadingSocial = false;
                    }
                );
            });

    }
  }

  capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
  }

  /*onSuccess(googleUser) {
    var profile = googleUser.getBasicProfile();
    console.log(googleUser);
    console.log(profile);
    console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
    console.log('Name: ' + profile.getName());
    console.log('Image URL: ' + profile.getImageUrl());
    console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.

    // do login
    let token = googleUser.vc.id_token;
    if (googleUser && token) {
    this.ngZone.run(() => {
    this.loginSocial(null, 'google', token);
    });
    }
    }

    onFailure(error) {
    console.log(error);
    }*/


  resetRequest() {

      this.accountService.requestPasswordReset(this.passwordRequestResetForm.controls.email.value).subscribe(
          success => {
              this.forgotModal.close();
              this.passwordRequestResetForm.controls.email.setValue('');
              this.alertService.success("Please check your email for password reset instructions.");

              //this.mixpanelService.track('requested password reset');

          },
          error => {
              this.alertService.error("The e-mail was not found, please check your spelling.");
          }
      );

  }

  showForgotPassword(legacy: boolean = false) {
      this.showLegacyForgotPassword = legacy;
      this.forgotModal.open();
  }


}
