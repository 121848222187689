<br />

<!--
<div class="alert alert-info" *ngIf="getQuestionsAnswerPoints() > 0">
    Score: <b>{{ questionsAnswerPercent | number }}%</b> - <b>{{ questionsAnswerPoints }} points</b> out of <b>{{ questionsMaxPoints }} max</b>
</div>
-->

<div *ngFor="let q of questions">
    
    <div>
        <pre>{{ q.question }}</pre>
        <!--<small *ngIf="getMaxPoints(q) > 0"> (max {{ getMaxPoints(q) }} points)</small>-->
    </div>

    <div *ngFor="let o of q.options">
        <span *ngIf="o.selected" style="color: navy;">{{ o.answer }} <i class="fas fa-arrow-left" style="margin-left: 8px"></i></span>
        <span *ngIf="!o.selected" style="color: #999">{{ o.answer }}</span>
    </div>

    <div *ngIf="q.answer" style="color: navy;">
        {{ q.answer }}
    </div>

    <hr />

</div>
