import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { Image } from '../../_models/media';

import { AccountUser } from '../../_models/user';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

    @ViewChild('addCompanyModal') addCompanyModal;

    @Input() accountUser: AccountUser;

    @Output() closeMenu = new EventEmitter<boolean>();

    p: string;
    sc: string; // selected company id

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private route: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.p = params['p'];
            this.sc = params['sc'];
        });
    }

    isStaff() {
        if(this.accountUser && (this.accountUser.isAdmin() || this.accountUser.isManager() || this.accountUser.isSeller())) {
            return true;
        }
        return false;
    }

    getImage(img: string) {
        if (img) {
            return new Image().deserialize(img);
        }
        return new Image();
    }

    close() {
        this.closeMenu.emit(true);
    }

    // the main company that the current user is using, this company
    // can have sub companies ("accounts") so the frontend needs to know
    // who is the master
    setMasterCompany(c: any) {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('masterId', c.id);
            localStorage.setItem('masterName', c.name);
            localStorage.setItem('masterSlug', c.page_slug);
        }
    }

    openAddCompanyModal() {
        this.addCompanyModal.open();
    }

    closeAddCompanyModal() {
        this.addCompanyModal.close();
    }


}
