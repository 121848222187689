import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { Image } from '../_models/media';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

    domain = environment.domain;
    prefix = environment.api_prefix;

    constructor(private http: HttpClient) { }

    createImage(data): Observable<any> {
        return this.http.post(this.domain + this.prefix + '/media-image/', data);
    }

    updateImage(data, id): Observable<any> {
        return this.http.put(this.domain + this.prefix + '/media-image/' + id + '/', data)
    }

    rotateImage(data, id): Observable<any> {
        return this.http.put(this.domain + this.prefix + '/media-image/' + id + '/rotate/', data)
    }

    deleteImage(id): Observable<any> {
        let url = this.domain + this.prefix + '/media-image/' + id + '/';
        return this.http.delete(url);
    }


}
