<div class="img relative group" [ngClass]="{
        'editing':  edit,
        'icon-dynamic': ref == 'icon-dynamic',
        'icon50': ref == 'icon50',
        'icon30': ref == 'icon30',
        'cover': ref == 'cover',
        'story': ref == 'story',
        'rounded-full': ref == 'circle',
        'search': ref == 'search'
    }">
    <div class="lds-ripple" *ngIf="loading"><div></div><div></div></div>

    <div *ngIf="!image && hint && edit && !justEditButton" [ngClass]="{ 'error': error }" class="hint">{{ hint }}</div>

    <!--<img *ngIf="image && !updated" src="{{ image.getImg() }}" />-->
    <img loading="lazy" *ngIf="image && cache && !justEditButton" src="{{ image.getImg() }}" [ngClass]="{ 'cover': ref == 'cover', 'circle': ref == 'circle' }" />
    <img loading="lazy" *ngIf="image && !cache && !justEditButton" src="{{ image.getImg() }}?{{ milliseconds }}" [ngClass]="{ 'cover': ref == 'cover', 'circle': ref == 'circle' }" /> <!-- avoid browser cache -->
    <input type="file" #upload *ngIf="edit" (change)="imageFileInput($event)" accept="image/png, image/jpeg, image/heic, image/heif" required>

    <div class="absolute bottom-1 inline-block text-left" [ngClass]="{'opacity-0 group-hover:opacity-100': showOnHover}" *ngIf="edit">
        <div>
            <button (click)="dropdownOptions=!dropdownOptions" type="button" class="w-8 h-8 rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100" id="menu-button" aria-expanded="true" aria-haspopup="true">
                <span class="sr-only">Options</span>
                <i class="bi bi-camera-fill"></i>
            </button>
        </div>

        <div *ngIf="dropdownOptions" class="absolute z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
            <div class="py-1" role="none">
                <a class="text-gray-700 block px-4 py-2 text-sm cursor-pointer" role="menuitem" tabindex="-1" id="menu-item-0" (click)="openUploadDialog()">Upload Photo</a>
                <a class="text-gray-700 block px-4 py-2 text-sm cursor-pointer" role="menuitem" tabindex="-1" id="menu-item-1" (click)="removePhoto()" *ngIf="image">Remove</a>
            </div>
        </div>
    </div>

    <!--<li><button class="dropdown-item" (click)="openModal()" >Select from library</button></li>-->
    <!--<li><button class="dropdown-item" (click)="rotatePhoto()" >Rotate Photo</button></li>-->
    <!--
    <div class="dropdown" *ngIf="edit">
        <button class="btn btn-sm btn-light" data-bs-toggle="dropdown" aria-expanded="false"><i class="bi bi-camera-fill"></i></button>
        <ul class="dropdown-menu">
            <li><button class="dropdown-item" (click)="openUploadDialog()" >Upload Photo</button></li>
            <li><button class="dropdown-item" (click)="removePhoto()" *ngIf="image" >Remove</button></li>
        </ul>
    </div>
    -->
</div>

<!--
<app-modal #modal>
    <div class="libContainer" *ngIf="_imgLib">
        <div class="libImg" *ngFor="let i of _imgLib">
            <div class="img-square">
                <img src="{{ i.getImg() }}" title="Select Photo" />

                <div class="dropdown">
                    <button class="btn btn-sm btn-light" data-bs-toggle="dropdown" aria-expanded="false"><i class="bi bi-pen-fill"></i></button>
                    <ul class="dropdown-menu">
                        <li><button class="dropdown-item" (click)="selectPhoto()" >Use this photo</button></li>
                        <li><button class="dropdown-item" (click)="deletePhoto()" >Delete</button></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <button class="btn btn-primary"><i class="fas fa-cloud-upload-alt"></i> <span > Upload Photo</span></button>
    <div *ngIf="!_imgLib" >
        No images to select
    </div>
</app-modal>
-->
