import { Component, OnInit, ViewChild, inject, Inject, PLATFORM_ID, output, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthenticationService } from '../../_services/authentication.service';
import { AccountService } from '../../_services/account.service';
import { AlertService } from '../../_services/alert.service';
import { NetworkService } from '../../_services/network.service';

import { Capacitor } from '@capacitor/core';

import { TranslateModule } from '@ngx-translate/core';

// web
//import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
//import { Auth } from '@angular/fire/auth';
import { initializeApp } from 'firebase/app';

// apps
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
//import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

import { userCredentials } from '../../_services/authentication.service';

import { environment } from '../../../environments/environment';

//declare var google: any;

/*declare global {
  interface Window {
    google: any;
  }
}*/

@Component({
  selector: 'app-google-signon-btn',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
  ],
  templateUrl: './google-signon-btn.component.html',
  styleUrl: './google-signon-btn.component.scss'
})
export class GoogleSignonBtnComponent implements OnInit {

    /*googleButton: ElementRef | undefined = undefined;

    @ViewChild('googleButton') set googleButtonDiv(gb: ElementRef) {
        if (gb && !this.googleButton) {

            this.googleButton = gb;

            if (isPlatformBrowser(this.platformId)) {
                window.google.accounts.id.renderButton(
                    this.googleButton?.nativeElement,
                    { theme: "outline", shape: "pill", logo_alignment: "left", text: "continue_with", size: "large", width: "275px" }
                );
            }

            //display the One Tap dialog
            //google.accounts.id.prompt();

        }
    }*/

    //firebaseAuth = inject(Auth);

    type = input<string>('login');
    disabled = input<boolean>(false);

    navigateTo = input<string | undefined>();
    noNavigation = input<boolean>(false);

    hubId = input<number | undefined>();
    hubSlug = input<string | undefined>();

    loginSuccessful = output<boolean>();
    //credentials = output<userCredentials>();

    loading: boolean = false;

    clientIpAddress: string = '';

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private authenticationService: AuthenticationService,
        private accountService: AccountService,
        private alertService: AlertService,
        private networkService: NetworkService,
        private route: ActivatedRoute,
        private router: Router,
    ) {
        //if (Capacitor.isNativePlatform()) {
            /*GoogleAuth.initialize({
                clientId: '259048599478-na39kp1tlut9h2dc0ekc03tj6misbmp9.apps.googleusercontent.com',
                scopes: ['profile', 'email'],
                grantOfflineAccess: true,
            });*/
        //}

        const firebaseConfig = {
          apiKey: "AIzaSyBZbNKWLTcrpj17fNJ7iR5RjR-sceoboMo",
          //authDomain: "hicareer-8ce86.firebaseapp.com",
          authDomain: "auth.hicareer.com",
          projectId: "hicareer-8ce86",
          storageBucket: "hicareer-8ce86.appspot.com",
          messagingSenderId: "259048599478",
          appId: "1:259048599478:web:4c573c2453bb6aade95b17",
          measurementId: "G-PJE8RN9PET"
        };

        initializeApp(firebaseConfig);
    }

    ngOnInit() {
        /*if (isPlatformBrowser(this.platformId)) {
            window.google.accounts.id.initialize({
              client_id: "259048599478-na39kp1tlut9h2dc0ekc03tj6misbmp9.apps.googleusercontent.com",
              auto_select: false,
              auto_prompt: true,
              cancel_on_tap_outside: true
            });
        }*/

        try {
            if (environment.production) {
                this.accountService.getClientIpAddress().subscribe(
                    ip => {
                        this.clientIpAddress = ip;
                    }
                );
            }
        } catch {
        }

    }


    async signInWithGoogle(): Promise<void> {
      const result = await FirebaseAuthentication.signInWithGoogle(); // { mode: 'redirect' }

      //alert(JSON.stringify(result));

      const user = result.user;
      const credential = result.credential;
      const token = credential?.idToken;

      if (!user) {
        //this.alertService.error("Error signing in with Google. Please try again." + JSON.stringify(result));
        return;
      }

      const userCredentials: userCredentials = {
        email: user.email,
        password: null,
        firstName: user.displayName?.split(' ')[0],
        lastName: user.displayName?.split(' ')[1],
        picture: user.photoUrl,
        code: null,
        token: token,
        provider: 'google',
        type: this.type()
      };

      //this.credentials.emit(userCredentials);
      this.signOn(userCredentials);
    };

    redirectOnSignOn(data: any = {}) {
        if (this.hubId() && this.hubSlug()) {
            this.joinHub();
            this.router.navigate(["/hub/", this.hubSlug()]);

        } else if (!this.noNavigation()) {
            // if the url is the same dont reload it as it will close the opened
            // job application modal thats opened with pendingJobApplication
            if (this.navigateTo() && this.navigateTo() != this.router.url) {
                this.router.navigate([this.navigateTo()]);

            // if navigateTo is empty
            } else if (!this.navigateTo()) {
                //if (this.isCompany) {
                    //this.router.navigate(["/me/dashboard/"], { queryParams: { p: 'company-new' } });
                    // stay on same page
                //} else {
                // go to users new profile in editing mode
                if (isPlatformBrowser(this.platformId)) {
                    localStorage.setItem('profileInEditingMode', 'y');
                }
                this.router.navigate(["/hi/", data['slug']]);
                //}
            }
        }
    }

    signOn(credentials: userCredentials) {

        this.loading = true;

        this.authenticationService.signOnViaProvider(credentials, this.type(), null, this.clientIpAddress).subscribe(
            data => {
                this.accountService.getAccountUser(data['au_id']).subscribe(
                    au => {
                        this.accountService.setLoggedInAccountUser(au);

                        // redirect
                        this.redirectOnSignOn(data);
                        /*if (!this.noNavigation) {
                            this.router.navigate([this.navigateTo]);
                        } else {
                            let url = this.route.snapshot.queryParams['returnUrl'] || this.router.url || '/';
                            this.router.navigate([url]);

                        }*/
                    }
                );

                // set logged in account user slug to local storage for easy access
                if (isPlatformBrowser(this.platformId)) {
                    localStorage.setItem('viewingProfileSlug', data['slug']);
                }

                // track login in Mixpanel
                //this.mixpanelService.track('logged in', { 'method': website });

                this.loginSuccessful.emit(true);
                this.loading = false;
            },
            error => {
                if (error.statusText == 'Forbidden') {
                    this.alertService.error("This account has been suspended and/or removed.");

                } else if (error.statusText == 'Not Acceptable') {
                    this.alertService.error("Could not sign in using Google. Are you registered with Google?");

                } else if (error.status == '404') {
                    this.alertService.warning("No user with that email, please sign up first");

                } else {
                    this.alertService.error("Could not sign in to Google. Are you registered with Google?");
                }

                this.loginSuccessful.emit(false);
                this.loading = false;
            }
        );

    }


    joinHub() {
        let hubId = this.hubId();
        if (hubId != undefined) {
            this.networkService.joinHub(hubId).subscribe(
                data => {
                    //this.alertService.info(data.message);
                },
                error => {
                }
            );
        }
    }

    /*signInApp() {
      let googleUser = await GoogleAuth.signIn();
      console.log(googleUser);
      alert(JSON.stringify(googleUser));
      //If you use Firebase you can forward and use the logged in Google user like this:
      //const credential = auth.GoogleAuthProvider.credential(googleUser.authentication.idToken);
      //return this.afAuth.auth.signInAndRetrieveDataWithCredential(credential);
    }*/


    /*signIn() {
      const provider = new GoogleAuthProvider();
      provider.addScope('https://www.googleapis.com/auth/userinfo.profile');

      const auth = getAuth();
      auth.useDeviceLanguage();
      // auth.languageCode = 'it';

      signInWithPopup(auth, provider).then((result) => {
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const user = result.user;
          const token = credential?.idToken;

          const userCredentials: userCredentials = {
            email: user.email,
            password: null,
            firstName: user.displayName?.split(' ')[0],
            lastName: user.displayName?.split(' ')[1],
            picture: user.photoURL,
            code: null,
            token: token,
            provider: 'google',
            type: this.type()
          };

          this.credentials.emit(userCredentials);

        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
        });

    }*/


}
