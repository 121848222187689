import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

    transform(value: string): string {
        const now = new Date();
        const inputDate = new Date(value);
        const diffInSeconds = Math.floor((now.getTime() - inputDate.getTime()) / 1000);

        if (diffInSeconds < 60) {
            return `${diffInSeconds}s`;

        } else if (diffInSeconds < 3600) {
            const minutes = Math.floor(diffInSeconds / 60);
            return `${minutes}m`;

        } else if (diffInSeconds < 86400) {
            const hours = Math.floor(diffInSeconds / 3600);
            return `${hours}h`;

        } else if (diffInSeconds < 604800) { // 7 days in seconds
            const days = Math.floor(diffInSeconds / 86400);
            return `${days}d`;

        } else if (diffInSeconds < 1209600) { // 14 days in seconds
            const weeks = Math.floor(diffInSeconds / 604800);
            return `${weeks}d`;

        } else if (diffInSeconds < 1209600) { // 2 weeks in seconds
            const weeks = Math.floor(diffInSeconds / 604800); // 604800 seconds in a week
            return `${weeks}w`;

        } else {
            const year = inputDate.getFullYear();
            const month = inputDate.getMonth();
            const day = inputDate.getDate();
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

            // If the year of the input date matches the current year
            if (year === now.getFullYear()) {
                return `${day} ${monthNames[month]}`;
            } else {
                return `${day} ${monthNames[month]} ${year}`;
            }
        }
    }

}
