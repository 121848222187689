import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//import { SocialAuthService } from "@abacritt/angularx-social-login";

//import { User } from '../_models/user';

import { environment } from '../../environments/environment';


export interface userCredentials {
  email: string | null | undefined;
  password: string | null | undefined;
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  picture: string | null | undefined;
  code: string | null | undefined;
  token: string | null | undefined;
  provider: string;
  type: string;
}


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

    domain = environment.domain;
    prefix = environment.api_prefix;

    //private currentUserSubject: BehaviorSubject<User>;
    //public currentUser: Observable<User>;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private http: HttpClient,
        //private socialAuthService: SocialAuthService,
    ) {
    }

    login(email: string, password: string) {
        // obtain_auth_token requires username field
        return this.http.post<any>(this.domain + '/api/token-auth/', { 'username': email, 'password': password })
            .pipe(map(res => {
                // login successful if there's a jwt token in the response
                if (res && res.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    if (isPlatformBrowser(this.platformId)) {
                        localStorage.setItem('currentUser', JSON.stringify(res));
                    }
                }

                return res;
            }));
    }

    logout() {
        if (isPlatformBrowser(this.platformId)) {
            // remove user from local storage to log user out
            localStorage.removeItem('currentUser');
            localStorage.removeItem('currentAccountUser');
            localStorage.removeItem('viewingProfileSlug');

            // remove partner multi account user details
            localStorage.removeItem('masterId');
            localStorage.removeItem('masterName');
            localStorage.removeItem('masterSlug');
        }

        // social logout
        //this.socialAuthService.signOut();
    }

    // legacy function, no longer used
    getAccess(requestType: string, code: string, website: string, token: string, email: string, password: string, providerResponse?: any, clientIpAddress?: string): Observable<any> {

        if (providerResponse) {
            try {
                providerResponse = JSON.parse(providerResponse);
            } catch (e) {
                providerResponse = undefined;
                //console.log(e);
            }
        }

        let data = {
          'requestType': requestType,
          'code': code,
          'provider': website,
          'token': token,
          'username': email,
          'password': password,
          'providerResponse': providerResponse,
          'clientIpAddress': clientIpAddress,
        };

        // NOTE social-auth replaced by auth
        return this.http.post(this.domain + '/api/social-auth/', data)
            .pipe(map(res => {
                // login successful if there's a jwt token in the response
                if (res) { // && res.token
                    if (isPlatformBrowser(this.platformId)) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('currentUser', JSON.stringify(res));
                    }
                }

                return res;
            }));
    }

    // get access token from provider
    // used for signup and login
    signOnViaProvider(credentials: userCredentials, requestType: string, providerResponse?: any, clientIpAddress?: string) {
        if (providerResponse) {
            try {
                providerResponse = JSON.parse(providerResponse);
            } catch (e) {
                providerResponse = undefined;
                //console.log(e);
            }
        }

        let data = {
          'type': requestType,
          'email': credentials.email,
          'firstName': credentials.firstName,
          'lastName': credentials.lastName,
          'picture': credentials.picture,
          'code': credentials.code,
          'token': credentials.token,
          'provider': credentials.provider,
          'username': credentials.email,
          'password': credentials.password,
          'providerResponse': providerResponse,
          'clientIpAddress': clientIpAddress,
        };

        return this.http.post(this.domain + '/api/auth/', data)
            .pipe(map(res => {
                // login successful if there's a jwt token in the response
                if (res) { // && res.token
                    if (isPlatformBrowser(this.platformId)) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('currentUser', JSON.stringify(res));
                    }
                }

                return res;
            }));
    }

    signup(data) {
        this.logout();
        return this.http.post<any>(this.domain + this.prefix + '/signup/', data);
    }

    checkEmail(email): Observable<any> {
        return this.http.post(this.domain + this.prefix + '/check-email/', {'email':email});
    }

    checkCompanyName(name): Observable<any> {
        return this.http.post(this.domain + this.prefix + '/check-company-name/', {'companyName':name});
    }

    checkCompanyPageName(name): Observable<any> {
        return this.http.post(this.domain + this.prefix + '/check-page-name/', {'companyName':name});
    }

}
