import { Deserializable } from "./deserializable";

import { AccountUserPublic } from './user';
import { RelatedDataPoint, RelatedDataPointJob, GroupDataType, FeaturedDataPoint, DataPoint } from './collection';
import { Image } from './media';
import { UserSkillSet } from './user';


export class CompanyFollowRequest implements Deserializable {
    id: number;
    company: number;
    account_user: number;
    accepted: string;
    declined: string;
    page_name: string;
    page_icon: string;
    page_slug: string;
    created: string;
    created_by: number;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getCompanyId() {
        return this.company;
    }

    getAccountUserId() {
        return this.account_user;
    }

    getAccepted() {
        return this.accepted;
    }

    getDeclined() {
        return this.declined;
    }

    getPageName() {
        return this.page_name;
    }

    getPageIcon() {
        if (this.page_icon) {
            return new Image().deserialize(this.page_icon);
        }
        return new Image();
    }

    getPageSlug() {
        return this.page_slug;
    }

    getCreated() {
        return this.created;
    }

    getCreatedBy() {
        return this.created_by;
    }

}


export class CompanyFollow implements Deserializable {
    id: number;
    company: number;
    account_user: number;
    accepted: string;
    declined: string;
    page_name: string;
    page_icon: string;
    page_slug: string;
    created: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getCompanyId() {
        return this.company;
    }

    getAccountUserId() {
        return this.account_user;
    }

    getAccepted() {
        return this.accepted;
    }

    getDeclined() {
        return this.declined;
    }

    getPageName() {
        return this.page_name;
    }

    getPageIcon() {
        if (this.page_icon) {
            return new Image().deserialize(this.page_icon);
        }
        return new Image();
    }

    getPageSlug() {
        return this.page_slug;
    }

    getCreated() {
        return this.created;
    }

}


export class Category implements Deserializable {
    id: number;
    name: any;
    icon: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getName(locale = 'en') {
        if (locale != 'en' && !this.name[locale])
            return this.name['en']; // fall back to default
        return this.name[locale];
    }

    getIcon() {
        return this.icon;
    }
}

export class Function implements Deserializable {
    id: number;
    name: any;
    icon: string;
    job_count: number;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getName(locale = 'en') {
        if (locale != 'en' && !this.name[locale])
            return this.name['en']; // fall back to default
        return this.name[locale];
    }

    getIcon() {
        return this.icon;
    }

    getJobCount() {
        return this.job_count;
    }

    hasJobs() {
        return this.job_count > 0;
    }
}

export class Company implements Deserializable {
    id: number;
    account: any;
    customer: number;
    industry: number;
    industry_code: string;
    industry_text: string;
    stripe_customer_id: string;
    stripe_customer_currency: string;
    stripe_subscription_id: string;
    stripe_price_id: string;
    stripe_product_id: string;
    currency_code: string;
    rating: string;
    rated_by: any;
    rating_date: any;
    status: string;
    status_by: string;
    status_date: any;
    status_text: string;
    status_text_date: any;
    prio: string;
    prio_by: string;
    prio_date: any;
    stage: string;
    stage_by: string;
    stage_date: any;
    referral_program_status: string;
    referral_program_status_by: string;
    referral_program_status_date: any;
    referral_program_status_reason: string;
    stop: boolean;
    stop_note: string;
    qualified: boolean;
    qualified_by: any;
    qualified_date: any;
    qualified_questions: string;
    name: string;
    orgnr: string;
    registration_date: any;
    tel: string;
    note: string;
    email_address1: string;
    email_address2: string;
    employer_contribution_reg: boolean;
    employer_contribution_reg_date: any;
    preliminary_tax_reg: boolean;
    vat_reg: boolean;
    vat_reg_date: any;
    vat_nr: string;
    number_employees: string;
    address1: string;
    address2: string;
    zip_code: string;
    city: string;
    county: string;
    country: number;
    country_code: string;
    country_vat: number;
    country_in_eu: boolean;
    web_address: string;
    top_director_name: string;
    top_director_function: string;
    imported: any;
    created: any;
    created_by: any;
    sellers: any;
    last_open_task: string;
    page_name: string;
    page_icon: any;
    page_slug: string;
    page_completed: number;
    active_users: number;
    invited_users: number;
    customer_has_errors: boolean;
    credit: number;
    active_subscriptions: any;
    follower_count: number;
    project_count: number;
    job_count: number;
    job_active_count: number;
    open_jobs: Job[];
    order_count: number;
    deal_count: number;

    deserialize(input: any) {
        Object.assign(this, input);

        if (this.open_jobs) {
            this.open_jobs = this.open_jobs.map(
                job => new Job().deserialize(job)
            );
        }

        return this;
    }

    getId() {
        return this.id;
    }

    // must return the account id
    getAccountId() {
        return this.account;
    }

    getCustomerId() {
        return this.customer;
    }

    getIndustryId() {
        return this.industry;
    }

    getIndustryCode() {
        return this.industry_code;
    }

    getIndustryText() {
        return this.industry_text;
    }

    getStripeCustomerId() {
        return this.stripe_customer_id;
    }

    getStripeCustomerCurrency() {
        return this.stripe_customer_currency;
    }

    getStripeSubscriptionId() {
        return this.stripe_subscription_id;
    }

    isPremium() {
        if (this.getStripeSubscriptionId()) {
            return true;
        }
        return false;
    }

    getStripePriceId() {
        return this.stripe_price_id;
    }

    getStripeProductId() {
        return this.stripe_product_id;
    }

    getCurrencyCode() {
        return this.currency_code;
    }

    getRating() {
        return this.rating;
    }

    getRatedBy() {
        return this.rated_by;
    }

    getRatingDate() {
        return this.rating_date;
    }

    getStatus() {
        //if (this.customer) {
        //    return "Customer";
        //}
        return this.status;
    }

    getStatusBy() {
        return this.status_by;
    }

    getStatusDate() {
        return this.status_date;
    }

    getStatusText() {
        return this.status_text;
    }

    getStatusTextDate() {
        return this.status_text_date;
    }

    getPrio() {
        return this.prio;
    }

    getPrioBy() {
        return this.prio_by;
    }

    getPrioDate() {
        return this.prio_date;
    }

    getStage() {
        return this.stage;
    }

    getStageBy() {
        return this.stage_by;
    }

    getStageDate() {
        return this.stage_date;
    }

    getReferralProgramStatus() {
        return this.referral_program_status;
    }

    getReferralProgramStatusBy() {
        return this.referral_program_status_by;
    }

    getReferralProgramStatusDate() {
        return this.referral_program_status_date;
    }

    getReferralProgramStatusReason() {
        return this.referral_program_status_reason;
    }

    isStopped() {
        return this.stop;
    }

    getStopNote() {
        return this.stop_note;
    }

    getQualified() {
        return this.qualified;
    }

    getQualifiedBy() {
        return this.qualified_by;
    }

    getQualifiedDate() {
        return this.qualified_date;
    }

    getQualifiedQuestions() {
        return this.qualified_questions;
    }

    getName() {
        return this.name;
    }

    getOrgNr() {
        return this.orgnr;
    }

    getRegistrationDate() {
        return this.registration_date;
    }

    getTel() {
        return this.tel;
    }

    getNote() {
        return this.note;
    }

    getEmailAddress1() {
        return this.email_address1;
    }

    getEmailAddress2() {
        return this.email_address2;
    }

    isEmployerContributionReg() {
        return this.employer_contribution_reg;
    }

    getEmployerContributionRegDate() {
        return this.employer_contribution_reg_date;
    }

    getPreliminaryTaxReg() {
        return this.preliminary_tax_reg;
    }

    isVatReg() {
        return this.vat_reg;
    }

    getVatRegDate() {
        return this.vat_reg_date;
    }

    getVatNr() {
        return this.vat_nr;
    }

    getNumberEmployees() {
        return this.number_employees;
    }

    getAddress1() {
        return this.address1;
    }

    getAddress2() {
        return this.address2;
    }

    getZipCode() {
        return this.zip_code;
    }

    getCity() {
        return this.city;
    }

    getCounty() {
        return this.county;
    }

    getCountryId() {
        return this.country;
    }

    getCountryCode() {
        return this.country_code;
    }

    getCountryVat() {
        return this.country_vat;
    }

    getCountryInEU() {
        return this.country_in_eu;
    }

    //getCountry() {
    //    return new Country().deserialize(this.country_obj);
    //}

    getWebAddress() {
        return this.web_address;
    }

    getTopDirectorName() {
        return this.top_director_name;
    }

    getTopDirectorFunction() {
        return this.top_director_function;
    }

    getImported() {
        return this.imported;
    }

    getCreated() {
        return this.created;
    }

    getCreatedBy() {
        return this.created_by;
    }

    // NOTE DO NOT USE SellerSerializer here as it contains sensitive data
    getSellers() {
        return this.sellers;
        /*if (this.sellers) {
            let r = [];
            for (let seller of this.sellers) {
                r.push(new Seller().deserialize(seller));
            }
            return r;
        }
        return [];*/
    }

    countSellers() {
        return this.getSellers().length;
    }

    getLastOpenTask() {
        return this.last_open_task;
    }

    getPageName() {
        return this.page_name;
    }

    getPageIcon() {
        if (this.page_icon) {
            return new Image().deserialize(this.page_icon);
        }
        return new Image();
    }

    getPageSlug() {
        return this.page_slug;
    }

    getPageCompleted() {
        return this.page_completed * 100;
    }

    getActiveUsers() {
        return this.active_users;
    }

    getInvitedUsers() {
        return this.invited_users;
    }

    getTotalUsers() {
        return this.getActiveUsers() + this.getInvitedUsers();
    }

    getCustomerHasErrors() {
        return this.customer_has_errors;
    }

    getCredit() {
        return this.credit;
    }

    getActiveSubscriptions() {
        return this.active_subscriptions;
    }

    /*isStarter() {
        return this.getActiveSubscriptions().includes('starter');
    }

    isPro() {
        return this.getActiveSubscriptions().includes('pro');
    }

    hasSubscription() {
        return this.isStarter() || this.isPro();
    }*/

    getFollowerCount() {
        return this.follower_count;
    }

    getProjectCount() {
        return this.project_count;
    }

    getJobCount() {
        return this.job_count;
    }

    getJobActiveCount() {
        return this.job_active_count;
    }

    getOpenJobs() {
        return this.open_jobs;
    }

    getOrderCount() {
        return this.order_count;
    }

    getDealCount() {
        return this.deal_count;
    }
}


export class CompanySm implements Deserializable {
    id: number;
    name: string;
    slug: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }

    getSlug() {
        return this.slug;
    }
}


export class CompanyUser implements Deserializable {
    id: number;
    company: number;
    account_user: number;
    full_name: string;
    email: string;
    role: string;
    created: string;

    dropdownSettings: boolean = false;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getCompanyId() {
        return this.company;
    }

    getAccountUserId() {
        return this.account_user;
    }

    getFullName() {
        return this.full_name;
    }

    getEmail() {
        return this.email;
    }

    getRole() {
        return this.role;
    }

    getCreated() {
        return this.created;
    }
}


export class Page implements Deserializable {
    id: number;
    account: any;
    company: number;
    company_name: string;
    company_orgnr: string;
    company_user_count: number;
    follower_count: number;
    category: number;
    slug: string;
    viewed: number;
    liked: number;
    shared: number;
    promoted: boolean;
    status: string;
    language: string;
    icon: any;
    name: string;
    completed: number;

    about_us: string;
    about_products: string;
    about_people: string;
    about_jobs: string;
    about_career_life: string;
    about_perks_benefits: string;
    about_values: string;
    about_vision: string;
    about_culture: string;
    about_founders: string;
    about_capital: string;
    about_customers: string;
    about_partners: string;
    about_industry: string;
    about_sustainability: string;
    about_awards: string;

    why_us: string;
    our_vision: string;
    our_pitch: string;
    registration_date: any;
    address: string;
    tel: string;
    email: string;
    number_employees: string;
    industry_text: string;
    web_address: string;
    facebook_url: string;
    linkedin_url: string;
    github_url: string;
    twitter_url: string;
    instagram_url: string;
    youtube_url: string;
    youtube_video_id: string;
    youtube_video_url: string;
    created: any;
    created_by: any;

    images: any;
    main_image: any;

    open_jobs: any;
    count_jobs: number;
    count_job_applications: number;
    count_company_users: number;

    related_data_points: any;
    rdps: any;

    jobs: Job[];

    show_related_pages: boolean = true;

    deserialize(input: any) {
        Object.assign(this, input);

        if (this.jobs) {
            this.jobs = this.jobs.map(
                job => new Job().deserialize(job)
            );
        }

        if (this.youtube_video_id) {
            // security check that youtube_video_id is not a url
            const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/;
            if (this.youtube_video_id && !urlPattern.test(this.youtube_video_id)) {
                let url = 'https://www.youtube.com/embed/' + this.youtube_video_id;
                this.youtube_video_url = url;
            }
        }

        return this;
    }

    getId() {
        return this.id;
    }

    // must return the account id
    getAccountId() {
        return this.account;
    }

    getCompanyId() {
        return this.company;
    }

    getCompanyName() {
        return this.company_name;
    }

    getCompanyOrgNr() {
        return this.company_orgnr;
    }

    getCategoryId() {
        return this.category;
    }

    getCompanyUserCount() {
        return this.company_user_count;
    }

    getFollowerCount() {
        return this.follower_count;
    }

    getSlug() {
        return this.slug;
    }

    getViewed() {
        return this.viewed;
    }

    getLiked() {
        return this.liked;
    }

    getShared() {
        return this.shared;
    }

    isPromoted() {
        return this.promoted;
    }

    getStatus() {
        return this.status;
    }

    getLanguage() {
        return this.language;
    }

    getIcon() {
        if (this.icon) {
            return new Image().deserialize(this.icon);
        }
        return new Image();
    }

    getName() {
        return this.name;
    }

    getCompleted() {
        return this.completed;
    }

    getAboutUs() {
        return this.about_us;
    }

    getAboutProducts() {
        return this.about_products;
    }

    getAboutPeople() {
        return this.about_people;
    }

    getAboutJobs() {
        return this.about_jobs;
    }

    getAboutCareerLife() {
        return this.about_career_life;
    }

    getAboutPerksBenefits() {
        return this.about_perks_benefits;
    }

    getAboutValues() {
        return this.about_values;
    }

    getAboutVision() {
        return this.about_vision;
    }

    getAboutCulture() {
        return this.about_culture;
    }

    getAboutFounders() {
        return this.about_founders;
    }

    getAboutCapital() {
        return this.about_capital;
    }

    getAboutCustomers() {
        return this.about_customers;
    }

    getAboutPartners() {
        return this.about_partners;
    }

    getAboutIndustry() {
        return this.about_industry;
    }

    getAboutSustainability() {
        return this.about_sustainability;
    }

    getAboutAwards() {
        return this.about_awards;
    }

    getWhyUs() {
        return this.why_us;
    }

    getOurVision() {
        return this.our_vision;
    }

    getOurPitch() {
        return this.our_pitch;
    }

    getRegistrationDate() {
        return this.registration_date;
    }

    getAddress() {
        return this.address;
    }

    getTel() {
        return this.tel;
    }

    getEmail() {
        return this.email;
    }

    getNumberEmployees() {
        return this.number_employees;
    }

    getIndustryText() {
        return this.industry_text;
    }

    getWebAddress() {
        return this.web_address;
    }

    getFacebookUrl() {
        return this.facebook_url;
    }

    getLinkedInUrl() {
        return this.linkedin_url;
    }

    getGithubUrl() {
        return this.github_url;
    }

    getTwitterUrl() {
        return this.twitter_url;
    }

    getInstagramUrl() {
        return this.instagram_url;
    }

    getYoutubeUrl() {
        return this.youtube_url;
    }

    getYoutubeVideoId() {
        return this.youtube_video_id;
    }

    getYoutubeVideoUrl() {
        return this.youtube_video_url;
    }

    getCreated() {
        return this.created;
    }

    getCreatedBy() {
        return this.created_by;
    }

    getOpenJobs() {
        if (this.open_jobs) {
            let r: Job[] = [];
            for (let job of this.open_jobs) {
                r.push(new Job().deserialize(job));
            }
            return r;
        }
        return [];
    }

    getCountJobs() {
        return this.count_jobs;
    }

    getCountJobApplications() {
        return this.count_job_applications;
    }

    getCountCompanyUsers() {
        return this.count_company_users;
    }

    getImages() {
        if (this.images) {
            let r: Image[] = [];
            for (let i of this.images) {
                r.push(new Image().deserialize(i));
            }
            return r;
        }
        return [];
    }

    getMainImage() {
        if (this.main_image) {
            return new Image().deserialize(this.main_image);

        } else if (this.images) {
            return this.getImages()[0];
        }
        return new Image();
    }

    getRelatedDataPoints() {
        let l: RelatedDataPoint[] = [];
        let rdps: RelatedDataPoint[] | null = null;

        if (this.related_data_points) {
            rdps = this.related_data_points;

        } else if (this.rdps) {
            rdps = this.rdps;
        }

        if (rdps) {
            for (let i of rdps) {
                l.push(new RelatedDataPoint().deserialize(i));
            }
        }
        return l;
    }

    getRelatedDataPointsByKey(key: string) {
        let l: RelatedDataPoint[] = [];
        let rdps: RelatedDataPoint[] | null = null;

        if (this.getRelatedDataPoints()) {
            rdps = this.getRelatedDataPoints();
        }

        if (rdps) {
            for (let i of this.getRelatedDataPoints()) {
                // get by key
                if (i.getDataTypeKey() == key) {
                    l.push(i);
                }
            }
        }
        return l;
    }

    getDataPointsByKey(key: string) {
        let l: (DataPoint | null)[] = [];
        let rdps: RelatedDataPoint[] | null = null;

        if (this.getRelatedDataPoints()) {
            rdps = this.getRelatedDataPoints();
        }

        if (rdps) {
            for (let i of this.getRelatedDataPoints()) {
                // get by key
                if (i.getDataTypeKey() == key) {
                    l.push(i.getDataPoint());
                }
                // get by sub key
                if (i.getSubDataTypeKey() == key) {
                    l.push(i.getSubDataPoint());
                }
            }
        }
        return l;
    }

    getDataPointStringList(key: string, locale: string = 'en'): string[] {
        let l: any[] = [];
        for (let dp of this.getDataPointsByKey(key)) {
            if (dp && dp.getDataTypeKeys().includes(key))
                l.push(dp.getValue(locale));
        }
        return l;
    }

    getDataPointsFirstString(key: string, locale: string = 'en') {
        let l = this.getDataPointStringList(key, locale);
        if (l.length > 0)
            return l[0];
        return '';
    }

    getDataPointsAsString(key: string, locale: string = 'en') {
        let l = this.getDataPointStringList(key, locale);
        if (l.length > 0)
            return l.join(', ');
        return '';
    }

    getShowRelatedPages() {
        // based on company subscription
        return this.show_related_pages;
    }

}


export class Job implements Deserializable {
    id: number;
    key: string;
    account: any;
    project_id: number;
    company: number;
    company_name: string;
    company_slug: string;
    page_name: string;
    page_slug: string;
    page_icon: any;
    page_main_image: any;
    page_completed: number;
    page_follower_count: number;
    category: number;
    function: number;
    department: number;
    owner: number;
    owner_full_name: string;

    user_skill_sets: any;
    has_account_user_applied: string;

    viewed: number;
    liked: number;
    shared: number;

    agents_notified: boolean;

    open_positions: number;
    application_count: number;

    score: number;

    status: string;
    work_from: string;
    experience: string;
    experience_required: boolean;

    terms_form: string;
    terms_extent: string;
    terms_part_time_scope: number;
    terms_salary: string;

    pre_qualify: string;
    stages: any;

    title: string;
    monitor_job: boolean;
    external_job: boolean;
    external_clicks: number;
    external_url: string;
    external_email: string;
    preamble: string;
    description: any;
    summary: string;
    about_job: string;
    about_profile: string;

    published: any;
    unpublished: any;
    approved: any;
    declined: any;
    start_position: any;

    starting: string;

    address1: string;
    address2: string;
    zip_code: string;
    city: string;
    county: string;
    country: number;

    contact_name: string;
    contact_initials: string;
    contact_phone: string;
    contact_email: string;

    referral_active: boolean;
    referral_price: number;
    referral_price_application: number;
    referral_commission: number;
    referral_commission_application: number;
    referral_signon_bonus: number;
    currency: number;
    currency_code: string;
    keywords: string;
    referral_program_status: string;
    referral_terms_accepted: any;
    referral_terms_accepted_by: number;

    show_referral_program: boolean;

    language: string;
    can_share: boolean;

    //related_data_points: any;

    hiring_managers: any;

    grdps: any;

    dp_work_from: any;
    dp_location: any;

    has_linked_slot: boolean;

    created: any;
    created_by: any;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }


    getRdpJobType() {
        return this.getDataPointsAsString('pref_preferred_job_type');
    }

    getRdpExperienceLevel() {
        return this.getDataPointsAsString('experience_level');
    }

    getRdpWorkFrom() {
        return this.getDataPointsAsString('pref_prefer_to_work_from');
    }

    getRdpLocations() {
        return this.getDataPointsAsString('pref_preferred_locations');
    }

    getId() {
        return this.id;
    }

    getKey() {
        return this.key;
    }

    // must return the account id
    getAccountId() {
        return this.account;
    }

    getProjectId() {
        return this.project_id;
    }

    getCompanyId() {
        return this.company;
    }

    getCompanyName() {
        return this.company_name;
    }

    getCompanySlug() {
        return this.company_slug;
    }

    getPageName() {
        return this.page_name;
    }

    getPageSlug() {
        return this.page_slug;
    }

    getPageIcon() {
        if (this.page_icon) {
            return new Image().deserialize(this.page_icon);
        }
        return new Image();
    }

    getPageMainImage() {
        if (this.page_main_image) {
            return new Image().deserialize(this.page_main_image);
        }
        return new Image();
    }

    getPageCompleted() {
        return this.page_completed * 100;
    }

    getPageFollowerCount() {
        return this.page_follower_count;
    }

    getCategoryId() {
        return this.category;
    }

    getFunctionId() {
        return this.function;
    }

    getDepartmentId() {
        return this.department;
    }

    getOwnerAccountUserId() {
        return this.owner;
    }

    getOwnerAccountUserFullName() {
        return this.owner_full_name;
    }

    getUserSkillSets() {
        let l: UserSkillSet[] = [];
        for (let i of this.user_skill_sets) {
            l.push(new UserSkillSet().deserialize(i));
        }
        return l;
    }

    getUserSkillSetsAsString() {
        let l: any[] = [];
        for (let i of this.user_skill_sets) {
            l.push(i.skill_name);
        }
        return l.toString();
    }

    hasAccountUserApplied() {
        return this.has_account_user_applied;
    }

    getViewed() {
        return this.viewed;
    }

    getLiked() {
        return this.liked;
    }

    getShared() {
        return this.shared;
    }

    isAgentCommunityNotified() {
        return this.agents_notified;
    }

    getOpenPositions() {
        return this.open_positions;
    }

    getApplicationCount() {
        return this.application_count;
    }

    getScore() {
        return this.score;
    }

    getStatus() {
        return this.status;
    }

    getWorkFrom() {
        return this.work_from;
    }

    getExperience() {
        return this.experience;
    }

    getExperienceRequired() {
        return this.experience_required;
    }

    getTermsForm() {
        return this.terms_form;
    }

    getTermsExtent() {
        return this.terms_extent;
    }

    getTermsPartTimeScope() {
        return this.terms_part_time_scope;
    }

    getTermsSalary() {
        return this.terms_salary;
    }

    getPreQualify() {
        return this.pre_qualify;
    }

    getPreQualifyQuestionCount() {
        try {
            var obj = JSON.parse(this.pre_qualify);
            if (obj && obj.length > 0) {
                return obj.length;
            }
        } catch(e) {
            return 0;
        }
        return 0;
    }


    getStages() {
        let r: any[] = [];
        if (this.stages) {
            const data = this.stages;
            Object.keys(data || {}).forEach(function(key) {
                //console.log('Key : ' + key + ', Value : ' + data[key])
                r.push(data[key]);
            })
        }
        return r;
    }

    getTitle() {
        return this.title;
    }

    isMonitorJob() {
        return this.monitor_job;
    }

    isExternalJob() {
        return this.external_job;
    }

    getExternalClicks() {
        return this.external_clicks;
    }

    getExternalUrl() {
        return this.external_url;
    }

    getExternalEmail() {
        return this.external_email;
    }

    getPreamble() {
        return this.preamble;
    }

    getDescription() {
        return this.description;
    }

    getSummary() {
        return this.summary;
    }

    getAboutJob() {
        return this.about_job;
    }

    getAboutProfile() {
        return this.about_profile;
    }

    getPublished() {
        return this.published;
    }

    getUnpublished() {
        return this.unpublished;
    }

    getApproved() {
        return this.approved;
    }

    getDeclined() {
        return this.declined;
    }

    getStarting() {
        return this.starting;
    }

    getStartPosition() {
        return this.start_position;
    }

    getAddress1() {
        return this.address1;
    }

    getAddress2() {
        return this.address2;
    }

    getZipCode() {
        return this.zip_code;
    }

    getCity() {
        return this.getDataPointsAsString('pref_preferred_locations');
        //return this.city;
    }

    getCounty() {
        return this.county;
    }

    getCountry() {
        return this.country;
    }

    getContactName() {
        return this.contact_name;
    }

    getContactInitials() {
        return this.contact_initials;
    }

    getContactPhone() {
        return this.contact_phone;
    }

    getContactEmail() {
        return this.contact_email;
    }

    getReferralActive() {
        return this.referral_active;
    }

    getReferralPrice() {
        return this.referral_price;
    }

    getReferralPriceApplication() {
        return this.referral_price_application;
    }

    getReferralCommission() {
        return this.referral_commission;
    }

    getReferralCommissionApplication() {
        return this.referral_commission_application;
    }

    getReferralSignonBonus() {
        return this.referral_signon_bonus;
    }

    getReferralProgramStatus() {
        return this.referral_program_status;
    }

    getReferralProgramTermsAccepted() {
        return this.referral_terms_accepted;
    }

    isReferralProgramTermsAccepted() {
        if (this.getReferralProgramTermsAccepted()) {
            return true;
        }
        return false;
    }

    getReferralProgramTermsAcceptedBy() {
        return this.referral_terms_accepted_by;
    }

    getShowReferralProgram() {
        return this.show_referral_program;
    }

    isReferralProgramAccepted() {
        if ((this.referral_program_status == 'accepted_manual' || this.referral_program_status == 'accepted_auto')) {
            return true;
        }
        return false;
    }

    getCurrencyId() {
        return this.currency;
    }

    getCurrencyCode() {
        return this.currency_code;
    }

    getKeywords() {
        return this.keywords;
    }

    getLanguage() {
        return this.language;
    }

    getCanShare() {
        return this.can_share;
    }

    getHiringManagers() {
        let r: AccountUserPublic[] = [];
        if (this.hiring_managers) {
            const data = this.hiring_managers;
            for (let hm of data) {
                r.push(new AccountUserPublic().deserialize(hm));
            }
        }
        return r;
    }

    getDpsAsString(dps: any) {
        let l: any[] = [];
        let dpv: DataPoint | null = null;
        if (dps) {
            for (let dp of dps) {
                //l.push(dp['value']);
                dpv = new DataPoint().deserialize(dp);
                l.push(dpv.getValue());
            }
        }

        if (l.length > 0)
            return l.join(', ');
        return '';
    }

    getRelatedDataPoints() {
        let rdps: RelatedDataPointJob[] = [];

        if (this.grdps) {
            for (let grdp of this.grdps) {
                for (let gr of grdp['grdps']) {
                    for (let rdp of gr['related']) {
                        rdps.push(new RelatedDataPointJob().deserialize(rdp));
                    }
                }
            }
        }
        return rdps;

        /*let l = [];
        if (this.related_data_points) {
            for (let i of this.related_data_points) {
                l.push(new RelatedDataPointJob().deserialize(i));
            }
        }
        return l;*/
    }

    getRelatedDataPointsByKey(key: string) {
        let l: RelatedDataPointJob[] = [];
        if (this.getRelatedDataPoints()) {
            for (let i of this.getRelatedDataPoints()) {
                // get by key
                if (i.getDataTypeKey() == key) {
                    l.push(i);
                }
            }
        }
        return l;
    }

    getGrdps(byGroupName: string) {

        for (let gr of this.grdps) {
            if (gr.group_name == byGroupName) {

                let data: any = {
                    'groupName': gr.group_name,
                    //'grdps': gr.grdps,    // raw data
                    'gr': [],               // grouped data
                };

                // for every group
                for (let gdps of gr.grdps) {

                    const gdt = new GroupDataType().deserialize(gdps.group);
                    const fdps = gdps.featured.map(f => new FeaturedDataPoint().deserialize(f));

                    const found = data['gr'].find(gr => gr.group.key == gdt.key);

                    if (!found) {
                        data['gr'].push({
                            'group': gdt,
                            'related': [],
                            'featured': fdps
                        });

                        for (let rdp of gdps.related)
                            data['gr'][data['gr'].length - 1].related.push(new RelatedDataPointJob().deserialize(rdp));

                    }
                }
                //console.log('getGrdps', data);
                return data;
            }
        }

        //return this.rdps;
    }

    getDataPointsByKey(key: string) {
        let l: (DataPoint | null)[] = [];
        if (this.getRelatedDataPoints()) {
            for (let i of this.getRelatedDataPoints()) {
                // get by key
                if (i.getDataTypeKey() == key) {
                    l.push(i.getDataPoint());
                }
                // get by sub key
                if (i.getSubDataTypeKey() == key) {
                    l.push(i.getSubDataPoint());
                }
            }
        }
        return l;
    }

    getDataPointStringList(key: string, locale: string = 'en'): string[] {
        let l: any[] = [];
        for (let dp of this.getDataPointsByKey(key)) {
            if (dp && dp.getDataTypeKeys().includes(key))
                l.push(dp.getValue(locale));
        }
        return l;
    }

    getDataPointsFirstString(key: string, locale: string = 'en') {
        let l = this.getDataPointStringList(key, locale);
        if (l.length > 0)
            return l[0];
        return '';
    }

    getDataPointsAsString(key: string, locale: string = 'en') {
        let l = this.getDataPointStringList(key, locale);
        if (l.length > 0)
            return l.join(', ');
        return '';
    }

    getUrlPath() {
        return '/' + this.company_slug + '/job/' + this.id.toString();
    }

    hasLinkedSlot() {
        return this.has_linked_slot;
    }

    getCreated() {
        return this.created;
    }

    getCreatedBy() {
        return this.created_by;
    }
}


export class JobStage implements Deserializable {
    id: number;
    job: number;
    name: string;
    position: number;
    created: string;
    created_by: number;
    applications: any;

    jobApplications: JobApplication[];

    deserialize(input: any) {
        Object.assign(this, input);

        this.jobApplications = this.getApplications();

        return this;
    }

    getId() {
        return this.id;
    }

    getJobId() {
        return this.job;
    }

    getName() {
        return this.name;
    }

    getPosition() {
        return this.position;
    }

    getCreated() {
        return this.created;
    }

    getCreatedBy() {
        return this.created_by;
    }

    getApplications() {
        let r: JobApplication[] = [];
        for (let app of this.applications) {
            r.push(new JobApplication().deserialize(app));
        }
        return r;
    }

    getJobApplications() {
        return this.jobApplications;
    }
}


export class JobApplication implements Deserializable {
    id: number;
    account: number;
    account_user: number;
    job: number;
    company_id: number;
    company_name: string;
    attached_file: string;
    status: string;
    archived: boolean;
    hired: string;
    hired_by: number;
    declined: string;
    declined_by: number;
    message: string;
    pre_qualify: any;
    rating: number;
    created: any;
    last_updated_by: number;

    page_slug: string;
    page_icon: string;
    page_name: string;

    job_title: string;
    job_city: string;
    job_contact_au_id: number;

    account_user_main_image: any;
    account_user_full_name: string;
    account_user_email: string;
    account_user_phone: string;
    account_user_is_premium: boolean;

    invited_by: number;
    invited_by_full_name: string;
    referred_by: number;
    referred_by_full_name: string;

    notification_count: number;
    comment_count: number;
    qualify_score: number;
    score: number;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    // NOTE replaced by qualify_score from backend
    getQuestionsMaxPoints(): number {
        let points = 0;
        if (this.pre_qualify) {
            for (let question of this.pre_qualify) {
                let pointList: any[] = [];

                if (question.options) {
                    for (let option of question.options) {
                        if (option.points > 0) {
                            pointList.push(option.points);
                        }
                    }

                    let largest = 0;

                    for (let i = 0; i <= largest; i++){
                        if (pointList[i] > largest) {
                            largest = pointList[i];
                        }
                    }

                    points += largest;
                }
            }
        }
        return points;
    }

    // NOTE replaced by qualify_score from backend
    getQuestionsAnswerPoints() {
        let points = 0;
        if (this.pre_qualify) {
            for (let question of this.pre_qualify) {
                if (question.options) {
                    for (let option of question.options) {
                        if (option.points > 0 && option.selected) {
                            points += option.points;
                        }
                    }
                }
            }
        }
        return points;
    }

    getQuestionsAnswerPercent() {
        return this.qualify_score;
        //return (this.getQuestionsAnswerPoints() / this.getQuestionsMaxPoints()) * 100;
    }


    getId() {
        return this.id;
    }

    getAccountId() {
        return this.account;
    }

    getAccountUserId() {
        return this.account_user;
    }

    getAccountUserMainImage() {
        if (this.account_user_main_image) {
            return new Image().deserialize(this.account_user_main_image);
        }
        return new Image();
    }

    getAccountUserFullName() {
        if (this.account_user_full_name == ' ') {
            return null;
        }
        return this.account_user_full_name;
    }

    getAccountUserEmail() {
        return this.account_user_email;
    }

    getAccountUserPhone() {
        return this.account_user_phone;
    }

    getAccountUserIsPremium() {
        return this.account_user_is_premium;
    }

    getJobId() {
        return this.job;
    }

    getCompanyId() {
        return this.company_id;
    }

    getCompanyName() {
        return this.company_name;
    }

    getAttachedFile() {
        return this.attached_file;
    }

    getStatus() {
        return this.status;
    }

    isArchived() {
        return this.archived;
    }

    getHiredDate() {
        return this.hired;
    }

    getHiredBy() {
        return this.hired_by;
    }

    getDeclinedDate() {
        return this.declined;
    }

    getDeclinedBy() {
        return this.declined_by;
    }

    getMessage() {
        return this.message;
    }

    getPreQualify() {
        return this.pre_qualify;
    }

    getRating() {
        return this.rating;
    }

    getCreated() {
        return this.created;
    }

    getPageSlug() {
        return this.page_slug;
    }

    getPageIcon() {
        if (this.page_icon) {
            return new Image().deserialize(this.page_icon);
        }
        return new Image();
    }

    getPageName() {
        return this.page_name;
    }

    getJobTitle() {
        return this.job_title;
    }

    getJobCity() {
        return this.job_city;
    }

    getJobContactAuId() {
        return this.job_contact_au_id;
    }

    getInvitedBy() {
        return this.invited_by;
    }

    getInvitedByFullName() {
        return this.invited_by_full_name;
    }

    getReferredBy() {
        return this.referred_by;
    }

    getReferredByFullName() {
        return this.referred_by_full_name;
    }

    getNotificationCount() {
        return this.notification_count;
    }

    getCommentCount() {
        return this.comment_count;
    }

    getMessageCount() {
        return this.getNotificationCount() + this.getCommentCount();
    }

    getQualifyScore() {
        return this.qualify_score;
    }

    getScore() {
        return this.score;
    }

}


export class JobApplicationMessage implements Deserializable {
    id: number;
    account: number;
    job_application: number;
    type: string;
    subject: string;
    message: string;
    created: string;
    created_by: number;
    created_by_full_name: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getAccountId() {
        return this.account;
    }

    getJobApplicationId() {
        return this.job_application;
    }

    getType() {
        return this.type;
    }

    getSubject() {
        return this.subject;
    }

    getMessage() {
        return this.message;
    }

    getCreated() {
        return this.created;
    }

    getCreatedBy() {
        return this.created_by;
    }

    getCreatedByFullName() {
        return this.created_by_full_name;
    }
}


export class Deal implements Deserializable {
    id: number;
    account: any;
    //contract: number;
    contracts: any;
    recruitment: number;
    customer_id: number;
    company: number;
    company_name: string;
    currency: number;
    currency_code: string;
    order: number;
    order_status: string;
    type: string;
    prio: string;
    status: string;
    name: string;
    note: string;
    city: string;
    background: string;
    estimated_close: any;
    work_form: string;
    work_start: any;
    work_end: any;
    progress: number;
    required_skills: any;
    hours: number;
    hour_price: number;
    hour_type: string;
    hour_period: string;
    recruiting_price: number;
    won: any;
    won_by: number
    lost: any;
    lost_by: number;
    owner: number;
    created: any;
    created_by: any;
    created_by_full_name: string;
    created_by_initials: string;
    //contract_parties: any;
    //contract_signed: boolean;
    //contract_sent_to_all: boolean;
    tasks_overdue: number;
    has_signed_customer: boolean;
    has_signed_candidate: boolean;

    loading: boolean = false;

    tab: string = 'details';

    expanded: boolean = false;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    // must return the account id
    getAccountId() {
        return this.account;
    }

    getContracts() {
        return this.contracts;
    }

    /*getContractId() {
        return this.contract;
    }

    getContractParties() {
        if (this.contract_parties) {
            let p = [];
            for (let party of this.contract_parties) {
                p.push(new Party().deserialize(party));
            }
            return p;
        }
        return null;
    }*/

    /*isContractSigned() {
        return this.contract_signed;
    }

    isContractSentToAll() {
        return this.contract_sent_to_all;
    }*/

    getRecruitmentId() {
        return this.recruitment;
    }

    getCustomerId() {
        return this.customer_id;
    }

    getCompanyId() {
        return this.company;
    }

    getCompanyName() {
        return this.company_name;
    }

    getCurrencyId() {
        return this.currency;
    }

    getCurrencyCode() {
        return this.currency_code;
    }

    getOrderId() {
        return this.order;
    }

    getOrderStatus() {
        return this.order_status;
    }

    getType() {
        return this.type;
    }

    getPrio() {
        return this.prio;
    }

    getStatus() {
        return this.status;
    }

    getName() {
        return this.name;
    }

    getNote() {
        return this.note;
    }

    getCity() {
        return this.city;
    }

    getBackground() {
        return this.background;
    }

    getEstimatedClose() {
        return this.estimated_close;
    }

    getProgress() {
        if (this.progress) {
            return this.progress;
        }
        return 0;
    }

    getRequiredSkills() {
        return this.required_skills;
    }

    getWorkForm() {
        return this.work_form;
    }

    getWorkStart() {
        return this.work_start;
    }

    getWorkEnd() {
        return this.work_end;
    }

    getHours() {
        return this.hours;
    }

    getHourPrice() {
        return this.hour_price;
    }

    getHourType() {
        return this.hour_type;
    }

    getHourPeriod() {
        return this.hour_period;
    }

    getRecruitingPrice() {
        return this.recruiting_price;
    }

    getValue() {
        if (this.getType() == 'consulting') {
            if (this.getHourPrice() && this.getHours()) {
                return this.getHourPrice() * this.getHours();
            }
            return 0;
        } else if (this.getType() == 'recruiting' || this.getType() == 'sourcing') {
            if (this.getRecruitingPrice()) {
                return this.getRecruitingPrice();
            }
            return 0;
        }
        return 0;
    }

    // NOTE temporary hard coded
    getSalary(margin = 0.5, tax = 1.45) {
        let budget = this.getValue();
        if (budget) {
            let salary = budget * (1 - margin);
            salary = salary / tax;
            return Math.round(salary);
        }
        return 0;
    }

    getSalaryLow() {
        return this.getSalary(0.5, 1.55);
    }

    getSalaryHigh() {
        return this.getSalary(0.48);
    }

    getSalaryMax() {
        return this.getSalary(0.4);
    }

    getMarginLow(margin = 0.48) {
        let budget = this.getValue();
        if (budget) {
            let m = budget * margin;
            return m / this.getHours();
        }
        return 0;
    }

    getMarginHigh(margin = 0.5) {
        let budget = this.getValue();
        if (budget) {
            let m = budget * margin;
            return m / this.getHours();
        }
        return 0;
    }

    getMarginAtMax(margin = 0.4) {
        let budget = this.getValue();
        if (budget) {
            let m = budget * margin;
            return m / this.getHours();
        }
        return 0;
    }

    getWon() {
        return this.won;
    }

    getWonBy() {
        return this.won_by;
    }

    getLost() {
        return this.lost;
    }

    getLostBy() {
        return this.lost_by;
    }

    getAccountUserOwnerId() {
        return this.owner;
    }

    getCreated() {
        return this.created;
    }

    getCreatedBy() {
        return this.created_by;
    }

    getCreatedByFullName() {
        return this.created_by_full_name;
    }

    getCreatedByInitials() {
        return this.created_by_initials;
    }

    getBy() {
        if (this.created_by) {
            return "#" + this.created_by + " " + this.getCreatedByFullName();
        }
        return "Unknown"
    }

    getTasksOverdue() {
        return this.tasks_overdue;
    }

    hasAcceptedCandidates(stages) {
        for (let stage of stages) {
            if (stage.name == 'accepted' && stage.candidates.length > 0) {
                return true;
            }
        }
        return false;
    }

    hasSignedCustomer() {
        return this.has_signed_customer;
    }

    hasSignedCandidate() {
        return this.has_signed_candidate;
    }

    // returns stage name and color
    getStage() {
        let s: string[] = [];

        if (this.hasSignedCustomer()) {
            s = ['Customer Signed', '#cccc00'];
        }

        if (this.getRecruitmentId()) {
            s = ['Recruiting', '#cc6600'];
        }

        //if (this.hasAcceptedCandidates()) {
        //    s = ['Candidate Accepted', ''];
        //}

        if (this.hasSignedCandidate()) {
            s = ['Candidate Signed', '#00cc66'];
        }

        return s;
    }

}


export class Project implements Deserializable {
    id: number;
    company: number;
    company_slug: string;
    company_name: string;
    company_credit: number;
    department: number;
    sourcing_project_id: string;
    job: number;
    job_obj: Job;
    name: string;
    location: string;
    keyword: string;
    potential_candidates_count: number;
    status: string;
    status_updated: string;
    message_count: number;
    message_recipient_count: number;
    message_open_count: number;
    message_click_count: number;
    message_application_count: number;
    referral_count: number;
    stage_uncontacted_count: number;
    stage_contacted_count: number;
    stage_replied_count: number;
    stage_interested_count: number;
    user_skill_sets: any;
    created: string;
    created_by: number;

    jobObj: Job;

    deserialize(input: any) {
        Object.assign(this, input);

        if (this.job_obj) {
            this.jobObj = new Job().deserialize(this.job_obj)
        }

        return this;
    }

    getId() {
        return this.id;
    }

    getCompanyId() {
        return this.company;
    }

    getCompanySlug() {
        return this.company_slug;
    }

    getCompanyName() {
        return this.company_name;
    }

    getCompanyCredit() {
        return this.company_credit;
    }

    getDepartmentId() {
        return this.department;
    }

    getSourcingProjectId() {
        return this.sourcing_project_id;
    }

    getJobId() {
        return this.job;
    }

    getJob() {
        return this.jobObj;
    }

    getName() {
        return this.name;
    }

    getLocation() {
        return this.location;
    }

    getKeyword() {
        return this.keyword;
    }

    getPotentialCandidateCount() {
        return this.potential_candidates_count;
    }

    getStatus() {
        return this.status;
    }

    getStatusUpdated() {
        return this.status_updated;
    }

    getMessageCount() {
        return this.message_count;
    }

    getMessageRecipientCount() {
        return this.message_recipient_count;
    }

    getMessageOpenCount() {
        return this.message_open_count;
    }

    getMessageClickCount() {
        return this.message_click_count;
    }

    getMessageApplicationCount() {
        return this.message_application_count;
    }

    getReferralCount() {
        return this.referral_count;
    }

    getStageUncontactedCount() {
        return this.stage_uncontacted_count;
    }

    getStageContactedCount() {
        return this.stage_contacted_count;
    }

    getStageRepliedCount() {
        return this.stage_replied_count;
    }

    getStageInterestedCount() {
        return this.stage_interested_count;
    }

    getUserSkillSets() {
        let l: UserSkillSet[] = [];
        for (let i of this.user_skill_sets) {
            l.push(new UserSkillSet().deserialize(i));
        }
        return l;
    }

    getSkillsString() {
        if (this.user_skill_sets) {
            let s: string[] = [];
            for (let i of this.getUserSkillSets()) {
                s.push(i.getSkillName());
            }
            return s.toString();
        }
        return '';
    }

    getCreated() {
        return this.created;
    }

    getCreatedBy() {
        return this.created_by;
    }

}


export class ProjectMessage implements Deserializable {
    id: number;
    project: string;
    //company: string;
    subject: string;
    body: string;
    from_email: string;
    reply_email: string;
    //opens: number;
    //clicks: number;
    //applications: number;
    //type: string;
    status: string;
    created: string;
    sent: string;
    recipient_count: number;
    //application_count: number;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getProjectId() {
        return this.project;
    }

    //getCompanyId() {
    //    return this.company;
    //}

    getSubject() {
        return this.subject;
    }

    getBody() {
        return this.body;
    }

    getFromEmail() {
        return this.from_email;
    }

    getReplyEmail() {
        return this.reply_email;
    }

    //getOpens() {
    //    return this.opens;
    //}

    //getClicks() {
    //    return this.clicks;
    //}

    //getApplications() {
    //    return this.applications;
    //}

    //getType() {
    //    return this.type;
    //}

    getStatus() {
        return this.status;
    }

    getCreated() {
        return this.created;
    }

    getSent() {
        return this.sent;
    }

    getRecipientCount() {
        return this.recipient_count;
    }

    //getApplicationCount() {
    //    return this.application_count;
    //}
}

export class ProjectContact implements Deserializable {
    id: number;
    account_user: number;
    account_user_slug: string;
    project: number;
    company: number;
    job_application: number;
    job_application_created: string;
    sourcing_sourced_id: number;
    stage: string;
    source: string;
    sourced_from: string;
    email: string;
    phone: string;
    first_name: string;
    last_name: string;
    current_position: string;
    current_company: string;
    location: string;
    description: string;
    experiences: string;
    skills: string;
    url: string;
    linkedin_url: string;
    github_url: string;
    twitter_url: string;
    attached_file: File;
    created: string;
    created_by: number;
    created_by_full_name: string;
    notes: ProjectContactNote[];
    messages: ProjectContactMessage[];
    last_messaged: string;
    last_messaged_by_full_name: string;
    last_messaged_opens: number;

    editing: boolean = false;
    dropdownProjectList: boolean = false;

    deserialize(input: any) {
        Object.assign(this, input);

        if (this.notes) {
            this.notes = this.notes.map(
                note => new ProjectContactNote().deserialize(note)
            );
        }

        if (this.messages) {
            this.messages = this.messages.map(
                message => new ProjectContactMessage().deserialize(message)
            );
        }

        return this;
    }

    getId() {
        return this.id;
    }

    getAccountUserId() {
        return this.account_user;
    }

    getAccountUserSlug() {
        return this.account_user_slug;
    }

    getProjectId() {
        return this.project;
    }

    getCompanyId() {
        return this.company;
    }

    getJobApplicationId() {
        return this.job_application;
    }

    getJobApplicationCreated() {
        return this.job_application_created;
    }

    getSourcedId() {
        return this.sourcing_sourced_id;
    }

    getStage() {
        return this.stage;
    }

    getStageName() {
        const stageName = {
            '1': 'Uncontacted',
            '2': 'Contacted',
            '3': 'Replied',
            '4': 'Interested',
            'A': 'Archived',
        };
        return stageName[this.getStage()];
    }

    getSource() {
        return this.source;
    }

    getSourceName() {
        const sourceName = {
            'P': 'Prospected',
            'A': 'Applied',
            'R': 'Referred',
            'I': 'Imported',
        };
        return sourceName[this.getSource()];
    }

    getSourcedFrom() {
        return this.sourced_from;
    }

    getEmail() {
        if (this.email)
            return this.email;

        return '';
    }

    getPhone() {
        if (this.phone != 'null')
            return this.phone;
        else
            return '';
    }

    getFirstName() {
        return this.first_name;
    }

    getLastName() {
        return this.last_name;
    }

    getFullName() {
        if (this.first_name && this.last_name)
            return this.getFirstName() + ' ' + this.getLastName();

        else if (this.first_name)
            return this.getFirstName();

        else if (this.last_name)
            return this.getLastName();

        else
            return null;
    }

    getCurrentPosition() {
        return this.current_position;
    }

    getCurrentCompany() {
        return this.current_company;
    }

    getLocation() {
        return this.location;
    }

    getDescription() {
        return this.description;
    }

    getExperiences() {
        return this.experiences;
    }

    getSkills() {
        return this.skills;
    }

    getUrl() {
        return this.url;
    }

    getLinkedInUrl() {
        return this.linkedin_url;
    }

    getGithubUrl() {
        return this.github_url;
    }

    getTwitterUrl() {
        return this.twitter_url;
    }

    getAttachedFile() {
        return this.attached_file;
    }

    getCreated() {
        return this.created;
    }

    getCreatedBy() {
        return this.created_by;
    }

    getCreatedByFullName() {
        return this.created_by_full_name;
    }

    getNotes() {
        return this.notes;
    }

    getMessages() {
        return this.messages;
    }

    getLastMessaged() {
        return this.last_messaged;
    }

    getLastMessagedByFullName() {
        return this.last_messaged_by_full_name;
    }

    getLastMessageOpens() {
        return this.last_messaged_opens;
    }

}

export class ProjectContactNote implements Deserializable {
    id: number;
    contact: number;
    note: string;
    created: string;
    created_by_full_name: string;

    editing: boolean = false;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getContactId() {
        return this.contact;
    }

    getNote() {
        return this.note;
    }

    getCreated() {
        return this.created;
    }

    getCreatedByFullName() {
        return this.created_by_full_name;
    }

}

export class ProjectContactMessage implements Deserializable {
    id: number;
    message: number;
    message_subject: string;
    message_body: string;
    contact: number;
    opens: number;
    clicks: number;
    created: string;
    sent: string;
    status: string;
    created_by_full_name: string;

    editing: boolean = false;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getMessageId() {
        return this.message;
    }

    getSubject() {
        return this.message_subject;
    }

    getBody() {
        return this.message_body;
    }

    getContactId() {
        return this.contact;
    }

    getOpens() {
        return this.opens;
    }

    getClicks() {
        return this.clicks;
    }

    getCreated() {
        return this.created;
    }

    getSent() {
        return this.sent;
    }

    getStatus() {
        return this.status;
    }

    getCreatedByFullName() {
        return this.created_by_full_name;
    }

}

export class Sourced implements Deserializable {
    id: number;
    email: string;
    full_name: string;
    job_title: string;
    description: string;
    company: string;
    phone: string;
    url: string;
    linkedin_url: string;
    github_url: string;
    twitter_url: string;
    location: string;
    sourced_from: string;
    created: string;
    updated: string;

    skills: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getEmail() {
        return this.email;
    }

    getFullName() {
        return this.full_name;
    }

    getJobTitle() {
        return this.job_title;
    }

    getDescription() {
        return this.description;
    }

    getCompany() {
        return this.company;
    }

    getPhone() {
        return this.phone;
    }

    getUrl() {
        return this.url;
    }

    getLinkedInUrl() {
        return this.linkedin_url;
    }

    getGithubUrl() {
        return this.github_url;
    }

    getTwitterUrl() {
        return this.twitter_url;
    }

    getLocation() {
        return this.location;
    }

    getSourcedFrom() {
        return this.sourced_from;
    }


    getCreated() {
        return this.created;
    }

    getUpdated() {
        return this.updated;
    }

    getSkills() {
        return this.skills;
    }

}


export class PartnerCompany implements Deserializable {
    id: number;
    partner: number;
    partner_name: string;
    company: number;
    company_name: string;
    company_slug: string;
    company_user_count: number;
    relationship: string;
    removed: string;
    removed_by: number;
    created: string;
    created_by: number;
    au_can_edit: boolean;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getPartnerId() {
        return this.partner;
    }

    getPartnerName() {
        return this.partner_name;
    }

    getCompanyId() {
        return this.company;
    }

    getCompanyName() {
        return this.company_name;
    }

    getCompanySlug() {
        return this.company_slug;
    }

    getCompanyUserCount() {
        return this.company_user_count;
    }

    getRelationship() {
        return this.relationship;
    }

    getRemoved() {
        return this.removed;
    }

    getRemovedBy() {
        return this.removed_by;
    }

    getCreated() {
        return this.created;
    }

    getCreatedBy() {
        return this.created_by;
    }

    canLoggedInAuEditCompany() {
        return this.au_can_edit;
    }
}

