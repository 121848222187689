import { Deserializable } from "./deserializable";

import { Image } from './media';

export class Conversation implements Deserializable {
    id: number;
    account: number;
    job: number;
    job_application: number;
    job_application_account_user_id: number;
    members: any[];
    ghost: boolean;
    unread_messages_count: number;
    updated: string;
    created: string;
    created_by: number;

    loading: boolean = false;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getAccountId() {
        return this.account;
    }

    getJobId() {
        return this.job;
    }

    getJobApplicationId() {
        return this.job_application;
    }

    getJobApplicationAccountUserId() {
        return this.job_application_account_user_id;
    }

    getMembers() {
        return this.members;
    }

    isGhost() {
        return this.ghost;
    }

    // AccountUser IDs
    getMemberIDsInList() {
        let r: any[] = [];
        if (this.members) {
            for (let m of this.members) {
                r.push(m.id);
            }
        }
        return r;
    }

    getTitle(excludeAccountUser?) {
        let t: any[] = [];
        for (let m of this.members) {
            if (excludeAccountUser && excludeAccountUser.getId() != m['id']) {
                if (m['full_name']) {
                    t.push(m['full_name']);
                } else {
                    t.push(m['email']);
                }
            }
            if (this.members.length == 1 && excludeAccountUser && excludeAccountUser.getId() == m['id']) {
                t.push("Me");
            }
        }

        if (this.isGhost())
            return "Candidate";

        return t.join(', ');
    }

    getUnreadMessagesCount() {
        return this.unread_messages_count;
    }

    getUpdated() {
        return this.updated;
    }

    getCreated() {
        return this.created;
    }

    getCreatedById() {
        return this.created_by;
    }
}


export class Message implements Deserializable {
    id: number;
    conversation: number;
    account_user: number;
    account_user_full_name: string;
    account_user_main_image: string;
    account_user_slug: string;
    account_user_is_member: boolean;
    sponsored: boolean;
    read: boolean;
    text: string;
    created: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getConversationId() {
        return this.conversation;
    }

    getAccountUserId() {
        return this.account_user;
    }

    getAccountUserFullName() {
        return this.account_user_full_name;
    }

    getAccountUserMainImage() {
        return new Image().deserialize(this.account_user_main_image);
    }

    getAccountUserSlug() {
        return this.account_user_slug;
    }

    getAccountUserIsMember() {
        return this.account_user_is_member;
    }

    isSponsored() {
        return this.sponsored;
    }

    isRead() {
        return this.read;
    }

    linkify(inputText) {
        let replacedText, replacePattern1, replacePattern2, replacePattern3;

        //URLs starting with http://, https://, or ftp://
        replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

        //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
        replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
        replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

        //Change email addresses to mailto:: links.
        replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
        replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

        return replacedText;
    }

    getText() {
        return this.linkify(this.text);
    }

    getCreated() {
        return this.created;
    }

}
