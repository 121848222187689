import { Component, OnInit, Input, effect } from '@angular/core';

import { environment } from '../../../environments/environment';

import { AccountUser } from '../../_models/user';
import { Notification } from '../../_models/network';

import { AlertService } from '../../_services/alert.service';
import { AccountService } from '../../_services/account.service';
import { NetworkService } from '../../_services/network.service';


@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss']
})
export class NotificationCardComponent implements OnInit {

    domain = environment.domain;

    @Input() notification: Notification;

    loggedInAccountUser: AccountUser | undefined | null;

    openProfilePopup: boolean = false;
    openProfilePopupTimer: number = 0;
    mouseOverProfilePopup: boolean = false;
    canClosePopup: boolean = true;

    constructor(
        private accountService: AccountService,
        private alertService: AlertService,
        private networkService: NetworkService,
    ) {
        effect(() => {
            this.loggedInAccountUser = this.accountService.loggedInAccountUser();
        });
    }

    ngOnInit(): void {
    }

    isNotificationAd(): boolean {
        return this.notification.isCampaign() && this.notification.getTypeKey() == 'notification_ad';
    }

    getLinkUrl(): string {
        if (this.notification.isPost()) {
            return '/hi/' + this.notification.getCreatedBySlug() + '/post/' + this.notification.getObjectSlug();

        } else if (this.notification.isHub()) {
            return '/hub/' + this.notification.getObjectSlug();

        } else if (this.notification.isJob()) {
            return '/' + this.notification.getObjectSlug() + '/job/' + this.notification.getObjectId();

        } else if (this.isNotificationAd()) {
            return this.notification.getObjectUrl(); // full url

        } else if (this.notification.isCampaign()) {
            return '/company/' + this.notification.getObjectCompanyId() + '?pp=ads';
        }

        return '';
    }

    // connection

    acceptConnection(): void {
        if (!this.notification.isConnection()) {
            this.alertService.error('Error: not a connection notification');
            return;
        }
        this.networkService.acceptConnection(this.notification.getObjectId()).subscribe(
            data => {
                this.notification.connection_status = 'accepted';
            },
            error => {
                console.log(error);
            }
        );
    }

    ignoreConnection(): void {
        if (!this.notification.isConnection()) {
            this.alertService.error('Error: not a connection notification');
            return;
        }
        this.networkService.ignoreConnection(this.notification.getObjectId()).subscribe(
            data => {
                this.notification.connection_status = 'ignored';
            },
            error => {
                console.log(error);
            }
        );
    }

    // hub invite

    acceptHubInvite(): void {
        if (!this.notification.isHubInvite()) {
            this.alertService.error('Error: not a hub notification');
            return;
        }
        //this.networkService.acceptHubInvite(this.notification.getObjectId(), this.notification.getHubInvite().getId()).subscribe(
        this.networkService.acceptHubInvite(this.notification.getObjectId()).subscribe(
            data => {
                let now = new Date();
                let isoDateTime = now.toISOString();
                //this.notification.hub_invite.accepted = isoDateTime;
                this.notification.hub_invite_status = 'accepted';
                this.alertService.success('Hub invite accepted');
            },
            error => {
                console.log(error);
            }
        );
    }

    ignoreHubInvite(): void {
        if (!this.notification.isHubInvite()) {
            this.alertService.error('Error: not a hub notification');
            return;
        }
        //this.networkService.ignoreHubInvite(this.notification.getObjectId(), this.notification.getHubInvite().getId()).subscribe(
        this.networkService.ignoreHubInvite(this.notification.getObjectId()).subscribe(
            data => {
                let now = new Date();
                let isoDateTime = now.toISOString();
                //this.notification.hub_invite.ignored = isoDateTime;
                this.notification.hub_invite_status = 'ignored';
                this.alertService.success('Hub invite ignored');
            },
            error => {
                console.log(error);
            }
        );
    }

    openProfilePopupDelayed() {

        //console.log('openProfilePopupDelayed');

        // if mouse leaves the button before setTimerout, cancel openProfilePopupTimer with 0
        this.openProfilePopupTimer = 1;

        setTimeout(() => {
            if (this.openProfilePopupTimer == 1) {
                this.openProfilePopup = true;
            }
        }, 500);
    }

    closeProfilePopupDelayed() {
        this.openProfilePopupTimer = 0;
        setTimeout(() => {
            if (this.openProfilePopupTimer == 0 && this.mouseOverProfilePopup == false) {
                this.openProfilePopup = false;
            }
        }, 500);
    }

    closeIfCanCloseProfilePopup() {
        if (this.canClosePopup) {
            this.openProfilePopup = false;
            this.mouseOverProfilePopup = false;
        }
    }

}
