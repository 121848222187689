<div class="mx-auto bg-white bg-opacity-80 py-8 ring-1 ring-white sm:rounded-3xl lg:mx-0 lg:max-w-none lg:py-20 text-center">
    <div class="w-full flex-auto">
        <h2 class="text-xl font-bold text-blue-600">
            {{ 'For Business' | translate }}
        </h2>
        <h1 class="mt-2 text-4xl md:text-5xl font-bold">
            {{ 'Target Professionals' | translate }}
        </h1>
        <p class="my-6 text-lg leading-8 text-gray-800">
            {{ 'What do you want to do?' | translate }}
        </p>

        <div class="mx-auto max-w-96 flex gap-3 flex-col">
            <button class="btn btn-outline-blue" [routerLink]="['/hiring']" (click)="close()">
                {{ 'Hire' | translate }}
            </button>

            <button class="btn btn-outline-blue" [routerLink]="['/ads']" [queryParams]="{ type: 'brand' }" (click)="close()">
                {{ 'Advertise' | translate }}
                <!--{{ 'Promote a Brand' | translate }}-->
            </button>

            <!--
            <button class="btn btn-outline-blue" [routerLink]="['/ads']" [queryParams]="{ type: 'prod' }" (click)="close()">
                {{ 'Promote a Product or Service' | translate }}
            </button>

            <button class="btn btn-outline-blue" [routerLink]="['/ads']" [queryParams]="{ type: 'job' }" (click)="close()">
                {{ 'Promote a Job' | translate }}
            </button>
            -->

            <button class="btn btn-outline-blue" [routerLink]="['/add-job']" (click)="close()">
                {{ 'Post Job' | translate }}
            </button>

            <button class="btn btn-outline-blue" [routerLink]="['/add-company']" (click)="close()">
              {{ 'Create Career Page' | translate }}
            </button>
        </div>

        <!--
        <p class="mt-8 my-6 text-lg leading-8 text-gray-800">
            {{ 'Get started for free' | translate }}:
        </p>

        <div class="mx-auto max-w-96 flex gap-3 flex-col">
            <button class="btn btn-outline-blue" [routerLink]="['/add-job']" (click)="close()">
                {{ 'Post a job for free' | translate }}
            </button>

            <button class="btn btn-outline-blue" [routerLink]="['/add-company']" (click)="close()">
                {{ 'Create a free company page' | translate }}
            </button>
        </div>
        -->

        <!--
        <h2 class="mt-8 text-xl font-bold text-blue-600">
            {{ 'Why HiCareer?' | translate }}
        </h2>

        <ul class="text-left mt-4">
            <li class="mb-2">
                <i class="bi bi-check2 text-blue-600"></i>
                <span class="ml-2">{{ 'Connect with highly qualified professionals' | translate }}</span>
            </li>
            <li class="mb-2">
                <i class="bi bi-check2 text-blue-600"></i>
                <span class="ml-2">{{ 'Reach professionals with a personalized message' | translate }}</span>
            </li>
            <li class="mb-2">
                <i class="bi bi-check2 text-blue-600"></i>
                <span class="ml-2">{{ 'Easily set a budget and only pay for results' | translate }}</span>
            </li>
        </ul>
        -->

        <!--
        <div class="flex gap-3 w-fit mx-auto">
            <button [routerLink]="['/add-company']" class="btn-blue mt-6">{{ 'Get started free' | translate }}</button>
        </div>
        -->
    </div>
</div>

