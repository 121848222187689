import { Component, HostListener, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { Notification } from '../../_models/network';

import { NetworkService } from '../../_services/network.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit {

    @Input() set setAuId(id: number) {
        this.auId = id;
        this.notificationsPage = 1;
        this.getNotifications();
    }

    notifications: Notification[] = [];
    notificationsPage: number = 1;
    notificationsNext: string;
    notificationsCount: number;

    loading: boolean = false;
    loadingScroll: boolean = false;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private networkService: NetworkService,
    ) {
    }

    auId: number;

    ngOnInit(): void {
        //this.getNotifications();
    }

    @HostListener('window:scroll', ['$event']) // for window scroll events
    onScroll(event) {
        //console.log("offsetHeight", event.target.scrollingElement.offsetHeight);
        //console.log("scrollTop", event.target.scrollingElement.scrollTop);
        //console.log("scrollHeight", event.target.scrollingElement.scrollHeight);

        // when we scroll to the bottom, load more pages
        if (event.target.scrollingElement.offsetHeight + event.target.scrollingElement.scrollTop + 300 >= event.target.scrollingElement.scrollHeight) {

            // if we have more pages with messages to get
            if (this.notificationsNext && (!this.loading && !this.loadingScroll)) {
                this.notificationsPage += 1;
                this.getNotifications(true);
            }

        }
    }

    onScrollDirectiveReachedBottom() {
        if (this.notificationsNext && (!this.loading && !this.loadingScroll)) {
            this.notificationsPage += 1;
            this.getNotifications(true);
        }
    }


    getNotifications(onScroll: boolean = false) {
        if (this.auId) {

            if (onScroll)
                this.loadingScroll = true;
            else {
                this.loading = true;

                if (isPlatformBrowser(this.platformId)) {
                    window.scroll(0, 0);
                }
            }

            this.networkService.getNotifications(this.auId, this.notificationsPage).subscribe(
                data => {
                    if (data.results) {
                        let r: Notification[] = [];
                        for (let j of data.results) {
                            r.push(new Notification().deserialize(j));
                        }

                        if (onScroll) {
                            if (this.notifications) {
                                this.notifications.reverse();
                                this.notifications = r.concat(this.notifications);
                                this.notifications.reverse();
                            } else {
                                this.notifications = r;
                            }
                            // on reload, reload all
                        } else {
                            this.notifications = r;
                        }
                    }

                    this.notificationsNext = data.next;
                    this.notificationsCount = data.count;

                    this.loading = false;
                    this.loadingScroll = false;
                },
                error => {
                }
            );
        }
    }

}
