import { environment } from '../../environments/environment';

import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { io } from "socket.io-client";
import { Md5 } from 'ts-md5';


@Injectable({
    providedIn: 'root'
})
export class ChatService {

    public message$: BehaviorSubject<string> = new BehaviorSubject('');
    messages = this.message$.asObservable();

    // current conversation chat room
    chatId: string;
    auId: number;
    auFullName: string;
    auMainImage: any;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        if(isPlatformBrowser(this.platformId)) {
            this.socket = io(environment.chat_server, { transports : ['websocket'] });
        }
    }

    socket: any;
    //socket = this.io(environment.chat_server, { transports : ['websocket'] });

    s = 'pomivd9uhw84379y029ri30irf3jp93784';

    public getStatus() {
        if(isPlatformBrowser(this.platformId)) {
            this.socket.on("connect", () => {
                // is connected the user is "online"
                //console.log("connected", this.socket.id);

                // re-join a chat if client was disconnected form chat server
                this.reJoinChat();

                return this.socket.id; // connected
            });

            this.socket.on("disconnect", () => {
                // is disconnected the user is "offline"
                //console.log("disconnected", this.socket.id); // undefined
                return this.socket.id; // undefined = disconnected
            });

            // listen for new messages
            if (this.socket) {
                this.socket.on('message', (message) =>{
                    this.message$.next(message);
                });
            }
        }
    }

    public reJoinChat() {
        if(isPlatformBrowser(this.platformId)) {
            // re-join chat on connect (assume we where disconnected)
            if (this.chatId) {
                let chatId = this.chatId;
                let auId = this.auId;
                let auFullName = this.auFullName;
                let auMainImage = this.auMainImage;

                chatId = Md5.hashStr(this.chatId.toString() + this.s);
                this.socket.emit('joinChat', { chatId, auId, auFullName, auMainImage });
            }
        }
    }

    public joinChat(chatId, auId, auFullName, auMainImage) {
        if(isPlatformBrowser(this.platformId)) {
            if (chatId) {
                this.chatId = chatId;
                this.auId = auId;
                this.auFullName = auFullName;
                this.auMainImage = auMainImage;

                chatId = Md5.hashStr(this.chatId.toString() + this.s);
                this.socket.emit('joinChat', { chatId, auId, auFullName, auMainImage });
            }
        }
    }

    public sendMessage(chatId, auId, auFullName, auMainImage, text) {
        if(isPlatformBrowser(this.platformId)) {
            if (chatId) {
                chatId = Md5.hashStr(chatId.toString() + this.s);
                this.socket.emit('sendMessage', { chatId, auId, auFullName, auMainImage, text });
            }
        }
    }

    public getNewMessage = () => {
        if (this.socket) {
            this.socket.on('message', (message) =>{
                this.message$.next(message);
            });
        }

        return this.message$.asObservable();
    }

}
