<div class="bg-gray-100 border-b border-gray-200">
    <div class="flex justify-center w-full max-w-auto"> <!-- md:max-w-md lg:max-w-lg xl:max-w-full -->
        <div class="overflow-x-auto">

            <nav class="w-fit px-4 md:px-10 py-2 flex items-center space-x-2 text-custom text-gray-900 z-20" aria-label="Tabs">
                <button *ngIf="showForwardBtn" (click)="forwardBtnClick.emit()" class="w-6 h-6 text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-full text-sm p-1 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                </button>

                <button class="rounded-full px-3 py-2 flex gap-1"
                    [ngClass]="selected == 'career' ? 'bg-gray-200' : 'text-gray-600 hover:text-gray-800'"
                    (click)="selectPage('career')"
                    [routerLink]="['/']">
                    <span>{{ 'Career' | translate }}</span>
                </button>

                <a class="rounded-full px-3 py-2 flex gap-1"
                    [ngClass]="selected == 'jobs' ? 'bg-gray-200' : 'text-gray-600 hover:text-gray-800'"
                    (click)="selectPage('jobs')"
                    [routerLink]="['/jobs']">
                    <!--
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5" [ngClass]="selected == 'jobs' ? '' : 'text-gray-400'">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                    </svg>
                    -->
                    {{ 'Jobs' | translate }}
                </a>
                <a class="rounded-full px-3 py-2 flex gap-1"
                    [ngClass]="selected == 'companies' ? 'bg-gray-200' : 'text-gray-600 hover:text-gray-800'"
                    (click)="selectPage('companies')"
                    [routerLink]="['/companies']">
                    <!--
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5" [ngClass]="selected == 'companies' ? '' : 'text-gray-400'">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" />
                    </svg>
                    -->
                    {{ 'Companies' | translate }}
                </a>
                <a class="rounded-full px-3 py-2 flex gap-1"
                    [ngClass]="selected == 'hubs' ? 'bg-gray-200' : 'text-gray-600 hover:text-gray-800'"
                    (click)="selectPage('hubs')"
                    [routerLink]="['/hubs']">
                    <!--
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5" [ngClass]="selected == 'hubs' ? '' : 'text-gray-400'">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                    </svg>
                    -->
                    {{ 'Hubs' | translate }}
                </a>
                <a class="rounded-full px-3 py-2 flex gap-1"
                   *ngIf="false && loggedInAccountUser"
                    [ngClass]="selected == 'referral' ? 'bg-gray-200' : 'text-gray-600 hover:text-gray-800'"
                    (click)="selectPage('referral')"
                    [routerLink]="['/me/referrals']">
                    <!--
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5" [ngClass]="selected == 'jobs' ? '' : 'text-gray-400'">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                    </svg>
                    -->
                    Referrals
                </a>
                <!--
                <a class="rounded-full px-3 py-2 flex gap-1" [ngClass]="selected == 'people' ? 'bg-gray-200 text-gray-800' : 'text-gray-600 hover:text-gray-800'" (click)="selectPage('people')" [routerLink]="['/search']">
                    People
                </a>
                -->
                <a *ngIf="false" class="rounded-full px-3 py-2 flex gap-1" [ngClass]="selected == 'stories' ? 'bg-gray-200 text-gray-800' : 'text-gray-600 hover:text-gray-800'" (click)="selectPage('stories')" [routerLink]="['/knowledge/articles']">
                    Knowledge
                </a>
                <!--
                <a class="rounded-full px-3 py-2 flex gap-1" [ngClass]="selected == 'knowledge' ? 'bg-gray-200 text-gray-800' : 'text-gray-600 hover:text-gray-800'" (click)="selectPage('knowledge')" [routerLink]="['/knowledge']">
                    Knowledge
                </a>
                <a class="rounded-full px-3 py-2 flex gap-1" [ngClass]="selected == 'employer' ? 'bg-gray-200 text-gray-800' : 'text-gray-600 hover:text-gray-800'" (click)="selectPage('employers')" [routerLink]="['/employers']">
                    Employer
                </a>
                -->
                <a *ngIf="loggedInAccountUser && (loggedInAccountUser.isAdmin() || loggedInAccountUser.isManager() || loggedInAccountUser.isSeller())"
                    class="rounded-full px-3 py-2 flex gap-1"
                    (click)="selectPage('staff')"
                    [ngClass]="selected == 'staff' ? 'bg-gray-200 text-gray-800' : 'text-gray-600 hover:text-gray-800'"
                    [routerLink]="['/dc/staff']">
                    <!--
                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" [ngClass]="selected == 'staff' ? '' : 'text-gray-400'">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                    -->
                    <svg class="w-5 h-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5" />
                    </svg>
                </a>
            </nav>

        </div>
        <!-- filter -->
        <!--
            <div class="flex items-center justify-center p-3">

            <button class="flex items-center gap-2 py-2 px-4 border rounded-lg text-xs">
            <div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
            </svg>
            </div>
            <div class="hidden md:block py-1 w-fit">Filter</div>
            </button>

            </div>
        -->
    </div>
</div>
