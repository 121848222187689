import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { ImageModule } from '../image/image.module';
import { GeneralModule } from '../_shared_modules/general/general.module';
import { ModalModule } from '../modal/modal.module';
import { NetworkSharedModule } from '../network/network-shared.module';
import { ForBusinessPopupComponent } from '../utils/for-business-popup/for-business-popup.component';

import { HeaderComponent } from './header.component';
import { HeaderSubMenuComponent } from './header-sub-menu/header-sub-menu.component';
import { LoginComponent } from '../auth/login/login.component';
//import { SignupComponent } from '../signup/signup.component';
import { SignupModule } from '../auth/signup/signup.module';
import { ProfileShareComponent } from '../account-user/profile-share/profile-share.component';


@NgModule({
  declarations: [
    HeaderComponent,
    HeaderSubMenuComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ImageModule,
    GeneralModule,
    ModalModule,
    NetworkSharedModule,
    SignupModule,
    TranslateModule.forChild({
        extend: true
    }),
    LoginComponent,
    //SignupComponent,
    ProfileShareComponent,
    ForBusinessPopupComponent,
  ],
  exports: [
    HeaderComponent,
    HeaderSubMenuComponent
  ]
})
export class HeaderModule { }
