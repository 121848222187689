import { Injectable } from '@angular/core';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

    constructor() { }

    // used for Google Analytics to trigger events
    public eventEmitter(eventName: string, eventCategory: string, eventLabel: string | null = null, nonInteraction: boolean = false ) {
        gtag('event', eventName, {
            event_category: eventCategory,
            event_label: eventLabel,
            non_interaction: nonInteraction
        })
    }

    // used for Google Adwords to trigger conversions
    public eventConversionEmitter() {
        gtag('event', 'conversion', {
            'send_to': 'AW-10884927420/9qQiCMmFtIsYELyfq8Yo'
        });
    }
}
