import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ModalModule } from '../modal/modal.module';
import { PipeModule } from '../_pipe/pipe.module';

import { BadgeComponent } from './badge/badge.component';
import { MessengerComponent } from '../messenger/messenger.component';
import { NotificationCardComponent } from './notification-card/notification-card.component';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { ProfilePopupComponent } from '../account-user/profile-popup/profile-popup.component';

//import { SignupComponent } from '../signup/signup.component';
import { SignupModule } from '../auth/signup/signup.module';

@NgModule({
  declarations: [
    BadgeComponent,
    NotificationCardComponent,
    NotificationListComponent,
    ProfilePopupComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ModalModule,
    MessengerComponent,
    PipeModule,
    SignupModule,
    //SignupComponent,
  ],
  exports: [
    BadgeComponent,
    NotificationCardComponent,
    NotificationListComponent,
    ProfilePopupComponent,
  ]
})
export class NetworkSharedModule { }
