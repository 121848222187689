<div class="menu" *ngIf="accountUser">
    <!-- account user -->
    <div class="section">
        <!--
            <div class="crumb">
            <a (click)="close()" [routerLink]="['/']">
            {{ 'dashboard.Home' | translate }}
            </a>
            </div>
        -->
        <a (click)="close()" [routerLink]="['/me/dashboard/']">
            {{ accountUser.getFullName() }}
        </a>
    </div>

    <div class="items">

        <!--
            <div class="item item-icon-stats">
            <i class="bi bi-journal-text"></i>
            <a (click)="close()" [routerLink]="['/me/dashboard/']" [queryParams]="{ p: 'applications'}" [ngClass]="{ 'selected': p == 'applications' }">
            {{ 'dashboard.Applications' | translate }} 
            </a>
            </div> 

            <div class="item item-icon-stats">
            <i class="bi bi-heart"></i>
            <a (click)="close()" [routerLink]="['/me/dashboard/']" [queryParams]="{ p: 'following'}" [ngClass]="{ 'selected': p == 'following' }">
            {{ 'dashboard.Following' | translate }} 
            </a>
            </div> 
        -->

        <div class="item item-icon">
            <i class="bi bi-house"></i>
            <a (click)="close()" [routerLink]="['/me/dashboard/']" [ngClass]="{ 'selected': !p }">
                {{ 'dashboard.Overview' | translate }} 
            </a>
        </div> 

        <div class="item item-icon">
            <i class="bi bi-person"></i>
            <a (click)="close()" [routerLink]="['/me/dashboard']" [queryParams]="{ p: 'profile'}" [ngClass]="{ 'selected': p == 'profile' }">
                {{ 'dashboard.Profile' | translate }} 
            </a>
            <!--
                <span class="count">{{ company.getJobCount() }}</span>
            -->
        </div> 

        <div class="item item-icon">
            <i class="bi bi-gear"></i>
            <a (click)="close()" [routerLink]="['/me/dashboard/']" [queryParams]="{ p: 'settings'}" [ngClass]="{ 'selected': p == 'settings' }">
                {{ 'dashboard.Settings' | translate }} 
            </a>
        </div> 

        <div class="item item-icon-stats">
            <i class="bi bi-wallet2"></i>
            <a (click)="close()" [routerLink]="['/me/dashboard']" [queryParams]="{ p: 'payments'}" [ngClass]="{ 'selected': p == 'payments' }">
                {{ 'recruiter.Payments' | translate }} 
            </a>
        </div> 

    </div> 

    <!-- referral -->
    <!--
        <div class="items">

        <div class="item item-icon-stats">
        <i class="bi bi-people"></i>
        <a (click)="close()" [routerLink]="['/me/dashboard']" [queryParams]="{ p: 'candidates'}" [ngClass]="{ 'selected': p == 'candidates' }">
        {{ 'recruiter.Candidates' | translate }} 
        </a>
        </div> 
        <div class="item item-icon-stats">
        <i class="bi bi-envelope"></i>
        <a (click)="close()" [routerLink]="['/me/dashboard']" [queryParams]="{ p: 'invites'}" [ngClass]="{ 'selected': p == 'invites' }">
        {{ 'recruiter.Invitations' | translate }} 
        </a>
        </div> 
        <div class="item item-icon-stats">
        <i class="bi bi-graph-up"></i>
        <a (click)="close()" [routerLink]="['/me/dashboard']" [queryParams]="{ p: 'reports'}" [ngClass]="{ 'selected': p == 'reports' }">
        {{ 'recruiter.Reports' | translate }} 
        </a>
        </div> 

        </div>
    -->

    <!-- staff -->
    <div class="section" *ngIf="accountUser && isStaff()">
        Staff
    </div>
    <div class="items" *ngIf="accountUser && isStaff()">
        <div class="item item-icon">
            <i class="bi bi-chevron-right"></i>
            <a (click)="close()" [routerLink]="['/me/dashboard/']" [queryParams]="{ p: 'staff-overview'}" [ngClass]="{ 'selected': p == 'staff-overview' }">
                Overview
            </a>
        </div> 
        <div class="item item-icon">
            <i class="bi bi-chevron-right"></i>
            <a (click)="close()" [routerLink]="['/me/dashboard/']" [queryParams]="{ p: 'page-manager'}" [ngClass]="{ 'selected': p == 'page-manager' }">
                Companies
            </a>
        </div> 
        <div class="item item-icon">
            <i class="bi bi-chevron-right"></i>
            <a (click)="close()" [routerLink]="['/me/dashboard/']" [queryParams]="{ p: 'profile-manager'}" [ngClass]="{ 'selected': p == 'profile-manager' }">
                Profiles
            </a>
        </div> 
        <div class="item item-icon">
            <i class="bi bi-chevron-right"></i>
            <a (click)="close()" [routerLink]="['/me/dashboard/']" [queryParams]="{ p: 'referral-stats'}" [ngClass]="{ 'selected': p == 'referral-stats' }">
                Ref Reports
            </a>
        </div> 
        <div class="item item-icon">
            <i class="bi bi-chevron-right"></i>
            <a (click)="close()" [routerLink]="['/me/dashboard/']" [queryParams]="{ p: 'referral-clients'}" [ngClass]="{ 'selected': p == 'referral-clients' }">
                Ref Clients
            </a>
        </div> 
        <div class="item item-icon" *ngIf="accountUser && accountUser.isAdmin()">
            <i class="bi bi-chevron-right"></i>
            <a (click)="close()" [routerLink]="['/job-manager']">
                Jobs (adm)
            </a>
        </div> 
    </div>

    <!-- companies -->
    <div class="section">
        {{ 'dashboard.Companies' | translate }}
    </div>
    <div class="items">
        <div class="item item-company" [ngClass]="{ 'small': company?.name.length > 25 }" *ngFor="let company of accountUser.getCompanies()">
            <a (click)="setMasterCompany(company); close()" [routerLink]="['/me/dashboard']" [queryParams]="{ sc: company.id, p: 'company' }" [ngClass]="{ 'selected': sc == company?.id }">
                <span [ngClass]="{'empty30-icon': !company?.page_icon}">
                    <app-image *ngIf="company?.page_icon" [image]="getImage(company.page_icon)" [ref]="'icon30'" [cache]="true"></app-image>
                </span>
                {{ company?.name || "Company" }}
            </a>
        </div> 

        <div class="item-icon">
            <i class="bi bi-plus"></i>
            <a class="link" (click)="openAddCompanyModal();">
                {{ 'company.New Company' | translate }}
            </a>
        </div> 
    </div>


    <div class="items only-in-mobile">
        <div class="item item-icon-stats">
            <a (click)="close()" [routerLink]="['/logout']">
                {{ 'word.Logout' | translate }} 
            </a>
        </div> 
    </div>

</div>

<app-modal #addCompanyModal [size]="'md'">
    <app-company-create-form *ngIf="addCompanyModal.isOpen()" [displayStyle]="'mini'" (created)="closeAddCompanyModal()"></app-company-create-form>
</app-modal>
