import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AccountUser } from '../../_models/user';
import { Company } from '../../_models/company';

import { CompanyService } from '../../_services/company.service'

@Component({
  selector: 'app-company-menu',
  templateUrl: './company-menu.component.html',
  styleUrls: ['./company-menu.component.scss']
})
export class CompanyMenuComponent implements OnInit {

    @Input() accountUser: AccountUser | undefined | null;
    @Input() company: Company;
    @Input() hasAccess: boolean = false;

    @Output() navigated = new EventEmitter<boolean>();

    _companyId: number;

    pp: string; // career-page, jobs, applications, settings

    constructor(
        private companyService: CompanyService,
        private route: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.getCompany();

        this.route.queryParams.subscribe(params => {
            this.pp = params['pp'];
        });
    }

    navigate() {
        this.navigated.emit(true);
    }

    isStaff() {
        if (this.accountUser && (this.accountUser.isAdmin() || this.accountUser.isManager() || this.accountUser.isSeller())) {
            return true;
        }
        return false;
    }

    getCompany() {
        if (this._companyId) {
            this.companyService.getCompany(this._companyId).subscribe(
                company => {
                    this.company = company;
                },
                error => {
                }
            );
        }
    }

}
