
<div
  class="viewStyle"
  [ngStyle]="viewStyle == 'cols' ? {'grid-template-columns': '1fr 2fr'} : {'grid-template-rows': '1fr 1fr'}">

<div>

    <label>
        {{ 'form.select-country' | translate }}
    </label>
    <select id="inputCountry" class="form-select" [(ngModel)]="country">
        <option value="se" selected>{{ 'country.sweden' | translate }}</option>
        <option value="dk">{{ 'country.denmark' | translate }}</option>
        <option value="fi">{{ 'country.finland' | translate }}</option>
        <option value="no">{{ 'country.norway' | translate }}</option>
    </select>

</div>

<div>

    <form>
        <label>
            <span *ngIf="fieldName">{{ fieldName }}</span>
            <span *ngIf="!fieldName">{{ 'form.select-company' | translate }}</span>
            <div class="spinner-border spinner-border-sm ms-auto" role="status" *ngIf="loading">
                <span class="visually-hidden">Loading...</span>
            </div>
        </label>
        <div class="flex gap-1">
            <input
             class="form-control"
             [formControl]="form"
             (change)="getSearchResults();"
             (keyup)="clearCompany();"
             (enter)="getSearchResults()"
             [ngClass]="{ 'is-invalid': form.hasError('required') }"
             autocomplete="off"
             placeholder="{{ 'form.search-for-a-company' | translate }}..." />

            <button type="button" class="btn btn-primary" (click)="getSearchResults()">{{ 'form.search' | translate }}</button>
        </div>

        <div class="invalid-feedback" *ngIf="form.hasError('required')">
            This field is <strong>required</strong>
        </div>
        <!--
        <div class="optionContainer" *ngIf="filtered && filtered.length > 0" #popup>
            <div class="option" *ngFor="let company of filtered" (click)="select(company)">
                {{ company.companyName }}<br />
            </div>
        </div>
        -->

        <!--
            <div class="optionInvite" *ngIf="canInvite && filtered && filtered.length == 0 && form.value.length > 3 && !accountUser">
            <i class="bi bi-envelope"></i> Invite the user <small>(User not found)</small>
            </div>
        -->
    </form>

</div>
</div>

<div class="selectedCompany">
    <div *ngIf="company">
      {{ 'company.selected-company' | translate }}:
      <div class="blue">{{ company.getName() }}</div>
      <div class="blue">{{ company.getOrgNr() }}</div>
    </div>

    <div *ngIf="!company">
      {{ 'company.no-company-selected' | translate }}<br />
    </div>
</div>
