import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { AccountUserGhost, DataType, DataPoint, DataPointInfo, RelatedDataPoint, FeaturedDataPoint, GroupDataType } from '../_models/collection';

@Injectable({
  providedIn: 'root'
})
export class CollectionService {

    domain = environment.domain;
    prefix = environment.api_prefix;

    constructor(private http: HttpClient) { }

    getGhosts(keywords: string, filter: any, page: number = 1, companyId?: number): Observable<any> {
        let url = this.domain + this.prefix + '/collection-point/search_profiles/?page=' + page;

        let data = {
            paginate: 8,
            keywords: keywords,
            company: companyId,
        };

        // get faster loading, modify pagination size
        if (document.body) {
            // if small screen
            if (document.body.clientWidth < 500) {
                data['paginate'] = 4;
            }
        }

        // add data type key and data points to filter
        for (let key in filter) {
            data['filter_' + key] = filter[key];
        }

        return this.http.post<any>(url, data); /*.pipe(
            map(aus => aus
                .map(au => new AccountUserGhost().deserialize(au))
            )
        );*/
    }

    getGhostMatches(jobId: number, page: number = 1): Observable<any> {
        let url = this.domain + this.prefix + '/company-job/'+jobId.toString()+'/list_matching/?page=' + page;

        let data = {
            paginate: 8,
        };

        // get faster loading, modify pagination size
        if (document.body) {
            // if small screen
            if (document.body.clientWidth < 500) {
                data['paginate'] = 4;
            }
        }

        return this.http.get<any>(url);
    }

    getDataTypes(): Observable<DataType[]> {
        let url = this.domain + this.prefix + '/collection-type/';

        return this.http.get<DataType[]>(url).pipe(
            map(dps => dps
                .map(dp => new DataType().deserialize(dp))
            )
        );
    }

    getGroupDataTypes(groupName: string): Observable<GroupDataType[]> {
        let url = this.domain + this.prefix + '/collection-type-group/?group=' + groupName;

        return this.http.get<GroupDataType[]>(url).pipe(
            map(dps => dps
                .map(dp => new GroupDataType().deserialize(dp))
            )
        );
    }

    getDataPoints(dataTypeKey: string, sortBy: string, limitResults: number, text?: string, verified?: boolean, hasPromotedDataPoints?: boolean): Observable<DataPoint[]> {
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/collection-point/?dtkey=' + dataTypeKey + '&sort=' + sortBy + '&limit=' + limitResults + `&timestamp=${timestamp}`; // key required

        if (text)
            url = url + '&dptxt=' + encodeURI(text);

        if (verified == true || verified == false)
            url = url + '&verified=' + verified;

        if (hasPromotedDataPoints == true || hasPromotedDataPoints == false)
            url = url + '&hpp=' + hasPromotedDataPoints;

        return this.http.get<DataPoint[]>(url).pipe(
            map(dps => dps
                .map(dp => new DataPoint().deserialize(dp))
            )
        );
    }

    getDataPointsSimilar(dpName: string, sectionKey: string): Observable<DataPoint[]> {
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/collection-point/list_similar/?&dptxt=' + dpName + '&sk=' + sectionKey + `&timestamp=${timestamp}`; // key required

        return this.http.get<DataPoint[]>(url).pipe(
            map(dps => dps
                .map(dp => new DataPoint().deserialize(dp))
            )
        );
    }

    getDataPoint(dataTypeKey: string, text: string): Observable<DataPoint> {
        let url = this.domain + this.prefix + '/collection-point/0/?dtkey=' + dataTypeKey; // key required

        url = url + '&dptxt=' + encodeURI(text);

        return this.http.get<DataPoint>(url).pipe(
            map(dp => new DataPoint().deserialize(dp))
        );
    }

    getDataPointInfo(dpName: string): Observable<DataPointInfo[]> {
        let url = this.domain + this.prefix + '/collection-point/'+dpName+'/info/';

        return this.http.get<DataPointInfo[]>(url).pipe(
            map(dpis => dpis
                .map(dpi => new DataPointInfo().deserialize(dpi))
            )
        );
    }

    /*toggleDataPoint(id: number, dataTypeKey: string, auId: number, companyId: number, jobPost: boolean, jobKey: string, projectId: number, singleSelect: boolean): Observable<any> {
        let data = {
            'dtk': dataTypeKey,
            'auId': auId,
            'companyId': companyId,
            'jobPost': jobPost,
            'jobKey': jobKey,
            'projectId': projectId,
            'singleSelect': singleSelect
        };
        return this.http.post(this.domain + this.prefix + '/collection-point/' + id + '/toggle/', data)
    }*/

    addDataPoint(
      value: string,
      dataTypeKey: string,
      auId: number | undefined | null,
      companyId: number | null,
      jobPost: boolean | null,
      jobKey: string | null,
      projectId: number | null,
      hubId: number | null,
      singleSelect: boolean): Observable<any> {

        let data = {
            'value': value,
            'dtk': dataTypeKey,
            'auId': auId,
            'companyId': companyId,
            'jobPost': jobPost,
            'jobKey': jobKey,
            'projectId': projectId,
            'hubId': hubId,
            'singleSelect': singleSelect
        };
        return this.http.post(this.domain + this.prefix + '/collection-point/', data).pipe(
            map(dp => new DataPoint().deserialize(dp))
        );
    }

    updateDataPoint(data): Observable<any> {
        return this.http.put(this.domain + this.prefix + '/collection-point/' + data.id + '/', data)
    }

    mergeDataPoints(data): Observable<any> {
        return this.http.post(this.domain + this.prefix + '/collection-point/merge_selected_dps/', data);
    }

    removeDataTypeFromDataPoint(data): Observable<any> {
        return this.http.put(this.domain + this.prefix + '/collection-point/' + data.id + '/remove_dt_from_dp/', data)
    }

    removeDataTypeFromDataPoints(data): Observable<any> {
        return this.http.put(this.domain + this.prefix + '/collection-point/remove_dt_from_dps/', data)
    }

    addDataTypeToDataPoints(data): Observable<any> {
        return this.http.put(this.domain + this.prefix + '/collection-point/add_dt_to_dps/', data)
    }

    createRelatedDataPoint(id: number, dataTypeKey: string, auId: number | undefined | null, companyId: number, jobPost: boolean, jobKey: string | null, projectId: number, hubId: number, singleSelect: boolean): Observable<any> {
        let data = {
            'dtk': dataTypeKey,
            'auId': auId,
            'companyId': companyId,
            'jobPost': jobPost,
            'jobKey': jobKey,
            'projectId': projectId,
            'hubId': hubId,
            'singleSelect': singleSelect
        };
        return this.http.post(this.domain + this.prefix + '/collection-point/' + id + '/add_rdp/', data)
    }

    removeRelatedDataPoint(id: number, jobKey: string | null, companyId: number, hubId: number): Observable<any> {
        let data = {
            'companyId': companyId,
            'jobKey': jobKey || '',
            'hubId': hubId
        };
        return this.http.post(this.domain + this.prefix + '/collection-point/' + id + '/remove_rdp/', data)
    }

    getRelatedDataPoints(
      dataTypeKey: string,
      auId: number | undefined | null,
      companyId: number,
      jobKey: string | null,
      projectId: number,
      hubId: number): Observable<RelatedDataPoint[]> {
        // NOTE i use the same in parameters multiple times on places like data-point-select component and thats
        // a problem since http will return a cached value and the related data point will not update, to solve
        // this use timestamp
        const timestamp = Date.now(); // Add a unique timestamp to the URL
        let url = this.domain + this.prefix + '/collection-point/list_related/?dtkey=' + dataTypeKey+ `&timestamp=${timestamp}`; // key required

        if (auId)
            url = url + '&auId=' + auId;

        if (companyId)
            url = url + '&companyId=' + companyId;

        if (jobKey)
            url = url + '&jobKey=' + jobKey;

        if (projectId)
            url = url + '&projectId=' + projectId;

        if (hubId)
            url = url + '&hubId=' + hubId;

        return this.http.get<RelatedDataPoint[]>(url).pipe(
            map(rdps => rdps
                .map(rdp => new RelatedDataPoint().deserialize(rdp))
            )
        );
    }

    updateRelatedDataPoint(data): Observable<any> {
        return this.http.put(this.domain + this.prefix + '/collection-point/' + data.id + '/update_related/', data)
    }

    getSelectedDataPoints(dataTypeKey: string, auId: number, companyId: number, jobId: number, projectId: number): Observable<DataPoint[]> {
        let url = this.domain + this.prefix + '/collection-point/list_selected/?dtkey=' + dataTypeKey; // key required

        if (auId)
            url = url + '&auId=' + auId;

        if (companyId)
            url = url + '&companyId=' + companyId;

        if (jobId)
            url = url + '&jobId=' + jobId;

        if (projectId)
            url = url + '&projectId=' + projectId;

        return this.http.get<DataPoint[]>(url).pipe(
            map(sdps => sdps
                .map(sdp => new DataPoint().deserialize(sdp))
            )
        );
    }

    getFeaturedDataPoints(): Observable<FeaturedDataPoint[]> {
        let url = this.domain + this.prefix + '/collection-point/list_featured/'; // key required

        return this.http.get<FeaturedDataPoint[]>(url).pipe(
            map(dps => dps
                .map(dp => new FeaturedDataPoint().deserialize(dp))
            )
        );
    }
}
